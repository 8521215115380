import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getStore } from 'modules/redux'
import { useAnalytics, SegmentEvents } from 'modules/segment'
import { expandAllCards } from 'modules/tiptap_editor/extensions/Card/CardCollapse'
import {
  MemoState,
  selectExpandedCardsMap,
  setExpandedCardsMap,
  setIsEditingDisabled,
} from 'modules/tiptap_editor/reducer'

export const usePrintHandlers = (editor) => {
  const dispatch = useDispatch()
  const analytics = useAnalytics()

  useEffect(() => {
    let expandedCards: MemoState['expandedCards']

    const onBeforePrint = () => {
      const state = getStore().getState()
      expandedCards = selectExpandedCardsMap(state)
      expandAllCards(editor)
      dispatch(setIsEditingDisabled({ isEditingDisabled: true }))
      analytics?.trackDocEvent(SegmentEvents.DOC_PRINTED)
    }

    const onAfterPrint = () => {
      dispatch(setIsEditingDisabled({ isEditingDisabled: false }))
      dispatch(setExpandedCardsMap({ expandedCardsMap: expandedCards }))
    }

    window.addEventListener('beforeprint', onBeforePrint)
    window.addEventListener('afterprint', onAfterPrint)

    return () => {
      window.removeEventListener('beforeprint', onBeforePrint)
      window.removeEventListener('afterprint', onAfterPrint)
    }
  }, [dispatch, editor, analytics])
}
