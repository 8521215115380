import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Skeleton,
  SkeletonText,
  Tab,
  TabList,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  capitalize,
  docTitleOrPlaceholder,
  DOC_DISPLAY_NAME,
  normalizeDate,
  SectionTitle,
} from '@gamma-app/ui'
import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { User } from '@gammatech/schema'
import { JSONContent } from '@tiptap/core'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { FullPageSpinner } from 'gamma_components'
import {
  Card,
  Doc,
  Snapshot,
  useGetSnapshotLazyQuery,
  useGetSnapshotsQuery,
} from 'modules/api'
import { EditorStatic } from 'modules/tiptap_editor'
import { CARD_NODE_NAME } from 'modules/tiptap_editor/extensions/Card'
import { findNodes } from 'modules/tiptap_editor/utils/findNodes'
import { useEditorContext } from 'sections/docs/context'
import { groupByTodayThisWeekAndMore } from 'utils/date'

import { DocEditorRootStyles } from '../../DocEditorRootStyles'

const PAGE_SIZE = 25

const HUMAN_READABLE_DATE_GROUPINGS = {
  today: 'Today',
  thisWeek: 'This week',
  earlier: 'Older snapshots',
}

const SkeletonSnapshotsDrawerHeader = () => {
  return (
    <>
      <Skeleton w="350px" h={12}></Skeleton>
      <SkeletonText mt={3} w={64} noOfLines={1} />
    </>
  )
}

type SnapshotInViewer = Pick<
  Snapshot,
  'createdTime' | 'editors' | 'id' | 'docId' | 'schemaVersion'
>

interface SnapshotTabItemProps {
  createdTime: string
  editors: User[]
  onClick: MouseEventHandler<HTMLElement>
}
const SnapshotTabItem = ({
  createdTime,
  editors,
  onClick,
}: SnapshotTabItemProps) => {
  if (!editors || !editors.length) {
    return <></>
  }
  return (
    <Tab
      flexDirection="column"
      borderRadius="base"
      border="none"
      onClick={onClick}
      alignItems="flex-start"
      width="100%"
      textAlign="left"
    >
      <Text fontSize="sm">{capitalize(normalizeDate(createdTime))}</Text>
      <Text fontSize="xs">{editors[0].displayName}</Text>
    </Tab>
  )
}
interface SnapshotsDrawerTabListProps {
  snapshots: SnapshotInViewer[]
  onClick: (id: string) => void
  showFetchMore: boolean
  onFetchMoreClick: () => void
  isFetchingMore: boolean
}
const SnapshotsDrawerTabList = ({
  snapshots,
  onClick,
  showFetchMore,
  onFetchMoreClick,
  isFetchingMore,
}: SnapshotsDrawerTabListProps) => {
  const groupedSnapshots = useMemo(
    () =>
      groupByTodayThisWeekAndMore<SnapshotInViewer>({
        items: snapshots,
        sortBy: 'createdTime',
      }),
    [snapshots]
  )

  return (
    <TabList w="100%">
      {Object.entries(groupedSnapshots)
        .filter(([, snapshotList]) => {
          // Filter out if the date key, i.e. "Today" or "This Week", has zero entries
          return snapshotList.length
        })
        .map(([dateKey, snapshotList]) => {
          return (
            <React.Fragment key={dateKey}>
              <SectionTitle px={4} py={2} mx={0} mt={2}>
                {HUMAN_READABLE_DATE_GROUPINGS[dateKey]}
              </SectionTitle>
              {snapshotList.map(({ createdTime, editors, id }) => {
                return (
                  <SnapshotTabItem
                    onClick={() => onClick(id)}
                    key={id}
                    createdTime={createdTime}
                    editors={editors}
                  />
                )
              })}
            </React.Fragment>
          )
        })}

      {showFetchMore && (
        <Button isLoading={isFetchingMore} onClick={onFetchMoreClick}>
          Load more
        </Button>
      )}
    </TabList>
  )
}

const SkeletonSnapshotsDrawerTabList = () => {
  return (
    <TabList w="100%">
      <Skeleton h={16} />
      <Skeleton h={16} mt={2} />
      <Skeleton h={16} mt={2} />
      <Skeleton h={16} mt={2} />
      <Skeleton h={16} mt={2} />
      <Skeleton h={16} mt={2} />
      <Skeleton h={16} mt={2} />
      <Skeleton h={16} mt={2} />
    </TabList>
  )
}

interface SnapshotsDrawerHeaderProps {
  title: string
  createdBy?: Partial<Pick<User, 'id' | 'displayName'>>
  createdTime: string
  numSnapshots: number
}

const SnapshotsDrawerHeader = ({
  title,
  createdBy,
  createdTime,
  numSnapshots,
}: SnapshotsDrawerHeaderProps) => {
  return (
    <>
      <Text mt={1}>
        {numSnapshots === 1 ? `One snapshot` : `${numSnapshots} snapshots`} over
        the last {formatDistanceToNowStrict(parseISO(createdTime))}
      </Text>
      <Text
        color="gray.500"
        fontSize="sm"
        fontWeight="normal"
        letterSpacing="normal"
      >
        {`${title}, created by ${
          createdBy?.displayName ? createdBy?.displayName : 'Unknown'
        } on ${format(parseISO(createdTime), 'PPp')}`}
      </Text>
    </>
  )
}

const SNAPSHOT_VIEWER_CLASS = 'snapshot-editor-root'

interface SnapshotViewerProps {
  id: Snapshot['id']
  doc: Doc
  content: JSONContent | undefined
}

const SnapshotViewer = ({ id, doc, content }: SnapshotViewerProps) => {
  const cardData: Card[] | undefined = useMemo(() => {
    if (!content) {
      return
    }
    return findNodes(content, (node) => node.type === CARD_NODE_NAME).map(
      (cardContent: JSONContent) => {
        return {
          __typename: 'Card',
          id: cardContent.attrs?.id || '',
          docId: doc.id,
          title: getCardTitle(cardContent),
          createdTime: doc.createdTime,
          updatedTime: doc.updatedTime,
          archived: false,
        }
      }
    )
  }, [content, doc])

  return (
    <EditorStatic
      reduxData={{ cards: cardData }}
      key={id}
      initialContent={content}
      doc={doc}
      docId={doc.id}
      scrollingParentSelector={`.${SNAPSHOT_VIEWER_CLASS}`}
    />
  )
}
interface SnapshotDrawerProps {
  isOpen: boolean
  onClose: () => void
  doc: Doc
}
export const SnapshotsDrawer = ({
  isOpen,
  onClose,
  doc,
}: SnapshotDrawerProps) => {
  const toast = useToast()
  const { getCollaborativeEditorInstance } = useEditorContext()
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)
  // Fetch the entire list of snapshots (excluding content) to display in sidebar
  const {
    loading: isLoadingSnapshots,
    data: snapshotsData,
    called: snapshotsCalled,
    fetchMore,
  } = useGetSnapshotsQuery({
    variables: {
      docId: doc.id,
      first: PAGE_SIZE,
    },
    skip: !doc || !isOpen,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })
  // Set up fetching for selected snapshot's contents
  const [
    getSnapshot,
    { loading: isLoadingSelectedSnapshot, data: selectedSnapshotData },
  ] = useGetSnapshotLazyQuery({
    nextFetchPolicy: 'cache-only',
  })

  const snapshots = snapshotsData?.snapshots.edges.map((edge) => edge.node)
  const pageInfo = snapshotsData?.snapshots.pageInfo

  const shouldFetchMore = Boolean(
    snapshotsCalled && pageInfo?.hasNextPage && pageInfo?.endCursor
  )

  const onFetchMoreClick = useCallback(() => {
    if (!pageInfo?.hasNextPage || !pageInfo?.endCursor) {
      return
    }
    setIsFetchingMore(true)

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    }).finally(() => {
      setIsFetchingMore(false)
    })
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage])

  const mostRecentSnapshot = snapshots?.[0]

  useEffect(() => {
    // Once the list of snapshots has loaded, fetch the first one's contents
    // to display the most recent snapshot right away
    if (mostRecentSnapshot?.id) {
      // TODO: Request that snapshots are explicitly returned in chronological order
      // https://github.com/gamma-app/gamma/issues/429
      getSnapshot({
        variables: { snapshotId: mostRecentSnapshot.id, docId: doc.id },
      })
    }
  }, [doc.id, getSnapshot, mostRecentSnapshot?.id])

  // Document metadata for Heading
  const title = docTitleOrPlaceholder(doc?.title)
  const createdBy = doc?.createdBy
  const createdTime = doc?.createdTime

  const selectedSnapshot = selectedSnapshotData?.snapshot

  const handleRestoreClick = () => {
    if (!selectedSnapshot) return

    const selectedSnapshotCreatedTime = snapshots?.find(
      (s) => s.id === selectedSnapshot.id
    )?.createdTime
    const collaborativeEditorInstance = getCollaborativeEditorInstance()
    if (!collaborativeEditorInstance) {
      return
    }

    collaborativeEditorInstance.commands.setContent(
      selectedSnapshot.content.default,
      // 2nd param is emit update event.  Set to true to trigger
      // other observers such as TOC and docAttrs
      true
    )

    const { annotationsAbsolute } = selectedSnapshot.content
    if (!annotationsAbsolute) {
      collaborativeEditorInstance.commands.clearAnnotations()
    } else {
      collaborativeEditorInstance.commands.restoreAnnotations(
        annotationsAbsolute
      )
    }
    onClose()
    toast({
      title: `Restored ${DOC_DISPLAY_NAME}`,
      description: `${capitalize(DOC_DISPLAY_NAME)} restored to ${
        selectedSnapshotCreatedTime
          ? format(parseISO(selectedSnapshotCreatedTime), 'PPp')
          : 'selected snapshot'
      }`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      isOpen={isOpen}
      trapFocus={true}
      isFullHeight={true}
    >
      <DrawerOverlay />
      <DrawerContent
        borderTopRadius="xl"
        h="calc(var(--100vh) - 24px)"
        // Prevent Chakra from animating the drawer. This is necessary because
        // background-attachment: fixed seems to be incompatible with transforms
        // and Chakra chose not to expose a transition setting :(
        // https://github.com/chakra-ui/chakra-ui/issues/4423
        transform="none !important"
        // Alternate approach: remove the transform after transition. Problem here is that it's broken on initial scroll
        // https://github.com/framer/motion/issues/823
        // @ts-ignore
        // transitionEnd={{
        //   enter: {
        //     x: 'unset',
        //     y: 'unset',
        //     z: 'unset',
        //   },
        // }}
      >
        <DrawerCloseButton />
        <DrawerHeader borderBottom="1px solid #000" borderColor="gray.200">
          {isLoadingSnapshots && <SkeletonSnapshotsDrawerHeader />}
          {doc && snapshots && (
            <SnapshotsDrawerHeader
              numSnapshots={snapshots?.length || 0}
              createdBy={createdBy}
              createdTime={createdTime}
              title={title}
            />
          )}
        </DrawerHeader>
        <DrawerBody p={0} h="100%">
          <Flex direction="row" h="100%">
            <Box p={2} w={64} overflowY="auto" bg="gray.50">
              <Tabs variant="soft-rounded" orientation="vertical" size="md">
                {isLoadingSnapshots && <SkeletonSnapshotsDrawerTabList />}
                {!isLoadingSnapshots && snapshots && (
                  <SnapshotsDrawerTabList
                    onClick={(id: string) => {
                      getSnapshot({
                        variables: { snapshotId: id, docId: doc.id },
                      })
                    }}
                    snapshots={snapshots}
                    showFetchMore={shouldFetchMore}
                    onFetchMoreClick={onFetchMoreClick}
                    isFetchingMore={isFetchingMore}
                  />
                )}
              </Tabs>
            </Box>
            <Flex
              flex={1}
              bg="gray.100"
              overflowY="scroll"
              justifyContent="center"
              alignContent="center"
              alignItems="flex-start"
              width="100%"
              height="100%"
              className={SNAPSHOT_VIEWER_CLASS}
            >
              <DocEditorRootStyles>
                {(!selectedSnapshot || isLoadingSelectedSnapshot) && (
                  <FullPageSpinner />
                )}
                {selectedSnapshot && !isLoadingSelectedSnapshot && (
                  <SnapshotViewer
                    id={selectedSnapshot.id}
                    doc={doc}
                    content={selectedSnapshot?.content.default}
                  />
                )}
              </DocEditorRootStyles>
            </Flex>
          </Flex>
        </DrawerBody>

        <DrawerFooter borderTop="1px solid #000" borderColor="gray.200">
          <ButtonGroup spacing={4}>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              disabled={!selectedSnapshot}
              onClick={handleRestoreClick}
            >
              Restore
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
