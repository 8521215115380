import { Box, Flex } from '@chakra-ui/react'
import { docTitleOrPlaceholder, DOC_DISPLAY_NAME } from '@gamma-app/ui'
import Head from 'next/head'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { useToastSignUp } from 'modules/auth/accesslink_signup/'
import { useFeatureFlag } from 'modules/featureFlags'
import { DefaultHelpWidget } from 'modules/help/components/DefaultHelpWidget'
import { PresentModeHelpWidget } from 'modules/help/components/PresentModeHelpWidget'
import {
  useDocEditorPanelLifecycles,
  useTogglePanel,
} from 'modules/panels/hooks'
import {
  LeftPanelContainer,
  RightPanelContainer,
} from 'modules/panels/PanelContainer'
import { PanelLifecycleContext } from 'modules/panels/PanelLifecycle'
import { resetPanels } from 'modules/panels/reducer'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { GlobalSearch } from 'modules/search'
import { CollaborativeEditor, EditorModeEnum } from 'modules/tiptap_editor'
import { ManageCardMenuPortal } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/ManageCardMenuPortal'
import { selectMode } from 'modules/tiptap_editor/reducer'
import { TimeOnCard } from 'modules/tiptap_editor/TimeOnCard/TimeOnCard'
import { useUserContext } from 'modules/user'
import { ExampleTopbar } from 'sections/docs/editor/components/ExampleTopbar'
import { useBodyDragClass } from 'utils/hooks/useBodyDragClass'

import { DocEditorType, DocEditorTypeType } from '../constants'
import { useEditorContext, withEditorContext } from '../context'
import { selectDoc, setDocEditorType } from '../reducer'
import { CommentFeedPanel } from './components/CommentFeed/CommentFeedPanel'
import { ExampleStatic } from './components/ExampleStatic'
import { useHandleScrollAndSelectionChange } from './components/presentFollow'
import { PublicStatic } from './components/PublicStatic'
import { useQuerySync } from './components/querySync'
import { SlideView } from './components/SlideView'
import { TableOfContentsPanel } from './components/TableOfContents/TableOfContentsPanel'
import { Toolbar } from './components/Toolbar'
import {
  useOverrideKeyboardShortcuts,
  useCardCollapseShortcuts,
} from './hooks/keyboardHooks'
import { useUpdateTOCFromMode } from './hooks/toc'
import { useArchivedToast } from './hooks/useArchivedToast'
import { useHandleCardHash } from './hooks/useHandleCardHash'
import { useDocTracking } from './useDocTracking'
import { usePrintHandlers } from './usePrintHandlers'

const DOC_ROOT_CLASS = 'doc-root'
interface DocEditorProps {
  docEditorType?: DocEditorTypeType
}

const DocEditorComponent = ({
  docEditorType = DocEditorType.COLLABORATIVE,
}: DocEditorProps) => {
  const { docId, isUnauthorized, setCollaborativeEditorInstance, editor } =
    useEditorContext()
  const examplesV2Enabled = useFeatureFlag('examplesV2')

  // IMPORTANT! This should never show for public or example templates.
  const showHelpWidget = docEditorType === DocEditorType.COLLABORATIVE

  const doc = useAppSelector(selectDoc)
  const { user } = useUserContext()
  const isUserWithOrg = Boolean(
    user && Number(user.organizations?.length || 0) > 0
  )
  useArchivedToast(doc, docEditorType)
  useDocTracking(docId, docEditorType)
  useHandleCardHash()
  useOverrideKeyboardShortcuts()
  useCardCollapseShortcuts()
  useHandleScrollAndSelectionChange(editor, `.${DOC_ROOT_CLASS}`)
  useQuerySync(editor)
  useBodyDragClass()
  usePrintHandlers(editor)
  useToastSignUp(doc, docEditorType === DocEditorType.COLLABORATIVE)
  const editorWrapperRef = useRef<HTMLDivElement | null>(null)
  const editorMode = useSelector(selectMode)

  const dispatch = useAppDispatch()

  console.debug(
    `%c[DocEditorComponent] RENDER - ${docId}`,
    'background-color: gold'
  )

  const {
    togglePanel: toggleToCPanel,
    isPanelOpen: isToCPanelOpen,
    initPanel: initializeToCPanel,
  } = useTogglePanel(TableOfContentsPanel)

  const { togglePanel: toggleCommentsPanel, isPanelOpen: isCommentsPanelOpen } =
    useTogglePanel(CommentFeedPanel)

  const isStaticEditor = docEditorType !== DocEditorType.COLLABORATIVE
  useUpdateTOCFromMode({ toggleToCPanel, isToCPanelOpen, initializeToCPanel })
  useEffect(() => {
    dispatch(setDocEditorType({ docEditorType }))
  }, [dispatch, docEditorType])

  useEffect(
    () => () => {
      dispatch(resetPanels())
    },
    [dispatch]
  )

  const isSearchModalEnabled = useCallback(() => {
    return Boolean(editor && editor.state.selection.empty)
  }, [editor])

  const { leftPanelLifecycle, rightPanelLifecycle, globalPanelLifecycle } =
    useDocEditorPanelLifecycles()

  return (
    <>
      <style id="editorStyleOverride">{`body {overflow: hidden;}`}</style>
      <Head>
        <title>
          {`${doc ? docTitleOrPlaceholder(doc.title) + ' | ' : ''}`}
          Gamma
        </title>
      </Head>
      <GlobalSearch isEnabledFn={isSearchModalEnabled} />
      {docEditorType === DocEditorType.EXAMPLE_STATIC &&
        isUserWithOrg &&
        !examplesV2Enabled && (
          // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
          <ExampleTopbar doc={doc} editor={editor} />
        )}
      {isUnauthorized ? (
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          color="gray.500"
          fontSize="2xl"
        >
          It looks like you don't have access to this {DOC_DISPLAY_NAME}.
        </Flex>
      ) : (
        <Flex
          id="doc-main"
          direction="row"
          w="100%"
          h="100%"
          data-testid="doc-editor-root"
          ref={editorWrapperRef}
        >
          <LeftPanelContainer panelLifecycle={leftPanelLifecycle} />
          <Flex
            flex="1"
            direction="column"
            position="relative"
            minWidth="0"
            h="100%"
          >
            {editor && (
              <Toolbar
                scrollingParentSelector={`.${DOC_ROOT_CLASS}`}
                editorWrapperEl={editorWrapperRef.current}
                isStaticEditor={isStaticEditor}
                isToCPanelOpen={isToCPanelOpen}
                toggleToCPanel={toggleToCPanel}
                isCommentsPanelOpen={isCommentsPanelOpen}
                toggleCommentsPanel={toggleCommentsPanel}
              />
            )}
            <Box
              flex="1"
              overflowY="auto"
              overflowX="hidden"
              minWidth="0"
              h="100%"
              className={DOC_ROOT_CLASS}
              style={{}}
            >
              {docEditorType === DocEditorType.PUBLIC_STATIC ? (
                <PublicStatic
                  docId={docId}
                  snapshotId={doc?.currentSnapshotId}
                  scrollingParentSelector={`.${DOC_ROOT_CLASS}`}
                  onCreate={setCollaborativeEditorInstance}
                />
              ) : docEditorType === DocEditorType.EXAMPLE_STATIC ? (
                <ExampleStatic
                  docId={docId}
                  snapshotId={doc?.currentSnapshotId}
                  scrollingParentSelector={`.${DOC_ROOT_CLASS}`}
                  onCreate={setCollaborativeEditorInstance}
                />
              ) : (
                <PanelLifecycleContext.Provider value={globalPanelLifecycle}>
                  <CollaborativeEditor
                    // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
                    doc={doc}
                    docId={docId}
                    readOnly={doc?.archived}
                    // This gives us access to the tiptap instance belonging to the Collaborative Editor
                    // in case we need it for running editor commands from anywhere inside this tree.
                    onCreate={setCollaborativeEditorInstance}
                    scrollingParentSelector={`.${DOC_ROOT_CLASS}`}
                  />
                  {editor && <TimeOnCard editor={editor} />}
                </PanelLifecycleContext.Provider>
              )}
              <SlideView editor={editor} />
            </Box>
          </Flex>
          <RightPanelContainer panelLifecycle={rightPanelLifecycle} />
          <ManageCardMenuPortal />
          {showHelpWidget &&
            (editorMode === EditorModeEnum.SLIDE_VIEW ? (
              <PresentModeHelpWidget />
            ) : (
              <DefaultHelpWidget />
            ))}
        </Flex>
      )}
    </>
  )
}

export const DocEditor = withEditorContext(DocEditorComponent)
