import { useUpdateEffect } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { replaceState, URLEventEmitter } from 'modules/history'
import { useAppSelector } from 'modules/redux'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { selectMode, setMode } from 'modules/tiptap_editor/reducer'
import { useEffectWhen } from 'utils/hooks'
import { getExistingQueryParams, parseUrlHash } from 'utils/url'

/**
 * Maps the EditorModeEnums to user friendly query params
 * E.g. ?mode=present and ?mode=doc
 */
export const EditorModeToQueryParamMap: Record<EditorModeEnum, string> = {
  [EditorModeEnum.DOC_VIEW]: 'doc',
  [EditorModeEnum.SLIDE_VIEW]: 'present',
} as const

export const useQuerySync = (editor?: Editor) => {
  const reduxDispatch = useDispatch()

  const existing = getExistingQueryParams()
  const modeQueryParam = existing.mode
  const mode = useAppSelector(selectMode)

  const updateModeQuery = useCallback(
    (override?: EditorModeEnum) => {
      const currentMode = override || mode
      const { mode: modeParam } = getExistingQueryParams()
      const nextModeParam = EditorModeToQueryParamMap[currentMode]
      if (modeParam === nextModeParam) return

      replaceState({
        query: {
          ...getExistingQueryParams(),
          mode: nextModeParam,
        },
        emitChange: false,
      })
    },
    [mode]
  )
  useUpdateEffect(updateModeQuery, [mode])

  useEffect(() => {
    // Its possible that a popstate event changes the URL such that
    // the mode= param is incorrect, so keep it in sync by listening
    return URLEventEmitter.on('changed', () => updateModeQuery())
  }, [updateModeQuery])

  useEffectWhen(
    function initializeModeFromQuery() {
      if (!editor) return

      const editorMode =
        modeQueryParam === EditorModeToQueryParamMap.SLIDE_VIEW
          ? EditorModeEnum.SLIDE_VIEW
          : EditorModeEnum.DOC_VIEW
      const { cardId } = parseUrlHash()

      if (editorMode === EditorModeEnum.SLIDE_VIEW) {
        if (cardId) {
          console.debug(
            '[useQuerySync] initializeModeFromQuery - Entering slide view -> spotlightCardById:',
            cardId
          )
          editor.commands.spotlightCardById(cardId)
        } else {
          console.debug(
            '[useQuerySync] initializeModeFromQuery - Entering slide view -> spotlightCurrentCard'
          )
          editor.commands.spotlightCurrentCard()
        }
      }
      reduxDispatch(
        setMode({
          mode: editorMode,
        })
      )

      // Ensure any invalid initial mode query strings are replaced
      updateModeQuery(editorMode)
    },
    [editor, reduxDispatch, modeQueryParam, updateModeQuery],
    [editor]
  )
}
