import {
  chakra,
  StyleProps,
  PropsOf,
  useMultiStyleConfig,
  HTMLChakraProps,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import {
  AnimatePresence,
  CustomDomComponent,
  motion,
  Variants,
} from 'framer-motion'
import React, { forwardRef, ReactNode } from 'react'

const motionVariants: Variants = {
  enter: {
    visibility: 'visible',
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    transitionEnd: {
      visibility: 'hidden',
    },
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.1,
      easings: 'easeOut',
    },
  },
}

const MenuTransition: CustomDomComponent<PropsOf<typeof chakra.div>> =
  'custom' in motion
    ? (motion as any).custom(chakra.div)
    : (motion as any)(chakra.div)

export type GammaMenuListProps = {
  zIndex: StyleProps['zIndex']
  style: any
  children: ReactNode
} & HTMLChakraProps<'div'>

export const GammaMenuList = forwardRef<HTMLDivElement, GammaMenuListProps>(
  function GammaMenuList(props, ref) {
    const { children, style, ...rest } = props
    const styles = useMultiStyleConfig('Menu', rest)
    return (
      <chakra.div
        ref={ref}
        __css={{ zIndex: props.zIndex ?? styles.list?.zIndex }}
        style={{
          ...style,
        }}
        {...rest}
      >
        <AnimatePresence>
          <MenuTransition
            className={cx('chakra-menu__menu-list')}
            variants={motionVariants}
            style={{
              transformOrigin: 'top left',
            }}
            initial={motionVariants.exit}
            animate={motionVariants.enter}
            exit={motionVariants.exit}
            __css={{
              outline: 0,
              ...styles.list,
            }}
          >
            {children}
          </MenuTransition>
        </AnimatePresence>
      </chakra.div>
    )
  }
)
