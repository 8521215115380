import { Box, Flex, HStack, Spacer, useMediaQuery } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { memo, useCallback, useRef } from 'react'

import { ProfileMenu } from 'gamma_components'
import { useMouseHotspot } from 'modules/hotspots'
import { TogglePanelConfig } from 'modules/panels/hooks'
import { useAppSelector } from 'modules/redux'
import { useSpotlightingByBlock } from 'modules/tiptap_editor/extensions/spotlight/hooks'
import {
  selectCollaboratorBeingFollowed,
  selectEditable,
  selectIsPresentModeAndNotEditing,
  selectLocalCollaboratorAttached,
  selectMode,
} from 'modules/tiptap_editor/reducer'
import { useCan } from 'modules/user'
import { useEditorContext } from 'sections/docs'
import { selectDoc } from 'sections/docs/reducer'

import { BreadcrumbBlock } from './BreadcrumbBlock'
import { CollaboratorsPanel } from './Collaborators'
import { CommentButton } from './CommentButton'
import { DocMenu } from './Menu'
import { PresentBlock } from './PresentBlock'
import { SharingSettings } from './SharingSettings'
import { SpotlightBlock } from './SpotlightBlock'
import { ThemeBlock } from './ThemeBlock'
import { ZoomControls } from './ZoomControls'

const FOLLOW_BAR_HEIGHT = 4
export const TOOLBAR_HEIGHT = 42

const MotionBox = motion(Box)

type ToolbarProps = {
  scrollingParentSelector: string
  isStaticEditor?: boolean // true if were in solo/static snapshot mode (no collaborators)
  isCommentsPanelOpen: boolean
  toggleCommentsPanel: (config?: TogglePanelConfig) => void
  isToCPanelOpen?: boolean
  toggleToCPanel?: (config?: TogglePanelConfig) => void
  editorWrapperEl?: HTMLDivElement | null
}
export const Toolbar = memo(function ToolbarComponent({
  scrollingParentSelector,
  toggleCommentsPanel,
  isCommentsPanelOpen,
  isStaticEditor = false,
  editorWrapperEl,
}: ToolbarProps) {
  // The toolbar cant fit a lot of the extra buttons on mobile (<840px),
  // so hide certain ones that wont really work on mobile anyway
  const [isMobile] = useMediaQuery(['(max-width: 840px)', '(min-width: 841px)'])
  const editorMode = useAppSelector(selectMode)
  const doc = useAppSelector(selectDoc)
  const { editor } = useEditorContext()
  const userCanComment = useCan('comment', doc)
  const { enabled: spotlightingByBlock } = useSpotlightingByBlock(editor)
  const collaboratorBeingFollowed = useAppSelector(
    selectCollaboratorBeingFollowed
  )
  const localCollaboratorAttached = useAppSelector(
    selectLocalCollaboratorAttached
  )
  const isFollowingAndAttached =
    collaboratorBeingFollowed && localCollaboratorAttached
  const isDocMode = editorMode !== 'SLIDE_VIEW'
  const isEditable = useAppSelector(selectEditable)

  const isPresentModeAndNotEditing = useAppSelector(
    selectIsPresentModeAndNotEditing
  )
  const isInHotspot = useMouseHotspot(
    useCallback((e) => {
      const el = toolbarRef.current
      if (!el) {
        return false
      }
      // We need this here to count the hotspot as "active" if any menus or popovers
      // inside the hotspot target are open.
      // example: If a hotspot is 100px tall, but a menu activated from within the toolbar
      //          is taller than 100px and its items are positioned at >100px.
      const res =
        el.querySelector('[data-active]') ||
        el.querySelector('[aria-expanded="true"]')
      return !!res || e.clientY < 100
    }, [])
  )

  const toolbarRef = useRef<HTMLDivElement | null>(null)
  const toolbarShown =
    !isPresentModeAndNotEditing || (isPresentModeAndNotEditing && isInHotspot)

  return (
    <Flex
      ref={toolbarRef}
      bg="linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.1) 100%)"
      position="absolute"
      zIndex="overlay"
      top={0}
      left={0}
      right={0}
      p={2}
      alignItems="center"
      pr={6}
      marginTop={toolbarShown ? 0 : '-100%'}
      // Disable pointer events on the wrapper and re-enable them on all children except the spacer
      // This enables mouse events (like clicking memo content or dragging nodes)
      // to work at the very top of the viewport where there aren't any buttons.
      pointerEvents="none"
      sx={{
        [`&:focus-within`]: {
          marginTop: 0,
        },

        '> *': {
          pointerEvents: 'auto',
        },
        '> #toolbar-spacer': {
          pointerEvents: 'none',
        },
      }}
      transitionProperty="margin-top"
      transitionDuration="ultra-slow"
      transitionTimingFunction="ease-in-out"
      data-print-hidden
    >
      <AnimatePresence initial={false}>
        {isFollowingAndAttached && (
          // This absolutely positioned box shows the colored page outline when following someone
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            position="absolute"
            pointerEvents="none"
            borderTop={`solid ${FOLLOW_BAR_HEIGHT}px ${collaboratorBeingFollowed?.color}`}
            top={0}
            left={0}
            bottom={0}
            right={0}
            height="var(--100vh)"
          />
        )}
      </AnimatePresence>
      <HStack>
        <BreadcrumbBlock doc={doc} />
      </HStack>
      <Spacer pointerEvents="none" id="toolbar-spacer" />
      <HStack>
        {!isMobile && <CollaboratorsPanel />}
        {!isMobile && <ZoomControls />}
        {!isMobile && !isDocMode && (
          <>
            <SpotlightBlock spotlightingByBlock={spotlightingByBlock} />
          </>
        )}
        {!isMobile && isEditable && <ThemeBlock />}
        {/** @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed */}
        {!isMobile && !isStaticEditor && <SharingSettings doc={doc} />}
        {/** @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed */}
        {!isMobile && (
          <PresentBlock
            doc={doc}
            isStaticEditor={isStaticEditor}
            editorWrapperEl={editorWrapperEl}
            scrollingParentSelector={scrollingParentSelector}
          />
        )}
        {!isStaticEditor && userCanComment && (
          <CommentButton
            // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
            doc={doc}
            mode={editorMode}
            isCommentsPanelOpen={isCommentsPanelOpen}
            toggleCommentsPanel={toggleCommentsPanel}
          />
        )}
        {!isMobile && !isStaticEditor && (
          // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
          <DocMenu doc={doc} />
        )}
        <Flex>
          <ProfileMenu showRing={true} />
        </Flex>
      </HStack>
    </Flex>
  )
})
