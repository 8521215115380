import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  HStack,
  Input,
  keyframes,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, GammaTooltip, LinkCopier } from '@gamma-app/ui'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { useUserContext } from 'modules/user'
import { selectDoc } from 'sections/docs/reducer'

const MEET_URL_PARAMS = {
  followOnStart: 'follow_on_start',
  followingId: 'following_id',
}
type MeetUrlOptions = {
  followOnStart: boolean
  followingId?: string
}
const generateMeetUrl = (
  accessLinkUrl: string,
  { followOnStart, followingId }: MeetUrlOptions
): string => {
  const url = new URL(accessLinkUrl)
  if (followingId) {
    url.searchParams.append(MEET_URL_PARAMS.followingId, followingId)
  }
  if (followOnStart) {
    url.searchParams.append(MEET_URL_PARAMS.followOnStart, 'true')
  }
  return url.toString()
}

const getInitialOptions = (userId): MeetUrlOptions => {
  return {
    followOnStart: true,
    followingId: userId,
  }
}

type MeetModalProps = {
  isOpen: boolean
  onClose: () => void
  accessLinkUrl: string
  onPlayClick: () => void
}
export const MeetModal = ({
  isOpen,
  onClose,
  accessLinkUrl,
  onPlayClick,
}: MeetModalProps) => {
  const { user: selfUser } = useUserContext()

  const { replace } = useRouter()
  const initialOptions = getInitialOptions(selfUser?.id)
  const [meetLinkSettings, setMeetLinkmeetLinkSettings] =
    useState(initialOptions)
  const meetLinkUrl = generateMeetUrl(accessLinkUrl, meetLinkSettings)
  const { user, color } = useUserContext()

  const { hasCopied, onCopy } = useClipboard(meetLinkUrl)

  const doc = useAppSelector(selectDoc)
  const accessLinkPermission = doc?.accessLinks?.[0]?.permission

  // Used for the pulsing avatar animation
  const pulseKeyframes = keyframes`
    0% { transform: scale(1.0); filter: brightness(1.0);  }
    3% { transform: scale(1.0); filter: brightness(1.0);  }
    8% {  transform: scale(0.90); filter: brightness(1.30);  }
    18% { transform: scale(1.05); filter: brightness(1.0); }
    30% { transform: scale(1.0); filter: brightness(1.0); }
  `
  const pulseAnimation = `${pulseKeyframes} 2s infinite ease-out`

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Follow link</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pt="6">
          <Stack spacing="4">
            <Text>
              Anyone with this link will get{' '}
              <GammaTooltip
                label={'You can change these settings in the share panel'}
                placement="top"
              >
                <Text
                  as="span"
                  fontWeight="bold"
                  borderBottom="1px dashed"
                  borderColor="gray.400"
                  display="inline-block"
                >
                  {accessLinkPermission ?? 'view'} access
                </Text>
              </GammaTooltip>{' '}
              to this {DOC_DISPLAY_NAME}, and automatically start following your
              position in the {DOC_DISPLAY_NAME} when they open it.
            </Text>

            <Box mb="2">
              <LinkCopier url={meetLinkUrl} variant="solid" />
            </Box>

            <Alert>
              <AlertIcon></AlertIcon>
              <HStack flexBasis="100%" alignItems="top">
                <Text fontSize="sm" width="100%">
                  Tip: people can also follow you anytime by clicking on your
                  avatar
                </Text>
                <Box position="relative" textShadow="sm">
                  <Avatar
                    animation={pulseAnimation}
                    name={user?.displayName}
                    src={user?.profileImageUrl}
                    size="md"
                    border={`solid 3px ${color.value}`}
                    shadow="lg"
                  />
                  <Box
                    position="absolute"
                    top="1.75rem"
                    left="1.75rem"
                    outline="0 0 2px rgba(255,255,255,1.0)"
                    sx={{
                      path: {
                        stroke: 'white',
                        strokeWidth: 48,
                      },
                    }}
                    color="black"
                  >
                    <FontAwesomeIcon icon={solid('arrow-pointer')} fixedWidth />
                  </Box>
                </Box>
              </HStack>
            </Alert>

            {/* Leaving these controls in case we decide to give users more control again */}

            {/* <FormControl display="flex" alignItems="center">
              <Switch
                id="followOnStart"
                mr={3}
                defaultChecked={initialOptions.followOnStart}
                onChange={(e) => {
                  setMeetLinkmeetLinkSettings((prev) => {
                    return { ...prev, followOnStart: e.target.checked }
                  })
                }}
              />
              <FormLabel htmlFor="followOnStart" mb="0">
                Have viewers follow me automatically when joining
              </FormLabel>
            </FormControl> */}

            {/* <FormControl display="flex" alignItems="center">
              <Switch
                id="allowExplore"
                mr={3}
                onChange={(e) => {
                  setMeetLinkmeetLinkSettings((prev) => {
                    return { ...prev, allowExplore: e.target.checked }
                  })
                }}
              />
              <FormLabel htmlFor="allowExplore" mb="0">
                Allow people to explore
              </FormLabel>
            </FormControl> */}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={() => {
                onClose()
              }}
            >
              Done
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                onClose()
                onPlayClick()
                replace(meetLinkUrl, meetLinkUrl, {
                  shallow: true,
                })
              }}
              leftIcon={<FontAwesomeIcon icon={solid('play')} />}
            >
              Start presenting
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
