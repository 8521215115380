import { useToast } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import isHotkey from 'is-hotkey'
import { useEffect } from 'react'

import { useAppDispatch, useAppStore } from 'modules/redux'
import { getScrollManager } from 'modules/scroll'
import { isCardNode } from 'modules/tiptap_editor/extensions/Card'
import { setCardCollapsed } from 'modules/tiptap_editor/extensions/Card/CardCollapse'
import { isLayoutNode } from 'modules/tiptap_editor/extensions/Layout/utils'
import { isTableNode } from 'modules/tiptap_editor/extensions/tables/utils/isCellSelection'
import {
  selectPresentingCardId,
  setForceDisableAnimations,
} from 'modules/tiptap_editor/reducer'
import {
  getDomNodeFromPos,
  getTopCenterIshNode,
  findParentNodes,
} from 'modules/tiptap_editor/utils'
import { useEditorContext } from 'sections/docs'
import {
  __DEBUGGING_addDebuggingOutline,
  __DEBUGGING_addDebuggingTripline,
  getOffsetFromParent,
} from 'utils/dom'

const isModSHotkey = isHotkey('mod+S')
const isModShiftOHotkey = isHotkey('mod+shift+O')

const PIN_DURATION = 100
const TOP_OFFSET = 200
const CHUNK_SIZE = 25

export const useOverrideKeyboardShortcuts = () => {
  const toast = useToast()
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isModSHotkey(e)) {
        e.preventDefault()
        if (!toast.isActive('save_toast')) {
          toast({
            id: 'save_toast',
            title: 'Gamma automatically saves your work for you.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        }
      }
    }
    const removeTripline = __DEBUGGING_addDebuggingTripline({
      requiredCookie: 'cardCollapseDebug=true',
    })
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      removeTripline?.()
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [toast])
}

export const useCardCollapseShortcuts = () => {
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const { editor } = useEditorContext()

  useEffect(() => {
    if (!editor) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isModShiftOHotkey(e)) return
      e.preventDefault()

      const state = store.getState()
      const {
        cardIdMap,
        cardIds: allCardIds,
        memoState: { expandedCards },
      } = state.TipTap
      const presentingCardId = selectPresentingCardId(state)
      const { total, expanded, cardIds } = allCardIds.reduce<{
        total: number
        expanded: number
        cardIds: string[]
      }>(
        (acc, cardId) => {
          const isExpanded = expandedCards[cardId] === true
          const parents = cardIdMap.parents[cardId] || []
          const isNested = parents.length > 0
          const inScope = presentingCardId
            ? parents.includes(presentingCardId)
            : true

          if (isNested && inScope) {
            acc.cardIds.push(cardId)
            acc.total++
            acc.expanded += isExpanded ? 1 : 0
          }
          return acc
        },
        { total: 0, expanded: 0, cardIds: [] }
      )
      dispatch(setForceDisableAnimations({ disable: true }))
      setTimeout(() => {
        dispatch(setForceDisableAnimations({ disable: false }))
      }, PIN_DURATION)

      const allCardsExpanded = total === expanded
      pinPageDuringAnimation(editor, PIN_DURATION, !allCardsExpanded)
      setCardCollapsed(cardIds, allCardsExpanded)
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch, store, editor])
}

const pinPageDuringAnimation = (
  editor: Editor,
  duration: number,
  isExpanding: boolean
) => {
  const scrollManager = getScrollManager('editor')
  let resultPos: number | undefined = undefined
  /**
   * Call getTopCenterIshNode until we find a pos where state.nodeAt resolves.
   * Start at TOP_OFFSET and move up the page in CHUNK_SIZE increments.
   */
  for (let offset = TOP_OFFSET; offset > 0; offset = offset - CHUNK_SIZE) {
    const nextResult = getTopCenterIshNode(
      editor,
      scrollManager.scrollSelector,
      offset
    )
    if (!nextResult.pos?.pos) continue

    let nextResultPos: number | undefined = undefined
    if (editor.state.doc.nodeAt(nextResult.pos.pos)) {
      nextResultPos = nextResult.pos.pos
    } else if (editor.state.doc.nodeAt(nextResult.pos.inside)) {
      nextResultPos = nextResult.pos.inside
    }

    if (!nextResultPos) continue

    if (!resultPos || nextResultPos > resultPos) {
      resultPos = nextResultPos
    }
  }
  if (!resultPos) {
    console.warn('[pinPageDuringAnimation] No result for getTopCenterIshNode')
    return
  }
  const element = getElementToPin(editor, resultPos, isExpanding)

  if (!element) {
    console.warn('[pinPageDuringAnimation] No element for getElementToPin')
    return
  }

  __DEBUGGING_addDebuggingOutline({
    element,
    requiredCookie: 'cardCollapseDebug=true',
  })

  const startTime = +new Date()
  const startScrollTop = scrollManager.scroller!.scrollTop
  const startOffset = getOffsetFromParent(element, scrollManager.scrollSelector)
  const startDiff = startScrollTop - startOffset
  const adjustAmount = startDiff < 0 ? 0 : Math.max(startDiff + TOP_OFFSET, 0)

  const execute = () => {
    if (!element) return
    const offsetFromTopOfScroller = getOffsetFromParent(
      element,
      scrollManager.scrollSelector
    )
    const offsetDelta = offsetFromTopOfScroller - startOffset - adjustAmount
    scrollManager.scroller!.scroll({ top: startScrollTop + offsetDelta })
    const elapsed = +new Date() - startTime
    if (elapsed < duration) {
      requestAnimationFrame(execute)
    }
  }
  execute()
}

const getElementToPin = (editor: Editor, pos: number, isExpanding: boolean) => {
  const $pos = editor.state.doc.resolve(pos)
  const nodeAt = editor.state.doc.nodeAt(pos)
  let element: HTMLElement | undefined = getDomNodeFromPos(editor, pos)

  if (!nodeAt) {
    console.warn(`[getElementToPin] nodeAt null for $pos`, $pos)
    return
  }

  const parentNodes = findParentNodes($pos, () => true).reverse()
  parentNodes.push({
    start: $pos.start($pos.depth + 1),
    pos,
    depth: $pos.depth,
    node: nodeAt,
  })
  while (parentNodes.length) {
    const next = parentNodes.shift()
    if (!next) continue

    const isCard = isCardNode(next.node)
    const isLayout = isLayoutNode(next.node)
    const isTable = isTableNode(next.node)

    // NO CLUE why cards and atoms need pos while others use start but it works
    const posToUse = isCard || next.node.isAtom ? next.pos : next.start
    element = getDomNodeFromPos(editor, posToUse)

    // Dont go into nested cards when collapsing
    // as they wont be visible after the collapse
    const bailForNestedCard = !isExpanding && isCard && next.depth > 2

    // Dont go into layouts or tables due to issues calculating their offset
    if (bailForNestedCard || isLayout || isTable) {
      break
    }
  }

  // Make sure the element we choose is visible
  while (element?.offsetParent === null && element.parentElement) {
    element = element.parentElement
  }

  return element
}
