// A very lightweight wrapper component whose jobs are:
// 1) Set the position and coordinates of the help widget such that it's
//    consistent across screens.
// 2) Toggle which version of the help widget to show
import { Box, BoxProps } from '@chakra-ui/react'
import { memo } from 'react'

import { isMobileDevice } from 'utils/deviceDetection'

import {
  HELP_WIDGET_BOTTOM_MARGIN,
  HELP_WIDGET_RIGHT_MARGIN,
} from './constants'

interface HelpWidgetWrapperProps extends BoxProps {}

export const HelpWidgetWrapper = memo(
  ({ children, ...props }: HelpWidgetWrapperProps) => {
    if (isMobileDevice) {
      return null
    }
    return (
      <Box
        position="fixed"
        bottom={HELP_WIDGET_BOTTOM_MARGIN}
        right={HELP_WIDGET_RIGHT_MARGIN}
        zIndex="overlay"
        data-print-hidden
        {...props}
      >
        {children}
      </Box>
    )
  }
)

HelpWidgetWrapper.displayName = 'HelpWidgetWrapper'
