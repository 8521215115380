import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  Item,
  UseAutoCompleteProps,
} from '@choc-ui/chakra-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AvatarGroupAutoOverflow,
  CHANNEL_DISPLAY_NAME,
  CHANNEL_DISPLAY_NAME_PLURAL,
} from '@gamma-app/ui'
import NextImage from 'next/image'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Channel,
  DocChannel,
  ShareSearchChannelFragment,
  useSharingSearchChannelQuery,
} from 'modules/api'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { useUpdatePublicChannels } from 'modules/sharing/useUpdatePublicChannels'

const MAX_CHANNEL_AVATARS = 3

type ChannelItem = Item & {
  originalValue: {
    id: string
  }
}

type OnSelectOptionArgs = UseAutoCompleteProps['onSelectOption'] & {
  item: ChannelItem
}

interface ChannelSearchBarProps {
  workspaceId: string
  docId: string
  existingChannels: DocChannel[]
  isDisabled: boolean
}

type SearchResult = ShareSearchChannelFragment

export const ChannelSearchBar = ({
  workspaceId,
  docId,
  existingChannels,
  isDisabled,
}: ChannelSearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [emptyState, setEmptyState] = useState<boolean>(false)
  const { addDocChannel } = useUpdatePublicChannels()

  const { data: searchData, loading } = useSharingSearchChannelQuery({
    variables: {
      query: inputValue,
      workspaceId,
    },
    skip: !inputValue,
  })

  useEffect(() => {
    if (loading || !searchData) return
    const incoming = searchData.search.filter(Boolean) as SearchResult[]
    setSearchResults(incoming)
    setEmptyState(incoming.length === 0)
  }, [searchData, loading])

  const searchChannels = useMemo(
    () =>
      (inputValue
        ? searchResults.filter(
            (d) =>
              // filter out channels that are already associated with the doc
              !existingChannels.find((channel) => channel.id === d.id)
          )
        : []) as Channel[],
    [existingChannels, inputValue, searchResults]
  )

  const addSelectedChannel = useCallback(
    (selectedChannelId) => {
      const matchingChannel = searchChannels.find(
        (c) => c.id === selectedChannelId
      )
      if (!matchingChannel) {
        console.error(
          '[ChannelSearchBar.addSelectedChannel] No matching channel found'
        )
        return
      }
      addDocChannel({ docId, channel: matchingChannel, existingChannels })
    },
    [addDocChannel, docId, existingChannels, searchChannels]
  )

  const shouldDisableInput = isDisabled

  return (
    <ButtonGroup
      w="100%"
      mb={2}
      alignItems="flex-start"
      data-testid="sharepanel-channel-search-bar"
    >
      <Box flex={1}>
        <InputGroup w="100%" justifyContent="center">
          <Flex
            display={'none'}
            position="absolute"
            h="100%"
            w="100%"
            alignItems="center"
            justifyContent="center"
            bg="gray.300"
            zIndex="overlay"
            opacity={0.4}
          >
            <Spinner />
          </Flex>
          <AutoComplete
            rollNavigation
            multiple={true}
            emptyState={() => {
              if (!emptyState) return false
              return (
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  w="100%"
                  my={6}
                >
                  <Box w="80%" maxW="200px" mb={6} alignContent="center">
                    <NextImage
                      src="/images/Sal-Fishing-2x.png"
                      width="640px"
                      height="361px"
                      alt="Sal, the Gamma mascot, fishing extraterrestrially"
                    />
                  </Box>
                  <Heading size="xs" mb={2} textAlign="center">
                    No {CHANNEL_DISPLAY_NAME_PLURAL} found.
                  </Heading>
                  <Text fontSize="sm" color="gray.400" textAlign="center">
                    Try changing your search query.
                  </Text>
                </Flex>
              )
            }}
            suggestWhenEmpty={false}
            openOnFocus={true}
            // The search result are already filtered, so we don't need
            // to add any additional client-side filtering.
            filter={() => true}
            // @ts-ignore
            onSelectOption={({ item }: OnSelectOptionArgs) => {
              addSelectedChannel(item.value)
              setInputValue('')
            }}
          >
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              h="100%"
              flexDirection="column"
            >
              {/**
               * Use a flex 1 div to slam the Search Icon to the bottom.
               * Necessary until this libary supports InputGroup inside AutoCompleteInput
               * See https://github.com/anubra266/choc-autocomplete/issues/63
               */}
              <Flex flex={1} />
              <Flex h={10} py={4} alignItems="center">
                <SearchIcon w={10} />
              </Flex>
            </InputLeftElement>
            <AutoCompleteInput
              ref={inputRef}
              data-testid="autocomplete-channels-input"
              cursor={shouldDisableInput ? 'not-allowed' : undefined}
              disabled={shouldDisableInput}
              w={'100%'}
              pl={5}
              wrapStyles={{
                px: 2,
              }}
              fontSize="md"
              placeholder={`Add to ${CHANNEL_DISPLAY_NAME}`}
              transition="width 1s ease-in-out"
              value={inputValue}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = target
                setInputValue(value)

                if (!value) {
                  setSearchResults([])
                }
              }}
            />
            <AutoCompleteList
              w="100%"
              mt={0}
              display={inputValue.length > 0 ? 'flex' : 'none'}
            >
              {searchChannels.length > 0 &&
                searchChannels.map((channel) => {
                  const { id, name, memberCount, isMember, members } = channel
                  const memberList = members || []
                  return (
                    <AutoCompleteItem
                      key={id}
                      data-testid={`autocomplete-item-channel-${id}`}
                      value={{ id: channel.id }}
                      getValue={(i) => i.id}
                      align="center"
                      _focus={{
                        bg: 'trueblue.50',
                      }}
                    >
                      <HStack spacing={4} p={0} flex={1}>
                        <Box color="gray.700">
                          <FontAwesomeIcon icon={CHANNELS_ICON} />
                        </Box>
                        <Stack spacing={0} flex="1">
                          <Text>{name}</Text>
                          <Text fontSize="sm" color="gray.400">
                            {memberCount}{' '}
                            {memberCount === 1 ? 'member' : 'members'}
                            {isMember && ', including you.'}
                          </Text>
                        </Stack>
                        <AvatarGroupAutoOverflow
                          size="xs"
                          avatars={memberList}
                          max={MAX_CHANNEL_AVATARS}
                          onClick={(e) => {
                            // Prevent clicking from selecting the item
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                        />
                      </HStack>
                    </AutoCompleteItem>
                  )
                })}
            </AutoCompleteList>
          </AutoComplete>
        </InputGroup>
      </Box>
    </ButtonGroup>
  )
}
