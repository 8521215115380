import { JSONContent } from '@tiptap/core'

export const findNodes = (
  root: JSONContent,
  predicate: (node: JSONContent) => boolean
) => {
  const nodeMatches: JSONContent = []

  const checkNodes = (node: JSONContent) => {
    if (predicate(node)) {
      nodeMatches.push(node)
    }
    if (node.content) {
      for (const child of node.content) {
        checkNodes(child)
      }
    }
  }

  checkNodes(root)
  return nodeMatches
}
