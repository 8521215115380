import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { SectionTitle } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { useMemo, useState } from 'react'

import { Comment, CommentStatus, Doc, useHealthCheck } from 'modules/api'
import { checkAccessLinkForLoggedOutUser } from 'modules/auth/accesslink_signup/'
import { useAppSelector } from 'modules/redux'
import {
  CommentBox,
  CommentSignUp,
} from 'modules/tiptap_editor/extensions/Annotatable'
import { COMMENTS_WRAPPER_CLASSNAME } from 'modules/tiptap_editor/extensions/Annotatable/components/BlockCommentsStack/components/BlockCommentsWrapper'
import { selectMode } from 'modules/tiptap_editor/reducer'
import { GraphqlUser, useCan, useUserContext } from 'modules/user'
import SalFishing from 'publicImages/Sal-Fishing-2x.png'
import { selectDoc } from 'sections/docs/reducer'
import {
  getLatestUpdatedCommentOrReplyTime,
  groupByTodayThisWeekAndMore,
  sortByLatestCommentOrReply,
} from 'utils/date'

import { CommentFeedHeader } from './CommentFeedHeader'
import { CommentFeedMemoLevelComment } from './CommentFeedMemoLevelComment'
import { COMMENT_DATE_DIVIDER_ZINDEX } from './constants'
import { OfflineInfoBox } from 'modules/offline'

const HUMAN_READABLE_DATE_GROUPINGS = {
  today: 'Today',
  thisWeek: 'This week',
  earlier: 'Older comments',
}

const HUMAN_READABLE_STATUS = {
  [CommentStatus.Open]: 'open',
  [CommentStatus.Closed]: 'closed',
}

type CommentFeedProps = {
  onClose: () => void
  editor?: Editor
}
export const CommentFeed = ({ onClose, editor }: CommentFeedProps) => {
  const doc = useAppSelector(selectDoc)
  const { isConnected } = useHealthCheck()

  const [commentStatusFilter, setCommentStatusFilter] = useState<CommentStatus>(
    CommentStatus.Open
  )
  const comments = doc?.comments
  const filteredComments = doc?.comments?.filter(
    (c) => c.status === commentStatusFilter
  )
  const editorMode = useAppSelector(selectMode)

  const userCanComment = useCan('comment', doc)
  const userCanCommentAndConnected = userCanComment && isConnected
  const { user } = useUserContext()
  const accessLink = checkAccessLinkForLoggedOutUser(doc, user)

  const groupedComments = useMemo(() => {
    const groups = groupByTodayThisWeekAndMore<Comment>({
      items: filteredComments || [],
      sortBy: sortByLatestCommentOrReply,
      latestTimeGetterFn: getLatestUpdatedCommentOrReplyTime,
    })
    return groups
  }, [filteredComments])

  if (!comments || !doc || !editor) {
    return null
  }

  return (
    <Flex
      direction="column"
      h="100%"
      overflowX="hidden"
      className={COMMENTS_WRAPPER_CLASSNAME}
      w="100%"
    >
      <CommentFeedHeader
        commentStatusFilter={commentStatusFilter}
        setCommentStatusFilter={setCommentStatusFilter}
        count={filteredComments?.length || 0}
        onClose={onClose}
      />
      <Flex
        direction="column"
        className="comments-list"
        flex={1}
        overflowY="auto"
        tabIndex={0}
        outline="none"
      >
        <OfflineInfoBox
          label="Comments will be available when you reconnect."
          isConnected={isConnected}
          mx={6}
        />
        {accessLink && !user && (
          <CommentSignUp docId={doc.id} accessLink={accessLink} />
        )}
        {userCanCommentAndConnected && (
          <Box px={6} py={2}>
            <CommentFeedMemoLevelComment
              user={user as GraphqlUser}
              editor={editor}
            />
          </Box>
        )}
        {!filteredComments?.length && (
          <VStack align="center" p={6} textAlign="center" spacing={8}>
            <Box w="80%" maxW="300px" mb={6} alignContent="center">
              <Image
                src={SalFishing.src}
                width="640px"
                alt="Sal, the Gamma mascot, fishing extraterrestrially"
              />
            </Box>
            <Box>
              <Heading size="xs" mb={2}>
                No {HUMAN_READABLE_STATUS[commentStatusFilter]} comments.
              </Heading>
              <Text fontSize="sm">Try changing the filters.</Text>
            </Box>
          </VStack>
        )}
        {Object.entries(groupedComments)
          .filter(([, commentList]) => {
            // Filter out if the date key, i.e. "Today" or "This Week", has zero entries
            return commentList.length
          })
          .map(([dateKey, commentList]) => {
            return (
              <Flex direction="column" key={dateKey}>
                <SectionTitle
                  px={6}
                  pt={4}
                  pb={2}
                  position="sticky"
                  top={-2}
                  bg="white"
                  zIndex={COMMENT_DATE_DIVIDER_ZINDEX}
                >
                  {HUMAN_READABLE_DATE_GROUPINGS[dateKey]}
                </SectionTitle>
                <Flex direction="column">
                  {commentList.map((comment) => {
                    return (
                      <CommentBox
                        instanceType="feed"
                        userCanComment={userCanCommentAndConnected}
                        // commentIdToHighlight={commentIdToHighlight}
                        editor={editor!}
                        comment={comment}
                        selfUser={user}
                        key={comment.id}
                        docId={doc?.id as string}
                        editorMode={editorMode}
                        onClose={onClose}
                        isRootComment={true}
                        pt={6}
                        px={6}
                        pb={4}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            )
          })}
      </Flex>
    </Flex>
  )
}
