import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

const getMenuLabelWithCount = (
  selection: string,
  everyone: number,
  viewersOnly: number
) => {
  if (selection === 'everyone') {
    return `Everyone (${everyone})`
  } else if (selection === 'viewersOnly') {
    return `Viewers only (${viewersOnly})`
  } else {
    return `Access link only`
  }
}

const viewerIcons = {
  everyone: solid('user-group'),
  viewersOnly: solid('eye'),
  accessLinkOnly: solid('link-simple'),
}

type ViewerControlMenuProps = {
  selected: string
  handleMenuItemChange: React.Dispatch<React.SetStateAction<string>>
  everyoneCount: number
  viewersOnlyCount: number
}
// For now, ability to filter by accessLink is disabled until we have the data from API
const accessLinkEnabled = false

export const ViewerControlMenu = ({
  selected,
  handleMenuItemChange,
  everyoneCount,
  viewersOnlyCount,
}: ViewerControlMenuProps) => {
  return (
    <Menu>
      <MenuButton
        leftIcon={<FontAwesomeIcon size="xs" icon={viewerIcons[selected]} />}
        as={Button}
        size="md"
        rightIcon={<FontAwesomeIcon size="xs" icon={regular('chevron-down')} />}
      >
        {getMenuLabelWithCount(selected, everyoneCount, viewersOnlyCount)}
      </MenuButton>
      {/* zIndex needs to > 1 in order to be on top of the horizontal card tree bars */}
      <MenuList zIndex={2}>
        <MenuOptionGroup
          defaultValue={selected}
          value={selected}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              handleMenuItemChange(value)
            }
          }}
        >
          <MenuItemOption value="everyone" isDisabled={everyoneCount === 0}>
            <Flex align="center">
              <FontAwesomeIcon icon={viewerIcons.everyone} size="xs" />
              <Box ml={3}>
                <Text>
                  {getMenuLabelWithCount(
                    'everyone',
                    everyoneCount,
                    viewersOnlyCount
                  )}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {`Everyone who has viewed, edited or created the ${DOC_DISPLAY_NAME}`}
                </Text>
              </Box>
            </Flex>
          </MenuItemOption>
          <MenuItemOption
            value="viewersOnly"
            isDisabled={viewersOnlyCount === 0}
          >
            <Flex align="center">
              <FontAwesomeIcon icon={viewerIcons.viewersOnly} size="xs" />
              <Box ml={3}>
                <Text>
                  {getMenuLabelWithCount(
                    'viewersOnly',
                    everyoneCount,
                    viewersOnlyCount
                  )}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {`Only people who viewed the ${DOC_DISPLAY_NAME}`}
                </Text>
              </Box>
            </Flex>
          </MenuItemOption>
          {accessLinkEnabled && (
            <MenuItemOption isDisabled={!accessLinkEnabled} value="accessLink">
              <Flex align="center">
                <FontAwesomeIcon icon={viewerIcons.accessLinkOnly} size="xs" />
                <Box ml={3}>
                  <Text>
                    {getMenuLabelWithCount(
                      'accessLink',
                      everyoneCount,
                      viewersOnlyCount
                    )}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {`Only people who accessed your ${DOC_DISPLAY_NAME} via an access link`}
                  </Text>
                </Box>
              </Flex>
            </MenuItemOption>
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
