import { OVERLAP_PCT_THRESHOLD, OVERLAP_PX_THRESHOLD } from './constants'

export type OverlapResult = {
  overlap: number
  viewing: boolean
  only: boolean
}

/**
 * Compute overlap and "viewing" of a card when in doc mode
 *
 * A card is considering being "viewed" if the card in question is expanded
 * AND any of the following are true
 *
 * 1. it's the only card in the viewport, it's height is higher than the viewport height
 * 2. the percentage overlap is > OVERLAP_PCT_THRESHOLD
 * 3. the absolute pixel overlap is > OVERLAP_PX_THRESHOLD
 */
export const computeIsViewing = (
  containerStart: number,
  containerEnd: number,
  start: number,
  end: number,
  expanded: boolean
): OverlapResult => {
  const length = end - start // Height of this card in pixels

  const a = Math.max(containerStart - start, 0) // Distance from top of scroller to top of this card
  const b = Math.max(end - containerEnd, 0) // Distance from bottom of scroller to bottom of this card

  const inFrame = Math.max(length - a - b, 0) // How many pixels of this card are in the viewport (frame)
  const overlap = inFrame / length // What percent of this card is in the viewport (frame)
  const only = overlap > 0 && inFrame == containerEnd - containerStart // The card is completely in view
  const viewing =
    expanded &&
    (only || overlap > OVERLAP_PCT_THRESHOLD || inFrame > OVERLAP_PX_THRESHOLD)

  return {
    overlap,
    viewing,
    only,
  }
}
