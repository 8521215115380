import { Button, Flex, Text } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import isHotkey from 'is-hotkey'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { keyboardHandler } from 'modules/keyboard'
import { EditorModeEnum } from 'modules/tiptap_editor'
import {
  selectIsEditingInSlideView,
  selectMode,
  setIsEditingInSlideView,
} from 'modules/tiptap_editor/reducer'

import { useNavigationControls } from './NavigationControls'
import { PresentScroll } from './PresentScroll'
import { ProgressBar } from './ProgressBar'

const isEscape = isHotkey('Esc')

export const SlideView = ({ editor }: { editor?: Editor }) => {
  const mode = useSelector(selectMode)
  const slideViewEnabled = mode === EditorModeEnum.SLIDE_VIEW
  const isEditing = useSelector(selectIsEditingInSlideView)
  const isEditingRef = useRef(isEditing)
  const dispatch = useDispatch()
  const slideViewEnabledAndNotEditing = slideViewEnabled && !isEditing

  useNavigationControls({ enabled: slideViewEnabledAndNotEditing })

  useEffect(() => {
    // Whether we go into or out of slide view, disable the "editing in slide view" state
    dispatch(setIsEditingInSlideView({ isEditingInSlideView: false }))
  }, [slideViewEnabled, dispatch])

  // Keep track of the latest value for isEditing so we dont have
  // to unbind/rebind the keydown listener whenever it changes
  isEditingRef.current = isEditing
  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (isEditingRef.current && isEscape(e)) {
        // Escape while editing in slideview should turn it off
        dispatch(setIsEditingInSlideView({ isEditingInSlideView: false }))
        e.preventDefault()
        return true
      }
      return false
    }
    return keyboardHandler.on('keydown', 'SLIDE_VIEW', keydownListener)
  }, [dispatch])

  return (
    <>
      {slideViewEnabled && <PresentScroll editor={editor} />}
      {slideViewEnabled && <ProgressBar />}
      {slideViewEnabled && (
        <Flex
          position="absolute"
          inset={0}
          width="100%"
          height="100%"
          pointerEvents={'none'}
          justify="flex-end"
        >
          <Flex
            bg="gray.50"
            position="fixed"
            bottom={6}
            right={6}
            p={3}
            px={6}
            justify="flex-end"
            align="center"
            opacity={isEditing ? 1 : 0}
            pointerEvents={isEditing ? 'auto' : 'none'}
            transitionProperty="opacity, transform"
            transitionDuration="normal"
            transitionTimingFunction="ease"
            transform={isEditing ? 'translate(0,0)' : 'translate(0,100%)'}
            borderRadius="md"
            shadow="md"
            zIndex="overlay"
          >
            <Text size="sm" mr={3}>
              You're editing this card.
            </Text>
            <Button
              size="sm"
              variant="solid"
              onClick={() =>
                dispatch(
                  setIsEditingInSlideView({ isEditingInSlideView: false })
                )
              }
            >
              Done
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}
