import { useToast } from '@chakra-ui/react'
import Knock, { FeedItem, FeedResponse } from '@knocklabs/client'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { config } from 'config'
import { Comment, ExistingUser, HealthCheckEventEmitter } from 'modules/api'

type CommentId = string
interface KnockCommentFeedItem extends FeedItem {
  data: {
    commentBody: string
    commentId: CommentId
    commentTime: string
    commentUrl: string
    commenterDisplayName: string
    commenterProfileImageUrl: string
    context: { targetHtml: string; targetText: string }
    docId: string
    docTitle: string
  }
}

interface GammaCommentFeedResponse extends FeedResponse {
  entries: KnockCommentFeedItem[]
}

type ExpiringFeedItem = {
  entry: KnockCommentFeedItem

  // The timeout can be in one of 3 states:
  //   null              - Active comment waiting to be assigned a timeout
  //   positive integer  - Timeout assigned and counting down
  //   negative one (-1) - Timeout expired but is being held due to hover
  timeout: null | NodeJS.Timeout | -1

  comment?: Comment
}

type ActiveExpiringFeedItem = ExpiringFeedItem & { comment: Comment }

const activeItemFilter = (
  item: ExpiringFeedItem
): item is ActiveExpiringFeedItem => {
  return Boolean(item.comment) && item.timeout !== null
}

interface NotificationFeedArgs {
  user: ExistingUser | undefined
  docId: string | undefined
  docComments?: Comment[]

  // When true, dont remove comments from the list
  pauseExiration: boolean
  duration?: number
}

const DEFAULT_EXPIRATION_TIME = 5000

export const useNotificationFeed = ({
  user,
  docId,
  docComments = [],
  pauseExiration = false,
  duration = DEFAULT_EXPIRATION_TIME,
}: NotificationFeedArgs): { comments: Comment[]; reset: () => void } => {
  const toast = useToast()
  const userId = user?.id
  const knockClient = useMemo(() => {
    if (!userId) return null
    const client = new Knock(config.KNOCK_PUBLIC_KEY)
    client.authenticate(userId)
    return client
  }, [userId])
  const [activeComments, setComments] = useState<
    Record<string, ExpiringFeedItem>
  >({})
  const isMounted = useRef(true)
  const shouldPauseExiration = useRef(pauseExiration)
  shouldPauseExiration.current = pauseExiration

  useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  const resetComments = useCallback(() => setComments({}), [])
  const removeComments = useCallback(
    /**
     * Helper to remove a specific comment (when a string id is provided)
     * OR
     * to remove all expired comments (when `true` is passed)
     */
    (idOrExpired: string | boolean) => {
      setComments((prev) =>
        Object.entries(prev).reduce((acc, [feedItemId, item]) => {
          const shouldRemove =
            idOrExpired === true
              ? item.timeout === -1
              : feedItemId === idOrExpired

          if (!shouldRemove) {
            acc[feedItemId] = item
          }
          return acc
        }, {})
      )
    },
    []
  )

  useEffect(() => {
    if (pauseExiration) return

    // When pauseExiration changes to false, delete any expired
    // out comments that are hanging around due to hover
    removeComments(true)
  }, [removeComments, pauseExiration])

  useEffect(() => {
    // Loop over all active comments and add a timeout to expire
    // them only once we've confirmed the doc.comments has arrived
    Object.entries(activeComments).forEach(
      ([feedItemId, { entry, timeout }]) => {
        if (timeout === null) {
          // We havent set a timeout to expire this yet
          const commentId = entry.data.commentId
          const comment = docComments?.find((c) => c.id === commentId)

          if (comment) {
            // Set a timeout to expire them
            const expireTimeout = setTimeout(() => {
              if (!isMounted.current) return

              // If its time to remove the comment but we're being
              // hovered over, mark the timeout with -1. It will be cleared later
              if (shouldPauseExiration.current) {
                setComments((prev) => {
                  const next = { ...prev }
                  next[feedItemId].timeout = -1
                  return next
                })
              } else {
                removeComments(feedItemId)
              }
            }, duration)

            setComments((prev) => {
              return {
                ...prev,
                [feedItemId]: {
                  entry,
                  comment,
                  timeout: expireTimeout,
                },
              }
            })
          }
        }
      }
    )
  }, [activeComments, docComments, duration, removeComments])

  useEffect(() => {
    if (!knockClient || !docId) return
    const notificationFeed = knockClient.feeds.initialize(
      config.KNOCK_FEED_ID,
      {
        source: 'new-comment',
        status: 'unseen',
      }
    )
    const teardown = notificationFeed.listenForUpdates()

    notificationFeed.on(
      'messages.new',
      // @ts-ignore
      ({ entries }: GammaCommentFeedResponse) => {
        // entries includes all messages arriving on the Knock feed with
        // the latest message being first in the list.
        // We check if docId matches current docId. A toast with the message
        // appears if the docId matches.
        const [entry] = entries
        if (entry.data.docId !== docId) {
          return
        }

        setComments((prev) => {
          return {
            ...prev,
            [entry.id]: { entry, timeout: null },
          }
        })

        notificationFeed
          .markAsSeen(entry)
          .then(() => {
            console.debug('New notification marked as seen')
          })
          .catch((error) => {
            console.error('Unable to mark notification as seen', error)
          })
      }
    )

    const unsubscribeOffline = HealthCheckEventEmitter.on(
      'status',
      ({ isConnected }) => {
        const apiClient = knockClient.client()
        if (isConnected) {
          apiClient.connectSocket()
        } else {
          apiClient.disconnectSocket()
        }
      }
    )

    return () => {
      unsubscribeOffline()
      teardown()
    }
  }, [docId, toast, knockClient])

  return {
    reset: resetComments,
    comments: Object.values(activeComments)
      .filter(activeItemFilter)
      .map((c) => c.comment),
  }
}
