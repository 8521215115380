import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, GammaTooltip } from '@gamma-app/ui'
import React from 'react'

import { AnalyticsPanelModal } from 'modules/analytics/components'
import { Doc } from 'modules/api'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { SharePanelModal } from 'modules/sharing/components/SharePanel'
import {
  getVisibility,
  isOtherOrgDoc,
  VisibilityMap,
} from 'modules/sharing/utils'
import { useCan, useUserContext } from 'modules/user'
import { joinUserNames } from 'utils/displayNames'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButton } from '../components/ToolbarButton'
import { TitleModal } from './TitleModal'

type SharingSettingsProps = {
  doc?: Doc
}
export const SharingSettings = ({ doc }: SharingSettingsProps) => {
  const {
    isOpen: isSharePanelOpen,
    onOpen: onSharePanelOpen,
    onClose: onSharePanelClose,
  } = useModalDisclosure({ id: 'sharePanelDisclosure' })
  const {
    isOpen: isTitleModalOpen,
    onOpen: onTitleModalOpen,
    onClose: onTitleModalClose,
  } = useModalDisclosure({ id: 'titleModalDisclosure' })
  const {
    isOpen: isAnalyticsPanelOpen,
    onClose: onAnalyticsPanelClose,
    onOpen: onAnalyticsPanelOpen,
  } = useDisclosure({ id: 'analyticsModalDisclosure' })

  const { user, currentWorkspace } = useUserContext()
  const canViewSharePanelButton = useCan('view', doc)
  const canOpenSharePanel = useCan('edit', doc)
  const canManage = useCan('manage', doc)

  if (!canViewSharePanelButton || !user || !doc) return null

  const collaborators = doc.collaborators || []
  const channels = doc.channels || []
  const sharedWithNames = joinUserNames(
    [...collaborators]
      // Make sure the current user comes first as "You"
      .sort((a, b) => {
        if (a.user.id === user.id) return -1
        if (b.user.id === user.id) return 1
        return 0
      })
      .map((c) => {
        if (c.user.id === user.id) return 'You'
        return c.user.displayName || ''
      })
      .concat(doc.orgAccess ? channels.map(({ name }) => name || '') : [])
  )

  const visibilityType = getVisibility(doc, user)
  const visibility = visibilityType ? VisibilityMap[visibilityType] : null
  const isFromOtherOrg = isOtherOrgDoc(doc, currentWorkspace)
  // We should never get this far, but err on the side of
  // caution by rendering nothing and logging an error
  if (visibility === null) {
    console.error('[SharePanel] Unknown VisibilityValue for doc', doc)
    return null
  }

  return (
    <Flex>
      <GammaTooltip
        label={
          <>
            {isFromOtherOrg && (
              <Text fontWeight="bold">
                This {DOC_DISPLAY_NAME} is managed by another workspace.
              </Text>
            )}
            {visibility.title(doc?.organization?.name) ? (
              <Text>{visibility.title(doc?.organization?.name)}</Text>
            ) : null}
            <Text fontSize="xs">
              {visibility.subtitle
                ? visibility.subtitle(sharedWithNames || '')
                : ''}
            </Text>
          </>
        }
      >
        <span>
          <ToolbarButton
            onMouseDown={preventDefaultToAvoidBlur}
            icon={
              <Box>
                {isFromOtherOrg && (
                  <FontAwesomeIcon icon={regular('passport')} />
                )}
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={visibility.primaryIcon} />
                  {visibility.secondaryIcon && (
                    <>
                      <FontAwesomeIcon
                        icon={solid('circle')}
                        inverse
                        transform="down-6 right-6"
                      />
                      <FontAwesomeIcon
                        icon={visibility.secondaryIcon}
                        transform="shrink-7 right-6 down-6"
                      />
                    </>
                  )}
                </span>
              </Box>
            }
            disabled={!canOpenSharePanel}
            onClick={() => {
              if (!doc.title || doc.title === '') {
                onTitleModalOpen()
              } else {
                onSharePanelOpen()
              }
            }}
          >
            Share
          </ToolbarButton>
        </span>
      </GammaTooltip>
      {isTitleModalOpen && (
        <TitleModal
          onClose={onTitleModalClose}
          isOpen={isTitleModalOpen}
          onSaveSuccess={() => {
            onTitleModalClose()
            onSharePanelOpen()
          }}
          docId={doc?.id}
        />
      )}
      <SharePanelModal
        doc={doc}
        isSharePanelOpen={isSharePanelOpen}
        onSharePanelClose={onSharePanelClose}
        onAnalyticsPanelOpen={onAnalyticsPanelOpen}
      />
      {isAnalyticsPanelOpen && (
        <AnalyticsPanelModal
          doc={doc}
          onSharePanelOpen={onSharePanelOpen}
          onAnalyticsPanelClose={onAnalyticsPanelClose}
          isAnalyticsPanelOpen={isAnalyticsPanelOpen}
          mode={canManage ? 'full' : 'personal'}
        />
      )}
    </Flex>
  )
}
