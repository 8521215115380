import { Flex, FlexProps, useBreakpointValue, useToken } from '@chakra-ui/react'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  LEFT_PANEL_DEFAULT_WIDTH,
  RIGHT_PANEL_DEFAULT_WIDTH,
} from 'modules/panels/constants'
import { useAppSelector } from 'modules/redux'

import { PanelLifecycle, PanelLifecycleContext } from './PanelLifecycle'
import { PanelResizer } from './PanelResizer'
import {
  closePanel,
  openPanel,
  selectIsPanelOpen,
  selectIsTransitionDisabled,
  selectPanelComponent,
  selectPanelWidth,
  setPanelWidth,
} from './reducer'
import { PanelPosition } from './types'

export const PanelUtils = {
  // Helper function for responsive styles based on whether panel will dock to the side or go over content
  ifPanelHasRoomToOpen: function ifPanelHasRoomToOpen<T>(
    dockedVal: T,
    overVal: T
  ): Record<string, T> {
    return { base: overVal, xl: dockedVal }
  },
}

type PanelContainerProps = {
  position: PanelPosition
  panelLifecycle: PanelLifecycle
}

const PanelContainer = ({ position, panelLifecycle }: PanelContainerProps) => {
  const panelRef = useRef<any>()
  const [isDragging, setIsDragging] = useState(false)
  const dispatch = useDispatch()
  const isOpen = useAppSelector(selectIsPanelOpen(position))
  const isTransitionDisabled = useAppSelector(
    selectIsTransitionDisabled(position)
  )

  const width = useAppSelector(selectPanelWidth(position))
  const Comp = useAppSelector(selectPanelComponent(position))
  const canCollapse = Comp?.canCollapse ?? false
  const onClose = useCallback(() => {
    dispatch(closePanel({ position }))
  }, [dispatch, position])
  const onOpen = useCallback(() => {
    dispatch(openPanel({ component: Comp! }))
  }, [Comp, dispatch])

  const setWidth = useCallback(
    (w: number) => {
      dispatch(setPanelWidth({ width: w, position }))
      panelLifecycle.emit('resize', {})
    },
    [dispatch, panelLifecycle, position]
  )

  const styles: FlexProps = {
    zIndex: Number(useToken('zIndices', 'overlay')) + 1,
    position: PanelUtils.ifPanelHasRoomToOpen('relative', 'fixed'),
    height: '100%',
    top: 0,
    bottom: 0,
    [position]: 0,
  }

  if (!isTransitionDisabled && !isDragging) {
    styles.transitionProperty = 'width'
    styles.transitionDuration = 'normal'
  }

  useEffect(() => {
    const cb = (e: any) => {
      if (e.propertyName === 'width') {
        panelLifecycle.emit('resize', {})
      }
    }

    panelRef.current.addEventListener('transitionend', cb)

    return () => {
      if (panelRef.current) {
        panelRef.current.removeEventListener('transitionend', cb)
      }
    }
  }, [panelLifecycle, panelRef])

  const hasRoomToOpen =
    useBreakpointValue(PanelUtils.ifPanelHasRoomToOpen(true, false)) || false

  // Auto-collapse on small screens
  useEffect(() => {
    if (isOpen && !hasRoomToOpen && canCollapse) {
      onClose()
    }
  }, [hasRoomToOpen, isOpen, onClose, canCollapse])

  return (
    <Flex
      ref={panelRef}
      {...styles}
      style={{
        width: isOpen || canCollapse ? width : 0,
      }}
      maxW="100%"
      data-print-hidden
    >
      {isOpen && position === 'right' && (
        <PanelResizer
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          position={position}
          width={width}
          setWidth={setWidth}
          panelRef={panelRef}
          onClose={onClose}
          initialWidth={RIGHT_PANEL_DEFAULT_WIDTH}
        />
      )}
      <PanelLifecycleContext.Provider value={panelLifecycle}>
        {Comp && (isOpen || canCollapse) && (
          <Comp
            isDragging={isDragging}
            isOpen={isOpen}
            closePanel={onClose}
            openPanel={onOpen}
            panelRef={panelRef}
            lifecycle={panelLifecycle}
            hasRoomToOpen={hasRoomToOpen}
          />
        )}
      </PanelLifecycleContext.Provider>
      {isOpen && position === 'left' && (
        <PanelResizer
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          position={position}
          width={width}
          setWidth={setWidth}
          panelRef={panelRef}
          onClose={onClose}
          initialWidth={LEFT_PANEL_DEFAULT_WIDTH}
        />
      )}
    </Flex>
  )
}

export const RightPanelContainer = memo(
  (props: Omit<PanelContainerProps, 'position'>) => (
    <PanelContainer position="right" {...props} />
  )
)
RightPanelContainer.displayName = 'RightPanelContainer'

export const LeftPanelContainer = memo(
  (props: Omit<PanelContainerProps, 'position'>) => (
    <PanelContainer position="left" {...props} />
  )
)
LeftPanelContainer.displayName = 'LeftPanelContainer'
