import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

import { CommentStatus, Doc } from 'modules/api'
import { TogglePanelConfig } from 'modules/panels/hooks'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { useUserContext } from 'modules/user'
import { useEditorContext } from 'sections/docs'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useHover } from 'utils/hooks'
import { parseUrlHash } from 'utils/url'

import { ToolbarButton } from '../components/ToolbarButton'
import { CommentNotificationList } from './Notifications'
import { useNotificationFeed } from './useNotificationFeed'

type CommentButtonProps = {
  isCommentsPanelOpen: boolean
  toggleCommentsPanel: (config?: TogglePanelConfig) => void
  doc?: Doc
  mode: EditorModeEnum
}

export const CommentButton = ({
  toggleCommentsPanel,
  isCommentsPanelOpen,
  doc,
  mode,
}: CommentButtonProps) => {
  const { user } = useUserContext()
  const { editor } = useEditorContext()
  const [ref, isHovered] = useHover<HTMLDivElement>()
  const { comments, reset } = useNotificationFeed({
    user,
    docId: doc?.id,
    docComments: doc?.comments,
    pauseExiration: isHovered,
  })
  const editorAndDocLoaded = Boolean(editor && doc && doc.comments)

  useEffect(() => {
    // Once the editor and doc have loaded, check the URL hash for
    // an intial comment to open (only happens once on initial load)
    if (!editorAndDocLoaded) return

    const { commentId: commentIdFromUrl } = parseUrlHash()
    if (!commentIdFromUrl) return

    TiptapEventBus.emit(EventBusEvent.OPEN_POPUP_COMMENT, {
      commentId: commentIdFromUrl,
      highlightComment: true,
    })
  }, [editorAndDocLoaded])

  useEffect(() => {
    // Clear all comment notifications when the panel opens or when we enter slide view
    if (isCommentsPanelOpen || mode === EditorModeEnum.SLIDE_VIEW) {
      reset()
    }
  }, [isCommentsPanelOpen, reset, mode])

  const isNotificationPopoverOpen =
    Boolean(comments.length) &&
    !isCommentsPanelOpen &&
    mode !== EditorModeEnum.SLIDE_VIEW

  const numComments =
    doc?.comments?.filter((c) => c.status === CommentStatus.Open).length || 0

  return (
    <Popover
      isLazy={true}
      lazyBehavior="keepMounted"
      returnFocusOnClose={false}
      isOpen={isNotificationPopoverOpen}
      placement="bottom"
    >
      <PopoverTrigger>
        <ToolbarButton
          onMouseDown={preventDefaultToAvoidBlur}
          onClick={toggleCommentsPanel}
          icon={<FontAwesomeIcon icon={regular('comment')} />}
          isActive={isCommentsPanelOpen}
          aria-label={isCommentsPanelOpen ? 'Hide comments' : 'Show comments'}
          borderRadius={5}
        >
          {numComments}
        </ToolbarButton>
      </PopoverTrigger>
      <Portal>
        {doc && (
          <PopoverContent bg="white" shadow="lg" border="none" ref={ref}>
            <PopoverArrow bg="white" />
            <CommentNotificationList
              editor={editor}
              user={user}
              commentFeedItems={comments}
              doc={doc}
            />
          </PopoverContent>
        )}
      </Portal>
    </Popover>
  )
}
