import { Box, BoxProps, Text } from '@chakra-ui/react'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { ParentSize } from '@visx/responsive'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Bar, BarRounded } from '@visx/shape'
import { getStringWidth } from '@visx/text'
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip'
import { useEffect, useMemo, useRef } from 'react'

import { Datum } from '../types'

type BarClickHandler = (
  e: React.MouseEvent<SVGPathElement, MouseEvent>,
  data: Datum
) => void

export type Data = Datum[]
type TooltipData = Datum

const defaultMargin = { top: 55, right: 20, bottom: 40, left: 24 }
const ZERO_BAR_HEIGHT = 6
const formatValue = (value: number) => {
  return Math.round(value).toString()
}

const getMaxStringWidthFromNumbers = (numbers: number[]) => {
  return Math.max(
    ...numbers.map(
      (num) =>
        getStringWidth(num.toString(), {
          fontFamily: 'Inter, sans-serif',
          fontSize: 'var(--chakra-fontSizes-sm)',
          fontWeight: '600',
        }) || 0
    )
  )
}

const gammaThemeTooltipStyles = {
  backgroundColor: 'var(--chakra-colors-blackAlpha-800)',
  color: 'var(--chakra-colors-gray-100)',
  fontFamily: 'Inter, sans-serif',
  boxShadow: 'var(--chakra-shadows-4)',
  fontSize: 'var(--chakra-fontSizes-xs)',
  borderRadius: 'var(--chakra-radii-sm)',
  fontWeight: 'var(--chakra-fontWeights-semibold)',
  borderWidth: '1px',
  borderColor: 'black',
  paddingRight: 'var(--chakra-space-1.5)',
  paddingLeft: 'var(--chakra-space-1.5)',
  paddingTop: 'var(--chakra-space-0.5)',
  paddingBottom: 'var(--chakra-space-0.5)',
  zIndex: 'var(--chakra-zIndex-tooltip)',
  minWidth: '60px',
  lineHeight: 'var(--chakra-lineHeights-base)',
}

const gray = 'var(--chakra-colors-gray-500)'
const lightGray = 'var(--chakra-colors-gray-200)'

// accessors
const getX = (data: Data) => data.map((d) => d.x)
const getY = (data: Data) => data.map((d) => d.y)

type BarChartInnerProps = {
  width: number
  height: number
  chartData: Data
  showZero: boolean
  shouldRoundYValues: boolean
  showMaxAndMiddle: boolean
  barColor: string
  TooltipInner: (props: Datum) => JSX.Element
  onClick?: BarClickHandler
  margin?: {
    top: number
    right: number
    bottom: number
    left: number
  }
  customTooltipStyles?: React.CSSProperties
}
const BarChartInner = ({
  width,
  height,
  margin = defaultMargin,
  chartData,
  TooltipInner,
  showZero,
  shouldRoundYValues,
  showMaxAndMiddle,
  barColor,
  customTooltipStyles,
  onClick = () => {},
}: BarChartInnerProps) => {
  const {
    showTooltip,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
  } = useTooltip<TooltipData>()

  const tooltipTimeout = useRef<undefined | number>()
  const tooltipTimeoutHiddenBar = useRef<undefined | number>()
  useEffect(() => {
    return () => {
      if (tooltipTimeout.current) {
        clearTimeout(tooltipTimeout.current)
      }
      if (tooltipTimeoutHiddenBar.current) {
        clearTimeout(tooltipTimeoutHiddenBar.current)
      }
    }
  }, [])

  // get all y values and max y value
  const yValues = getY(chartData)
  const maxYVal = Math.max(...yValues)

  // calculate the largest possible left axis label width to add to left margin
  const maxAndMiddleTickValues = [maxYVal, Math.round(maxYVal / 2)]
  const labelsToCheck = showMaxAndMiddle ? maxAndMiddleTickValues : yValues
  const maxStringWidth = getMaxStringWidthFromNumbers(labelsToCheck)

  // bounds
  const leftMarginWithOffset = margin.left + maxStringWidth
  const xMax = width - leftMarginWithOffset - margin.right
  // yMax represents the bottommost position of the chart
  const yMax = height - margin.top - margin.bottom

  const tooltipStyles = {
    ...defaultStyles,
    ...gammaThemeTooltipStyles,
    ...customTooltipStyles,
  }

  // scales
  const xScale = useMemo(
    () =>
      scaleBand<Datum['x']>({
        range: [0, xMax],
        domain: getX(chartData),
        padding: 0.3,
      }),
    [xMax, chartData]
  )
  const yScale = useMemo(
    () =>
      scaleLinear<Datum['y']>({
        range: [yMax, 0],
        round: true,
        domain: [0, maxYVal],
      }),
    [yMax, maxYVal]
  )

  /**
   * This attempts to handle two problems with displaying a bar with min height for y values equaling zero by:
   * 1. Using a minimum height adjustment value instead of scaling the min height for zero values,
   *    because if y values are all low (e.g., all 1s and 0s) the zero bar will appear too long.
   * 2. Making sure the minimum bar height for zero is not taller than the next non-zero min value
   *    when there is a large difference between the y values (e.g. both very high and low values).
   *    In this case, zero values will have a height of zero.
   */
  const scaledMinYGreaterThanZero = showZero
    ? yScale(Math.min(...yValues.filter((y) => y !== 0)))
    : yMax
  const shouldDisplayZeroBarHeight =
    showZero && yMax - scaledMinYGreaterThanZero > ZERO_BAR_HEIGHT

  return (
    <>
      <Box>
        <svg width={width} height={height}>
          {/* the chart background */}
          <rect
            x={0}
            y={0}
            rx={4}
            width={width}
            height={height}
            fill="var(--chakra-colors-gray-100)"
          />
          <Group top={margin.top} left={leftMarginWithOffset}>
            {chartData.map((d) => {
              // data value of y
              const yVal = d.y
              // data value of x
              const xVal = d.x
              const key = `bar-${xVal}-${yVal}`
              const barWidth = xScale.bandwidth()
              // barHeight is the difference between the total y distance and the scaled value of y
              const initialBarHeight = yMax - (yScale(yVal) ?? 0)
              // if we are showing zero bars, add minimum adjustment value
              const barHeight =
                shouldDisplayZeroBarHeight && initialBarHeight === 0
                  ? ZERO_BAR_HEIGHT
                  : initialBarHeight
              // the x position of the top left corner of the bar
              const barX = xScale(xVal) ?? 0
              // the y position of the top left corner of the bar
              const barY = yMax - barHeight
              return (
                <Group key={key}>
                  <BarRounded
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    radius={5}
                    topLeft={true}
                    topRight={true}
                    fill={showZero && yVal === 0 ? lightGray : barColor}
                    onMouseLeave={() => {
                      tooltipTimeout.current = window.setTimeout(() => {
                        hideTooltip()
                      }, 300)
                    }}
                    onMouseMove={(event) => {
                      if (tooltipTimeout.current) {
                        clearTimeout(tooltipTimeout.current)
                      }
                      /*
                       * localPoint takes an SVG MouseEvent or TouchEvent as input and returns a { x: number; y: number; }
                       * point coordinate (or null if the event has no ownerSVGElement) within the coordinate system of the SVG
                       */
                      const eventSvgCoords = localPoint(event)
                      showTooltip({
                        tooltipData: d,
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      })
                    }}
                    onClick={(e) => onClick(e, d)}
                  />
                  {/* hidden bar that fills up remaining vertical space to allow tooltips to show on entire y axis*/}
                  <Bar
                    x={barX}
                    y={0}
                    width={barWidth}
                    // To prevent using negative numbers for height
                    height={Math.max(0, yMax - barHeight)}
                    opacity={0}
                    onMouseLeave={() => {
                      tooltipTimeoutHiddenBar.current = window.setTimeout(
                        () => {
                          hideTooltip()
                        },
                        300
                      )
                    }}
                    onMouseMove={(event) => {
                      if (tooltipTimeoutHiddenBar.current) {
                        clearTimeout(tooltipTimeoutHiddenBar.current)
                      }
                      const eventSvgCoords = localPoint(event)
                      showTooltip({
                        tooltipData: d,
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      })
                    }}
                  />
                </Group>
              )
            })}
          </Group>
          <AxisLeft
            top={margin.top}
            left={leftMarginWithOffset}
            scale={yScale}
            stroke={lightGray}
            strokeWidth={2}
            tickValues={showMaxAndMiddle ? maxAndMiddleTickValues : undefined}
            numTicks={showMaxAndMiddle ? undefined : 2}
            hideZero
            tickFormat={shouldRoundYValues ? formatValue : undefined}
            tickLength={6}
            tickStroke={lightGray}
            tickLabelProps={() => ({
              fill: gray,
              textAnchor: 'end',
              fontFamily: 'Inter, sans-serif',
              fontSize: 'var(--chakra-fontSizes-sm)',
              fontWeight: '600',
              dy: '0.33em',
              dx: -4,
            })}
          />
          <AxisBottom
            top={yMax + margin.top}
            left={leftMarginWithOffset}
            scale={xScale}
            stroke={lightGray}
            numTicks={4}
            tickStroke={lightGray}
            tickLabelProps={() => ({
              fill: gray,
              textAnchor: 'middle',
              fontFamily: 'Inter, sans-serif',
              fontSize: 'var(--chakra-fontSizes-sm)',
              fontWeight: '600',
              dy: 4,
            })}
            strokeWidth={2}
            tickLength={6}
          />
        </svg>
      </Box>

      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <TooltipInner x={tooltipData.x} y={tooltipData.y} />
        </TooltipWithBounds>
      )}
    </>
  )
}

type BarChartProps = {
  chartTitle: string
  data: Data
  TooltipInner: (props: Datum) => JSX.Element
  showZero?: boolean
  shouldRoundYValues?: boolean
  showMaxAndMiddle?: boolean
  // if using Chakra colors, use variables, e.g. var(--chakra-colors-gray-500)
  barColor?: string
  customTooltipStyles?: React.CSSProperties
  onClick?: BarClickHandler
} & BoxProps

export const BarChart = ({
  chartTitle,
  TooltipInner,
  showZero = true,
  shouldRoundYValues = true,
  showMaxAndMiddle = true,
  data,
  barColor = 'var(--chakra-colors-trueblue-200)',
  customTooltipStyles,
  onClick = () => {},
  ...rest
}: BarChartProps) => {
  return (
    <Box position="relative" height="300px" {...rest}>
      <Text
        size="xs"
        color="gray.500"
        fontWeight="600"
        position="absolute"
        top={3}
        left="50%"
        transform="translateX(-50%)"
      >
        {chartTitle}
      </Text>

      <ParentSize>
        {({ width, height }) => {
          if (width < 10) return null
          return (
            <BarChartInner
              width={width}
              height={height}
              showZero={showZero}
              chartData={data}
              barColor={barColor}
              customTooltipStyles={customTooltipStyles}
              onClick={onClick}
              TooltipInner={TooltipInner}
              shouldRoundYValues={shouldRoundYValues}
              showMaxAndMiddle={showMaxAndMiddle}
            />
          )
        }}
      </ParentSize>
    </Box>
  )
}
