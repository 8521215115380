import { JSONContent } from '@tiptap/core'
import { useCallback } from 'react'

import { useDuplicateDocMutation } from 'modules/api'
import { useUserContext } from 'modules/user'

import { SCHEMA_VERSION } from '../schema'
import { jsonToYDocSnapshot } from './transform'
/**
 * Provides a way to duplicate a doc.
 * 1) [REQUIRED] If we only supply `docId` to the API, the API will take care
 *    of ascertaining the latest snapshot in the database.
 * 2) [OPTIONAL] If we pass `initialContent`, as we might directly from the editor,
 *    then the API will use that to create the duplicate
 * 3) [OPTIONAL] If we pass `channelId`, then the API will duplicate the doc
 *    associated with the given `channelId`.
 */
export const useDuplicateDoc = () => {
  const [duplicateDoc] = useDuplicateDocMutation()
  const { user, currentWorkspace } = useUserContext()

  const duplicate = useCallback(
    (
      docId: string,
      initialContent?: JSONContent | null,
      channelId?: string | null
    ) => {
      if (!user || !currentWorkspace?.id) {
        console.error(
          '[useDuplicateDoc] Cannot duplicate without a user or workspace',
          { user }
        )
        return Promise.reject(
          new Error('Cannot duplicate without a user or workspace')
        )
      }

      return duplicateDoc({
        variables: {
          duplicateDocInput: {
            orgId: currentWorkspace?.id,
            sourceDocId: docId,
            initialContent: initialContent
              ? {
                  ydoc: jsonToYDocSnapshot(initialContent),
                  schemaVersion: SCHEMA_VERSION,
                  editors: [user?.id!],
                }
              : undefined,
            ...(channelId ? { channels: [{ channelId }] } : {}),
          },
        },
      })
    },
    [duplicateDoc, user, currentWorkspace]
  )

  return duplicate
}
