import tinycolor from 'tinycolor2'

import { CardStats } from 'modules/api'
import { lerp } from 'utils/easings'

import { CardEngagementMode, CardLookupTable, CardTree } from './types'

type ColorForProgressParams = {
  degree: number
  alpha?: number
  startColor?: string
  endColor?: string
}

export const colorForProgress = ({
  degree,
  alpha = 1,
  startColor = '#ECC94B',
  endColor = '#38A169',
}: ColorForProgressParams) => {
  if (Number.isNaN(degree)) {
    return tinycolor('#e5e0df').toHex8String() // gray.200
  }

  const startHslColor = tinycolor(startColor).toHsl() // orange.500
  const endHslColor = tinycolor(endColor).toHsl() // green.500
  return tinycolor({
    h: lerp(startHslColor.h, endHslColor.h, degree),
    s: lerp(startHslColor.s, endHslColor.s, degree),
    l: lerp(startHslColor.l, endHslColor.l, degree),
    a: alpha,
  }).toHex8String()
}

export const formatCardsViewedTooltipText = (timeInMS?: number) => {
  if (timeInMS === undefined) return ''
  const timeInSeconds = timeInMS / 1000
  return `For a card to be considered "viewed" it must have been in the view of the browser for at least ${timeInSeconds} ${
    timeInSeconds === 1 ? 'second' : 'seconds'
  }.`
}

export const createCardLookupTable = (
  cardStats: CardStats[],
  key: CardEngagementMode
): CardLookupTable => {
  // transform data from [{cardId: string, key: value}] to {cardId: value}
  return cardStats.reduce((acc, curr) => {
    acc[curr.cardId] = curr[key]
    return acc
  }, {})
}

export const sumChildren = (tree: CardTree, totals: CardLookupTable) => {
  let sum = 0
  for (const parent in tree) {
    sum += totals[parent] ?? 0
    sum += sumChildren(tree[parent], totals)
  }
  return sum
}

export const findMaxParentTotal = (
  cardTree: CardTree,
  totals: CardLookupTable
) => {
  let max = 0
  for (const card in cardTree) {
    const currTotal = totals[card] ?? 0
    max = Math.max(currTotal + sumChildren(cardTree[card], totals), max)
  }
  return max
}
