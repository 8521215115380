import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import {
  CHANNEL_DISPLAY_NAME,
  DOC_DISPLAY_NAME,
  TagConfig,
} from '@gamma-app/ui'

import { Doc, ExistingWorkspace } from 'modules/api'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { GraphqlUser } from 'modules/user'
import { generateChannelUrl } from 'utils/url'

export const isOtherOrgDoc = (d?: Doc, workspace?: ExistingWorkspace) => {
  return d?.organization?.id !== workspace?.id
}

export const Visibility = {
  PRIVATE: 'private',
  INDIVIDUAL: 'individual',
  ORG_VIEW: 'org-view',
  ORG_COMMENT: 'org-comment',
  ORG_EDIT: 'org-edit',
  ORG_MANAGE: 'org-manage',
  CHANNEL_PRIVATE: 'channel-private',
  PUBLIC: 'public',
} as const
type VisibilityType = typeof Visibility[keyof typeof Visibility]
type VisibilityValue = {
  title: (org?: string) => string
  subtitle?: (names?: string) => string
  primaryIcon: FontAwesomeIconProps['icon']
  secondaryIcon?: FontAwesomeIconProps['icon']
}

export const VisibilityMap: Record<VisibilityType, VisibilityValue> = {
  [Visibility.PRIVATE]: {
    title: () => `This ${DOC_DISPLAY_NAME} is private.`,
    subtitle: () => `Only you can view and edit this ${DOC_DISPLAY_NAME}.`,
    primaryIcon: regular('lock'),
  },
  [Visibility.INDIVIDUAL]: {
    title: () => `This ${DOC_DISPLAY_NAME} is only shared with specific users.`,
    subtitle: (names?: string) =>
      `${names} can access this ${DOC_DISPLAY_NAME}.`,
    primaryIcon: regular('user'),
  },
  [Visibility.ORG_VIEW]: {
    title: (org) =>
      `This ${DOC_DISPLAY_NAME} is viewable by everyone at ${org}.`,
    primaryIcon: regular('building'),
    secondaryIcon: regular('eye'),
  },
  [Visibility.ORG_COMMENT]: {
    title: (org) =>
      `This ${DOC_DISPLAY_NAME} is viewable and commentable by everyone at ${org}.`,
    primaryIcon: regular('building'),
    secondaryIcon: regular('comment'),
  },
  [Visibility.ORG_EDIT]: {
    title: (org) =>
      `This ${DOC_DISPLAY_NAME} is editable by everyone at ${org}.`,
    primaryIcon: regular('building'),
    secondaryIcon: regular('pencil'),
  },
  [Visibility.ORG_MANAGE]: {
    title: (org) =>
      `This ${DOC_DISPLAY_NAME} is editable and shareable by everyone at ${org}.`,
    primaryIcon: regular('building'),
  },
  [Visibility.CHANNEL_PRIVATE]: {
    title: () => `Only ${CHANNEL_DISPLAY_NAME} members have access.`,
    primaryIcon: CHANNELS_ICON,
  },
  [Visibility.PUBLIC]: {
    title: () => `This ${DOC_DISPLAY_NAME} is publicly viewable.`,
    primaryIcon: regular('globe-americas'),
  },
}

export const getVisibility = (
  doc?: Doc,
  user?: GraphqlUser
): VisibilityType | null => {
  if (!doc) return null
  const result = doc?.publicAccess
    ? Visibility.PUBLIC
    : // All org access cases
    doc?.orgAccess === 'manage'
    ? Visibility.ORG_MANAGE
    : doc?.orgAccess === 'edit'
    ? Visibility.ORG_EDIT
    : doc?.orgAccess === 'comment'
    ? Visibility.ORG_COMMENT
    : doc?.orgAccess === 'view'
    ? Visibility.ORG_VIEW
    : // Shared into a private channel — unreachable for now
    doc?.channels && doc.channels.some((c) => c.permission !== null)
    ? Visibility.CHANNEL_PRIVATE
    : // Shared with individuals
    doc?.collaborators && doc?.collaborators?.length > 1
    ? Visibility.INDIVIDUAL
    : // Private
    isDocPrivate(doc, user)
    ? Visibility.PRIVATE
    : Visibility.INDIVIDUAL

  return result
}

export const getTagsForDoc = (
  doc: Doc,
  user?: GraphqlUser,
  currentWorkspace?: ExistingWorkspace
): TagConfig[] => {
  // TODO: Implement shared from another workspace
  if (!user) {
    return []
  }
  const visibility = getVisibility(doc, user)
  // Shared from another org
  const isOtherOrg = isOtherOrgDoc(doc, currentWorkspace)
  const otherOrgTagArr = !isOtherOrg
    ? []
    : [
        {
          id: 'shared-from-external-org',
          label: `Shared from ${
            doc?.organization?.name || 'an external workspace'
          }`,
          icon: regular('passport'),
          color: 'gray.500',
        },
      ]

  if (visibility === Visibility.PUBLIC) {
    // ONLY GAMMA ORG PEOPLE CAN SEE THIS
    return [
      {
        id: 'public',
        label: 'Public',
        icon: regular('globe-americas'),
        color: 'gray.500',
      },
      ...otherOrgTagArr,
    ]
  } else if (visibility === Visibility.PRIVATE) {
    return [
      {
        id: 'private',
        label: 'Private',
        icon: regular('lock'),
        color: 'gray.500',
      },
      ...otherOrgTagArr,
    ]
  } else if (visibility === Visibility.INDIVIDUAL) {
    const numCollaborators = (doc.collaborators || []).filter(
      (c) => c.user.id !== user.id // (exclude self)
    ).length
    const collabsNoun = numCollaborators === 1 ? 'other' : 'others'
    return [
      {
        id: 'collaborators',
        label:
          numCollaborators > 0
            ? `Shared with ${numCollaborators} ${collabsNoun}`
            : 'Shared',
        icon: regular('user'),
        color: 'gray.500',
      },
      ...otherOrgTagArr,
    ]
  } else if (isOtherOrg) {
    return otherOrgTagArr
  }
  return (
    doc?.channels?.map((channel) => {
      const { id: channelId, name, slug } = channel
      return {
        id: channelId,
        label: name,
        href: generateChannelUrl({ id: channelId, slug }),
        icon: CHANNELS_ICON,
      }
    }) || []
  )
}

export const getTagsForDocExcludingChannel = ({
  doc,
  user,
  currentWorkspace,
  channelToExclude,
}: {
  doc: Doc
  user?: GraphqlUser
  currentWorkspace?: ExistingWorkspace
  channelToExclude: string | null
}) => {
  return getTagsForDoc(doc, user, currentWorkspace).filter(
    (tag) => tag.id !== channelToExclude
  )
}
/**
 * Based on the heuristic here:
 * https://github.com/gamma-app/gamma/blob/572a1aa446c3bdb51e63a47e855b7151a5a8127f/packages/server/src/docs/docs.service.ts#L295-L311
 */
export const isDocPrivate = (doc: Doc, user?: GraphqlUser) => {
  return (
    doc &&
    user &&
    !doc.orgAccess &&
    !doc.publicAccess &&
    doc.createdBy?.id === user.id
  )
}
