import { Portal, useOutsideClick } from '@chakra-ui/react'
import { memo, useCallback, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { useDispatch } from 'react-redux'

import { useAppSelector } from 'modules/redux'

import { ManageCardMenu } from './ManageCardMenu'
import {
  clearLastManageCardMenuRef,
  closeManageCardMenu,
  selectManageCardMenuState,
} from './reducer'

export const ManageCardMenuPortal = memo(() => {
  const { isOpen, ref, options } = useAppSelector(selectManageCardMenuState)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const { styles } = usePopper(ref, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: { boundary: 'clippingParents' },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    strategy: 'absolute',
  })
  const dispatch = useDispatch()

  const closeAndSaveRef = useCallback(() => {
    dispatch(closeManageCardMenu({ saveRef: true }))
  }, [dispatch])

  const onClose = useCallback(() => {
    dispatch(closeManageCardMenu({ saveRef: false }))
  }, [dispatch])

  useOutsideClick({
    ref: {
      current: popperElement,
    },
    handler() {
      if (!isOpen) {
        return
      }
      closeAndSaveRef()
      // in the event where we click outside but the click is on
      // another menu activator button, we let this run (hide the menu)
      // and button click handler is responsible for checking the lastRef
      // to see if the thing we just closed was this menu.

      // The check happens between the above `onClose` and the menu button activator
      // `onClick` handler and before the setTimeout below
      // to ensure that a stale ref (which could be out of the DOM) doesn't sit in redux
      // state dispatch `clearLastManageCardMenuRef` to cleanup
      setTimeout(() => {
        dispatch(clearLastManageCardMenuRef())
      }, 0)
    },
  })
  useEffect(() => {
    // we want this to run on unmount.  Also `onClose` should be identity stable
    return () => onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Portal>
      {isOpen && options && (
        <ManageCardMenu
          {...options}
          style={{
            ...styles.popper,
          }}
          onClose={onClose}
          ref={setPopperElement}
        />
      )}
    </Portal>
  )
})
