import { Avatar, Flex, Text, useToast } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useRef } from 'react'
import tinycolor from 'tinycolor2'

import { User } from 'modules/api'
import { useLeftPanelWidth } from 'modules/panels/hooks'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useScrollManager } from 'modules/scroll'
import { getDomNodeFromPos, getTopCenterPosPct } from 'modules/tiptap_editor'
import {
  Collaborator,
  selectFollowingAttached,
  selectLocalCollaboratorAttached,
  selectLocalCollaboratorSpotlight,
  setFollowingAttached,
  setScroll,
} from 'modules/tiptap_editor/reducer'
import { __DEBUGGING_addDebuggingTripline } from 'utils/dom'

const TOP_OFFSET = 100

/**
 * Responsible for listening to the local scroll/wheel events
 * and updating state accordingly.
 */
export const useHandleScrollAndSelectionChange = (
  editor?: Editor,
  scrollingParentSelector: string = 'body'
) => {
  const dispatch = useAppDispatch()
  const scrollManager = useScrollManager('editor')
  const leftPanelWidth = useLeftPanelWidth()

  const localIsAttached = useAppSelector(
    selectLocalCollaboratorAttached,
    isEqual
  )
  const localSpotlight = useAppSelector(
    selectLocalCollaboratorSpotlight,
    isEqual
  )
  const localIsSpotlighting = Boolean(localSpotlight?.pos)

  useEffect(() => {
    if (!editor) return
    const handleWheel = debounce(
      () => {
        if (localIsAttached) {
          dispatch(setFollowingAttached({ attached: false }))
        }
      },
      500,
      { leading: true }
    )
    const removeTripline = __DEBUGGING_addDebuggingTripline({
      topOffset: TOP_OFFSET,
      requiredCookie: 'spotlightScrollDebug=true',
    })
    document.addEventListener('wheel', handleWheel, true)
    return () => {
      removeTripline()
      handleWheel.cancel()
      document.removeEventListener('wheel', handleWheel, true)
    }
  }, [
    editor,
    dispatch,
    localIsAttached,
    localIsSpotlighting,
    scrollingParentSelector,
  ])

  const isFollowingSomeoneAndAttached = useAppSelector(
    selectFollowingAttached,
    isEqual
  )

  useEffect(() => {
    // Relay our scroll position to others who may be following us.
    // If we're syncing scroll position via following someone else, dont compute our own.
    if (!editor || isFollowingSomeoneAndAttached) {
      return
    }
    const handleScroll = debounce(
      () => {
        // Dont relay programmatic editor scrolls to others
        if (scrollManager.inProgress) return

        const topPosPct = getTopCenterPosPct(editor, TOP_OFFSET, leftPanelWidth)
        if (!topPosPct) {
          console.debug(
            '[useHandleScrollAndSelectionChange][handleScroll] Unable to find topPosPct.'
          )
          return
        }

        dispatch(setScroll(topPosPct))
      },
      250,
      { trailing: true, maxWait: 500 }
    )

    const handleSelectionChange = debounce(
      () => {
        const rootEl = document.querySelector(
          scrollManager.scrollSelector || ''
        )
        const rootRect = rootEl?.getBoundingClientRect()
        const posToUse = editor.state.selection.from
        const domNodeToUse = getDomNodeFromPos(editor, posToUse)
        if (!rootEl || !rootRect || !domNodeToUse) {
          console.debug(
            '[useHandleScrollAndSelectionChange][handleSelectionChange] missing data',
            {
              domNodeToUse,
            }
          )
          return
        }

        const domNodeToUseRect = domNodeToUse.getBoundingClientRect()
        const coordsAtPos = editor.view.coordsAtPos(posToUse)
        const pctToUse = parseFloat(
          (
            (coordsAtPos.top - domNodeToUseRect.y) /
            domNodeToUseRect.height
          ).toFixed(2)
        )

        dispatch(
          setScroll({
            pos: posToUse,
            pct: pctToUse,
          })
        )
      },
      250,
      { trailing: true, maxWait: 500 }
    )

    // Sync initial state to everyone
    handleScroll()

    editor.on('selectionUpdate', handleSelectionChange)
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      editor.off('selectionUpdate', handleSelectionChange)
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [
    editor,
    dispatch,
    scrollManager,
    isFollowingSomeoneAndAttached,
    leftPanelWidth,
  ])
}

export const useFollow = () => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const follow = useCallback(
    ({ collaborator, localIsFollowing, localIsAttached }) => {
      const newValues: Partial<{
        attached: boolean
        following: string | null
      }> = {
        // Clicking a face always (re) attaches
        attached: true,
      }

      if (!localIsFollowing) {
        // Start following
        newValues.following = collaborator.sessionId
        // Show toast
        toast.closeAll()
        toast({
          duration: 2000,
          position: 'bottom',
          render: function FollowingToast() {
            return (
              <Flex
                bg="gray.50"
                p={3}
                px={6}
                align="center"
                borderRadius="md"
                shadow="md"
              >
                <Avatar
                  size="sm"
                  src={collaborator.profileImageUrl}
                  border={`solid 2px ${collaborator.color}`}
                  color={
                    tinycolor(collaborator.color).isDark() ? 'white' : 'black'
                  }
                  bg={collaborator.color}
                />
                <Text ml={2}>You're following {collaborator.name}.</Text>
              </Flex>
            )
          },
        })
      } else if (!localIsAttached) {
        // Close the re-attach toast
        toast.closeAll()
      } else {
        // Stop following
        newValues.following = null
      }
      console.debug(
        `[CollaboratorsPanel] Clicked on collaborator ${collaborator.name}. Setting following to ${newValues}`
      )
      dispatch(setFollowingAttached(newValues))
    },
    [toast, dispatch]
  )
  return follow
}

export const useFollowFromQueryParams = ({
  editor,
  collaborators,
  user,
}: {
  editor?: Editor
  collaborators: Collaborator[]
  user?: User
}) => {
  const followedOnStart = useRef(false)
  const follow = useFollow()
  const queryParams = new URLSearchParams(
    process.browser ? window.location.search : ''
  )
  const FOLLOWING_ID_FROM_QUERY_PARAMS = queryParams.get('following_id')
  const FOLLOW_ON_START = queryParams.get('follow_on_start')

  useEffect(() => {
    if (
      followedOnStart.current ||
      !FOLLOW_ON_START ||
      user?.id === FOLLOWING_ID_FROM_QUERY_PARAMS
    )
      return
    if (!editor) return
    const collaboratorToFollow = collaborators.find(
      (c) => c.id === FOLLOWING_ID_FROM_QUERY_PARAMS
    )
    if (collaboratorToFollow) {
      follow({
        collaborator: collaboratorToFollow,
        localIsAttached: false,
        localIsFollowing: false,
      })
      followedOnStart.current = true
    }
  }, [
    FOLLOWING_ID_FROM_QUERY_PARAMS,
    FOLLOW_ON_START,
    editor,
    collaborators,
    user,
    follow,
  ])
}
