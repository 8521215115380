import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Link,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NextLink from 'next/link'

import { CommentStatus, Doc } from 'modules/api'
import { useScrollDirection } from 'modules/scroll/hooks'
import { useUserContext } from 'modules/user'
import { useSessionStorage } from 'utils/hooks/useSessionStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

type MobileTopBarProps = {
  doc?: Pick<Doc, 'title' | 'comments'>
  onCommentFeedOpen: () => void
  onTocOpen: () => void
  commentsEnabled: boolean
}
export const MobileTopBar = ({
  doc,
  onCommentFeedOpen,
  onTocOpen,
  commentsEnabled = true,
}: MobileTopBarProps) => {
  const [lastHomeUrl] = useSessionStorage<string>(
    USER_SETTINGS_CONSTANTS.lastHomeUrl,
    '/'
  )
  const { user } = useUserContext()
  const numComments =
    doc?.comments?.filter((c) => c.status === CommentStatus.Open).length || 0
  const scrollDirection = useScrollDirection('editor')

  return (
    <HStack
      p={1}
      px={2}
      bg="gray.50"
      shadow="lg"
      position="sticky"
      top={0}
      transitionProperty="common"
      transitionDuration="normal"
      transform={
        scrollDirection == 'up' ? 'translate(0px,0px)' : 'translate(0px,-100%)'
      }
      opacity={scrollDirection == 'up' ? 1 : 0}
      zIndex="sticky"
    >
      {user && (
        <NextLink href={lastHomeUrl} passHref>
          <IconButton
            as={Link}
            aria-label="Home"
            _hover={{ bg: 'transparent', color: 'trueblue.500' }}
            icon={<FontAwesomeIcon icon={regular('home')} size="1x" />}
            variant="ghost"
            color="gray.700"
          />
        </NextLink>
      )}

      <Text flex={1} fontSize="sm" noOfLines={1} ml={2}>
        {doc?.title}
      </Text>
      <ButtonGroup variant="ghost">
        {commentsEnabled && (
          <Button
            _hover={{ bg: 'transparent', color: 'trueblue.500' }}
            color="gray.700"
            fontSize="sm"
            onClick={onCommentFeedOpen}
            leftIcon={<FontAwesomeIcon icon={regular('comment')} />}
          >
            {numComments}
          </Button>
        )}
        <IconButton
          aria-label="Open table of contents"
          _hover={{ bg: 'transparent', color: 'trueblue.500' }}
          color="gray.700"
          onClick={onTocOpen}
          icon={<FontAwesomeIcon icon={regular('bars')} />}
        />
      </ButtonGroup>
    </HStack>
  )
}
