import { SimpleGrid } from '@chakra-ui/react'

import { Theme } from 'modules/theming'

import { ThemePanelProps } from './ThemePanel'
import { ThemePreview } from './ThemePreview'

type ThemeListProps = {
  themes: Theme[]
} & ThemePanelProps

export const ThemeList = ({
  themes,
  currentTheme,
  setDocTheme,
  openThemeEditor,
}: ThemeListProps) => {
  return (
    <SimpleGrid columns={2} spacing={1} mt={2} mx={-2}>
      {[...themes]
        .sort((a, b) => {
          const regex = new RegExp(/\p{Emoji_Presentation}/, 'gu')
          const nameA = a.name.replace(regex, '').trim()
          const nameB = b.name.replace(regex, '').trim()

          return (
            // sort by priority first, then sort by name (ignoring punctuation/emoji)
            b.priority - a.priority ||
            nameA.localeCompare(nameB, 'en-US', { ignorePunctuation: true })
          )
        })
        .map((theme) => {
          return (
            <ThemePreview
              key={theme.id}
              theme={theme}
              isChecked={theme.id === currentTheme.id}
              setDocTheme={setDocTheme}
              openThemeEditor={openThemeEditor}
            />
          )
        })}
    </SimpleGrid>
  )
}
