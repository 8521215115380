import { Editor } from '@tiptap/core'
import { useCallback, useEffect, useState } from 'react'

import {
  createEmptyDraftComment,
  CreateTopLevelComment,
  DraftComment,
  findDraftCommentByAbsPos,
  useOnCommentSave,
} from 'modules/tiptap_editor/extensions/Annotatable'
import { MEMO_LEVEL_COMMENT_ABS_POS } from 'modules/tiptap_editor/extensions/Annotatable/constants'
import { GraphqlUser } from 'modules/user'

export const CommentFeedMemoLevelComment = ({
  user,
  editor,
}: {
  user: GraphqlUser
  editor: Editor
}) => {
  const [localDraft, setLocalDraft] = useState<DraftComment | null>(() => {
    const existingDraft = findDraftCommentByAbsPos(
      editor,
      MEMO_LEVEL_COMMENT_ABS_POS
    )
    const draft =
      existingDraft && existingDraft.length
        ? existingDraft[0]
        : createEmptyDraftComment(() => MEMO_LEVEL_COMMENT_ABS_POS, editor, '')
    return draft
  })

  useEffect(() => {
    // Store the draft when the component unmounts
    if (!localDraft || !editor) return
    return () => {
      editor.commands.createDraftComment?.(localDraft)
    }
  }, [localDraft, editor])

  const onCommentSave = useOnCommentSave({
    draftComment: localDraft,
    clearDraftComment() {
      if (localDraft) {
        editor!.commands.removeDraftComments?.([localDraft])
      }
      // reset to a new empty draft comment
      const draft = createEmptyDraftComment(
        () => MEMO_LEVEL_COMMENT_ABS_POS,
        editor!,
        '' // use an empty string for doc level comments' targetHtml
      )
      editor!.commands.createDraftComment?.(draft)
      setLocalDraft(draft)
    },
    editor: editor!,
  })

  const onCommentDraftUpdate = useCallback(({ editor: commentEditor }) => {
    setLocalDraft((data) => {
      // uninitialized null case
      if (!data) return data

      return {
        ...data,
        json: commentEditor.view.state.doc.toJSON(),
        text: commentEditor.view.state.doc.textContent,
      }
    })
  }, [])

  return (
    <CreateTopLevelComment
      draftComment={localDraft}
      user={user as GraphqlUser}
      onCommentDraftUpdate={onCommentDraftUpdate}
      onCommentSave={onCommentSave}
      commentIdToHighlight={null}
    />
  )
}
