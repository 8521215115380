import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { useCallback } from 'react'

import { Doc } from 'modules/api'

import { FullAnalyticsView } from './FullAnalyticsView'
import { PersonalAnalyticsView } from './PersonalAnalyticsView'

type AnalyticsPanelModalProps = {
  doc: Doc
  mode: 'full' | 'personal'
  isAnalyticsPanelOpen: boolean
  onAnalyticsPanelClose: () => void
  onSharePanelOpen: () => void
}

export const AnalyticsPanelModal = ({
  doc,
  mode,
  isAnalyticsPanelOpen,
  onAnalyticsPanelClose,
  onSharePanelOpen,
}: AnalyticsPanelModalProps) => {
  const modalSize = useBreakpointValue({
    base: 'sm',
    md: '2xl',
    lg: '4xl',
  })

  const handleSharePanelOpen = useCallback(() => {
    onSharePanelOpen()
    onAnalyticsPanelClose()
  }, [onSharePanelOpen, onAnalyticsPanelClose])

  return (
    <Modal
      onClose={onAnalyticsPanelClose}
      isOpen={isAnalyticsPanelOpen}
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent>
        {mode === 'full' ? (
          <FullAnalyticsView
            doc={doc}
            handleSharePanelOpen={handleSharePanelOpen}
            onAnalyticsPanelClose={onAnalyticsPanelClose}
          />
        ) : (
          <PersonalAnalyticsView
            doc={doc}
            onAnalyticsPanelClose={onAnalyticsPanelClose}
          />
        )}
        <Divider my={4} />
        {/* Footer */}
        <ModalFooter>
          <Flex
            justifyContent={mode === 'full' ? 'space-between' : 'flex-end'}
            flex={1}
          >
            {mode === 'full' && (
              <Button
                leftIcon={<FontAwesomeIcon icon={regular('share')} />}
                variant="ghost"
                onClick={handleSharePanelOpen}
              >
                Share this {DOC_DISPLAY_NAME}
              </Button>
            )}
            <Button variant="solid" onClick={onAnalyticsPanelClose}>
              Done
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
