import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react'
import { DOC_DISPLAY_NAME, DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import React, { useCallback, useEffect, useState } from 'react'

import {
  GetThemesDocument,
  useArchiveThemeMutation,
  useGetThemeQuery,
} from 'modules/api'
import { Theme } from 'modules/theming'

export const DeleteThemeModal = ({
  theme,
  isOpen,
  onClose,
}: {
  theme: Theme
  isOpen: boolean
  onClose: () => void
}) => {
  const [docCount, setDocCount] = useState<number>()

  const [archiveTheme] = useArchiveThemeMutation()
  const toast = useToast()

  // Refetch here because docCount is expensive to calculate so we only get it
  // when you delete it
  const { data: fetchedTheme, loading } = useGetThemeQuery({
    variables: {
      id: theme?.id,
    },
    skip: !theme || theme.id === 'new',
  })

  useEffect(() => {
    if (loading || !fetchedTheme) return
    if (fetchedTheme.theme?.docCount) {
      setDocCount(fetchedTheme.theme.docCount)
    }
  }, [loading, fetchedTheme, setDocCount])

  const onArchive = useCallback(() => {
    if (!theme || !theme.id) return

    archiveTheme({
      variables: { id: theme.id },
      update: (cache, { data }) => {
        if (!data?.archiveTheme) return

        // Write the newly created theme into the cache so it shows up in the
        // list without refreshing
        cache.writeQuery({
          query: GetThemesDocument,
          variables: {
            workspaceId: theme.workspaceId,
            archived: false,
          },
          data: {
            themes: [data.archiveTheme],
          },
        })
      },
      optimisticResponse: {
        archiveTheme: {
          __typename: 'Theme',
          ...theme,
          archived: true,
          updatedTime: new Date().toISOString(),
        },
      },
    })
      .then(() => {
        toast({
          title: `Theme ${theme.name} has been deleted`,
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error(`Couldn't create theme ${theme.name} error: ${err}`)
      })
  }, [archiveTheme, theme, toast])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {theme.name}?</ModalHeader>
        <ModalBody>
          {docCount && docCount > 0 ? (
            <Text mb={2}>
              It's currently in use by <strong>{docCount}</strong>{' '}
              {docCount > 1 ? DOC_DISPLAY_NAME_PLURAL : DOC_DISPLAY_NAME}.
              Existing {DOC_DISPLAY_NAME_PLURAL} will not be changed, but new{' '}
              {DOC_DISPLAY_NAME_PLURAL} will no longer be able to select it.
            </Text>
          ) : loading ? (
            <Skeleton h="1em" mb={2} />
          ) : (
            <Text mb={2}>
              It's not used by any {DOC_DISPLAY_NAME_PLURAL} right now.
            </Text>
          )}
          <Text color="red.500">This action cannot be undone.</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={() => {
                onArchive()
                onClose()
              }}
            >
              Delete Theme
            </Button>
          </ButtonGroup>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
