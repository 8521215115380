import { Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import isHotkey from 'is-hotkey'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useFeatureFlag } from 'modules/featureFlags'
import { keyboardHandler } from 'modules/keyboard'
import { selectIsAnyModalOpen } from 'modules/modal_state/reducer'
import { useAppSelector } from 'modules/redux'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { editorHasFocus } from 'modules/tiptap_editor'
import {
  ZOOM_LEVEL_DEFAULT,
  ZOOM_LEVEL_MAX,
  ZOOM_LEVEL_MIN,
} from 'modules/tiptap_editor/extensions/Card/constants'
import { selectZoomLevel, setZoomLevel } from 'modules/tiptap_editor/reducer'
import { isEditableElement } from 'utils/dom'

import { useEditorContext } from '../../../context'

export const ZoomControls = () => {
  const enabled = useFeatureFlag('presentZoom')
  const { editor } = useEditorContext()
  const analytics = useAnalytics()

  const dispatch = useDispatch()
  const zoomLevel = useAppSelector(selectZoomLevel)
  const zoomIn = useCallback(() => {
    const newLevel = Math.min(ZOOM_LEVEL_MAX, zoomLevel + 0.1)
    dispatch(setZoomLevel({ zoomLevel: newLevel }))
    analytics?.track(SegmentEvents.DOC_ZOOMED, {
      direction: 'in',
      level: newLevel,
    })
  }, [dispatch, analytics, zoomLevel])
  const zoomOut = useCallback(() => {
    const newLevel = Math.max(ZOOM_LEVEL_MIN, zoomLevel - 0.1)
    dispatch(setZoomLevel({ zoomLevel: newLevel }))
    analytics?.track(SegmentEvents.DOC_ZOOMED, {
      direction: 'in',
      level: newLevel,
    })
  }, [dispatch, analytics, zoomLevel])
  const resetZoom = useCallback(() => {
    const newLevel = ZOOM_LEVEL_DEFAULT
    dispatch(setZoomLevel({ zoomLevel: newLevel }))
    analytics?.track(SegmentEvents.DOC_ZOOMED, {
      direction: 'reset',
      level: newLevel,
    })
  }, [dispatch, analytics])

  const isAnyModalOpen = useAppSelector(selectIsAnyModalOpen)

  useEffect(() => {
    if (!enabled) return
    const keydownListener = (ev: KeyboardEvent) => {
      if (
        isAnyModalOpen ||
        !editor ||
        editorHasFocus(editor) ||
        isEditableElement(ev.target as HTMLElement)
      ) {
        return false
      }
      if (isHotkey('=')(ev)) {
        zoomIn()
        ev.preventDefault()
        return true
      }

      if (isHotkey('-')(ev)) {
        zoomOut()
        ev.preventDefault()
        return true
      }

      if (isHotkey('0')(ev)) {
        resetZoom()
        ev.preventDefault()
        return true
      }

      return false
    }
    return keyboardHandler.on('keydown', 'PRESENT_BLOCK', keydownListener)
  }, [isAnyModalOpen, enabled, editor, zoomIn, zoomOut, resetZoom])

  if (!enabled) return null
  return (
    <ButtonGroup spacing={'0'} size="sm" variant="plain" isAttached>
      <GammaTooltip
        closeOnMouseDown={false}
        closeOnClick={false}
        shortcut="-"
        label="Zoom out"
      >
        <IconButton
          aria-label="Zoom out"
          fontWeight="normal"
          onClick={zoomOut}
          icon={<FontAwesomeIcon icon={regular('minus')} />}
        />
      </GammaTooltip>
      <GammaTooltip
        closeOnMouseDown={false}
        closeOnClick={false}
        shortcut="0"
        label="Reset zoom"
      >
        <Button fontWeight="normal" borderX={'none'} onClick={resetZoom} w={12}>
          {(zoomLevel * 100).toFixed(0)}%
        </Button>
      </GammaTooltip>
      <GammaTooltip
        closeOnMouseDown={false}
        closeOnClick={false}
        shortcut="+"
        label="Zoom in"
      >
        <IconButton
          fontWeight="normal"
          aria-label="Zoom in"
          onClick={zoomIn}
          icon={<FontAwesomeIcon icon={regular('plus')} />}
        />
      </GammaTooltip>
    </ButtonGroup>
  )
}
