import { Flex, useDisclosure } from '@chakra-ui/react'
import { MobileDrawer } from '@gamma-app/ui'
import { useCallback, useState } from 'react'

import { Doc } from 'modules/api'
import { CollaborativeEditor } from 'modules/tiptap_editor'
import { TimeOnCard } from 'modules/tiptap_editor/TimeOnCard/TimeOnCard'

import { DocEditorType, DocEditorTypeType } from '../../../constants'
import { useEditorContext } from '../../../context'
import { CommentFeed } from '../CommentFeed/CommentFeed'
import { ExampleStatic } from '../ExampleStatic'
import { PublicStatic } from '../PublicStatic'
import { TableOfContents } from '../TableOfContents/TableOfContents'
import { MOBILE_EDITOR_SCROLLING_PARENT_SELECTOR } from './constants'
import { MobileTopBar } from './MobileTopBar'

export const MobileEditorWrapper = ({
  doc,
  docEditorType,
  commentsEnabled = true,
}: {
  doc?: Pick<Doc, 'title' | 'comments' | 'currentSnapshotId'>
  docEditorType?: DocEditorTypeType
  commentsEnabled?: boolean
}) => {
  const { docId, setCollaborativeEditorInstance, editor } = useEditorContext()
  const {
    isOpen: isCommentFeedPanelOpen,
    onOpen: onCommentFeedPanelOpen,
    onClose: onCommentFeedPanelClose,
  } = useDisclosure({
    id: 'mobile-comment-feed-panel-drawer',
  })
  const {
    isOpen: isTocOpen,
    onOpen: onTocOpen,
    onClose: onTocClose,
  } = useDisclosure({
    id: 'mobile-toc-drawer',
  })
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)
  console.debug(
    `%c[MobileEditorWrapper] RENDER - ${docId}`,
    'background-color: gold'
  )

  return (
    <Flex
      ref={useCallback((node) => setRootElement(node), [])}
      direction="column"
      flex={1}
    >
      <MobileTopBar
        doc={doc}
        onCommentFeedOpen={onCommentFeedPanelOpen}
        onTocOpen={onTocOpen}
        commentsEnabled={commentsEnabled}
      />
      {docEditorType === DocEditorType.PUBLIC_STATIC ? (
        <PublicStatic
          docId={docId}
          snapshotId={doc?.currentSnapshotId}
          scrollingParentSelector={MOBILE_EDITOR_SCROLLING_PARENT_SELECTOR}
          onCreate={setCollaborativeEditorInstance}
        />
      ) : docEditorType === DocEditorType.EXAMPLE_STATIC ? (
        <ExampleStatic
          docId={docId}
          snapshotId={doc?.currentSnapshotId}
          scrollingParentSelector={MOBILE_EDITOR_SCROLLING_PARENT_SELECTOR}
          onCreate={setCollaborativeEditorInstance}
        />
      ) : (
        <>
          <CollaborativeEditor
            // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
            doc={doc}
            docId={docId}
            // Force readonly on mobile
            readOnly={true}
            // This gives us access to the tiptap instance belonging to the Collaborative Editor
            // in case we need it for running editor commands from anywhere inside this tree.
            onCreate={setCollaborativeEditorInstance}
            scrollingParentSelector={MOBILE_EDITOR_SCROLLING_PARENT_SELECTOR}
          />
          {editor && <TimeOnCard editor={editor} />}
        </>
      )}
      {commentsEnabled && isCommentFeedPanelOpen && (
        <MobileDrawer
          isOpen={isCommentFeedPanelOpen}
          onClose={onCommentFeedPanelClose}
          drawerProps={{ autoFocus: false }}
        >
          <CommentFeed onClose={onCommentFeedPanelClose} editor={editor} />
        </MobileDrawer>
      )}
      {isTocOpen && (
        <MobileDrawer isOpen={isTocOpen} onClose={onTocClose}>
          <TableOfContents rootElement={rootElement} handleClose={onTocClose} />
        </MobileDrawer>
      )}
    </Flex>
  )
}
