import { Link, Text, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'

import { Doc, useUnArchiveDocMutation } from 'modules/api'
import { DocEditorType, DocEditorTypeType } from 'sections/docs/constants'

const DOC_ARCHIVED_TOAST_ID = 'doc_archived_warning'

export const useArchivedToast = (
  doc?: Pick<Doc, 'id' | 'archived'>,
  docEditorType?: DocEditorTypeType
) => {
  const toast = useToast()
  const [unarchiveDoc] = useUnArchiveDocMutation()
  useEffect(() => {
    return () => {
      toast.close(DOC_ARCHIVED_TOAST_ID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!doc || docEditorType !== DocEditorType.COLLABORATIVE) return

    if (doc.archived && !toast.isActive(DOC_ARCHIVED_TOAST_ID)) {
      toast({
        id: DOC_ARCHIVED_TOAST_ID,
        title: (
          <Text data-testid="trash-confirmation-toast">
            Want to edit this? It's in the trash.{' '}
            <Link
              textDecoration="underline"
              onClick={() => {
                const variables = { id: doc.id }
                unarchiveDoc({
                  variables,
                  optimisticResponse: {
                    unarchiveDoc: {
                      ...variables,
                      archived: true,
                      __typename: 'Doc',
                    },
                  },
                })
              }}
            >
              Restore it.
            </Link>
          </Text>
        ),
        status: 'warning',
        position: 'top',
        isClosable: false,
        duration: null,
      })
    } else if (!doc.archived && toast.isActive(DOC_ARCHIVED_TOAST_ID)) {
      toast.close(DOC_ARCHIVED_TOAST_ID)
    }
  }, [doc, docEditorType, toast, unarchiveDoc])
}
