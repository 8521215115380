export const HELP_WIDGET_RIGHT_MARGIN = 4

export const HELP_WIDGET_BOTTOM_MARGIN = 4

export const HELP_WIDGET_POPOVER_CONTENT_PROPS = {
  mr: HELP_WIDGET_RIGHT_MARGIN,
  width: 'auto',
  outline: '0',
  shadow: 'lg',
  _focus: { outline: 'none' },
}
