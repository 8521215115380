import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  IconButton,
  LinkBox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { format, parseISO } from 'date-fns'
import { memo, useCallback } from 'react'

import { config } from 'config'
import { GetThemesDocument, useDuplicateThemeMutation } from 'modules/api'
import { getThemeCSSVars, Theme } from 'modules/theming'
import { ThemeFontLoader } from 'modules/theming/components/FontLoader'
import { CARD_CONTENT_CLASS } from 'modules/tiptap_editor/extensions/Card'
import { useUserContext } from 'modules/user'

import {
  getBackgroundProps,
  getDocOrThemeBackground,
} from '../../../styles/backgroundStyles'
import {
  DEFAULT_CONTAINER,
  getContainerStyles,
} from '../../../styles/containerStyles'
import { DeleteThemeModal } from './DeleteThemeModal'

type ThemePreviewProps = {
  theme: Theme
  setDocTheme: (newTheme: Theme) => void
  isChecked: boolean
  openThemeEditor: (themeToEdit?: Theme) => void
}

export const ThemePreview = memo(
  ({ theme, setDocTheme, isChecked, openThemeEditor }: ThemePreviewProps) => {
    const { container = DEFAULT_CONTAINER, contentStyles } = theme.config
    const background = getDocOrThemeBackground(theme)
    const { isDark } = container
    const cssVars = getThemeCSSVars(theme)
    const toast = useToast()
    const createdTime =
      theme.createdTime && format(parseISO(theme.createdTime), 'PPP')

    const openThemeEditorWithThisTheme = useCallback(() => {
      openThemeEditor(theme)
    }, [openThemeEditor, theme])

    const {
      isOpen: isDeleteThemeModalOpen,
      onOpen: onDeleteThemeModalOpen,
      onClose: onDeleteThemeModalClose,
    } = useDisclosure({ id: 'deleteThemeModalDisclosure' })

    const { user, currentWorkspace, isGammaOrgUser } = useUserContext()
    const [duplicateTheme] = useDuplicateThemeMutation()

    const onDuplicate = useCallback(() => {
      if (!user || !currentWorkspace) return

      const { id, ...rest } = theme
      duplicateTheme({
        variables: { id },
        update: (cache, { data }) => {
          if (!data?.duplicateTheme) return

          // Write the newly created theme into the cache so it shows up in the
          // list without refreshing
          cache.writeQuery({
            query: GetThemesDocument,
            variables: {
              workspaceId: theme.workspaceId,
              archived: false,
            },
            data: {
              themes: [data.duplicateTheme],
            },
          })
        },
        optimisticResponse: {
          duplicateTheme: {
            id: 'temp',
            __typename: 'Theme',
            ...rest,
            name: `${theme.name} (copy)`,
            archived: false,
            createdTime: new Date().toISOString(),
            updatedTime: new Date().toISOString(),
          },
        },
      })
        .then(({ data }) => {
          if (!data) return
          toast({
            title: `Theme ${data.duplicateTheme.name} has been saved`,
            status: 'success',
            duration: 3000,
            position: 'top',
            isClosable: true,
          })
        })
        .catch((err) => {
          console.error(`Couldn't duplicate theme ${theme.name} error: ${err}`)
          toast({
            title: `Couldn't duplicate theme. ${err}`,
            status: 'error',
            duration: 3000,
            position: 'top',
            isClosable: false,
          })
        })
    }, [duplicateTheme, theme, user, currentWorkspace, toast])

    return (
      <Box
        as={LinkBox}
        bgColor={isChecked ? 'trueblue.100' : undefined}
        textAlign="left"
        tabIndex={0}
        borderRadius="md"
        transitionProperty="common"
        transitionDuration="normal"
        _focus={{
          boxShadow: 'outline',
        }}
        outline="none"
        onClick={() => setDocTheme(theme)}
        _hover={{
          bg: 'trueblue.50',
        }}
        css={cssVars}
        p={2}
        data-theme-id={theme.id}
        data-testid={isChecked ? 'current-theme-preview' : undefined}
        cursor="pointer"
      >
        <ThemeFontLoader theme={theme} />
        {/* The background */}
        <Flex
          boxShadow="sm"
          _focus={{
            boxShadow: 'outline',
          }}
          p={4}
          transitionProperty="common"
          transitionDuration="normal"
          {...getBackgroundProps(background, container.isDark)}
          backgroundAttachment="initial"
          minH={'9em'}
          direction="row"
          align="center"
        >
          {/* The container */}
          <Box
            sx={{ ...getContainerStyles(container, theme), ...contentStyles }}
            p={4}
            borderRadius="base"
            className={`${CARD_CONTENT_CLASS} ${isDark ? 'is-dark' : ''}`}
            color={isDark ? 'white' : 'gray.900'}
            flex={1}
          >
            <Text
              className="block-title"
              mt={'0 !important'}
              mb={'var(--chakra-space-2) !important'}
            >
              <Text
                as="span"
                className="title"
                fontSize="1.8em !important"
                paddingBottom="0 !important"
              >
                Title
              </Text>
            </Text>
            <Text
              fontSize="sm"
              noOfLines={3}
              className="block block-paragraph"
              my="0 !important"
            >
              Body &{' '}
              <Text as="a" className="link">
                link
              </Text>
            </Text>
          </Box>
        </Flex>
        <Flex align="baseline" mt={1}>
          {isChecked && (
            <CheckIcon w={3} h="auto" mr={1} color="trueblue.700" />
          )}
          <Text
            fontSize="sm"
            noOfLines={2}
            data-testid="theme-name"
            color={isChecked ? 'trueblue.700' : undefined}
          >
            {theme.name}
          </Text>
          <Spacer />
          {(theme.workspaceId ||
            (isGammaOrgUser &&
              config.APPLICATION_ENVIRONMENT !== 'production')) && (
            <Menu>
              <MenuButton
                as={IconButton}
                isRound
                size="sm"
                variant="ghost"
                colorScheme="blackAlpha"
                icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
                onClick={(ev) => ev.stopPropagation()} // Prevents clicking the ... from changing the theme
              >
                Actions
              </MenuButton>
              <MenuList
                zIndex="overlay"
                onClick={(ev) => ev.stopPropagation()} // Prevents clicking a menu item from changing the theme
              >
                <Box px={4} py={0}>
                  <Text>{theme.name}</Text>
                  <Text fontSize="sm" color="gray.500" noOfLines={1}>
                    Created {createdTime}
                  </Text>
                  {theme.createdBy && (
                    <GammaTooltip label={theme.createdBy.email}>
                      <Text
                        display="inline-block"
                        fontSize="sm"
                        color="gray.500"
                        noOfLines={1}
                      >
                        by {theme.createdBy.displayName}
                      </Text>
                    </GammaTooltip>
                  )}
                </Box>
                <MenuDivider />
                <MenuItem
                  icon={
                    <FontAwesomeIcon icon={regular('palette')} fixedWidth />
                  }
                  onClick={openThemeEditorWithThisTheme}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={regular('clone')} fixedWidth />}
                  onClick={onDuplicate}
                >
                  Duplicate
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  color="red"
                  icon={<FontAwesomeIcon icon={regular('trash')} fixedWidth />}
                  onClick={onDeleteThemeModalOpen}
                >
                  Delete
                </MenuItem>
              </MenuList>

              <DeleteThemeModal
                theme={theme}
                isOpen={isDeleteThemeModalOpen}
                onClose={onDeleteThemeModalClose}
              />
            </Menu>
          )}
        </Flex>
      </Box>
    )
  }
)

ThemePreview.displayName = 'ThemePreview'
