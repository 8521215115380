import { useToast } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { useRouter } from 'next/router'
import { memo, useEffect, useRef, useState } from 'react'

import { config } from 'config'
import { useGetCardsLazyQuery, useGetDocLazyQuery } from 'modules/api'
import { useAccessLinkCollaborator } from 'modules/auth/accesslink_signup/useAccessLinkCollaborator'
import { useSyncCardsRedux } from 'modules/cards'
import { replaceState } from 'modules/history'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { CARDS_SUBSCRIPTION_GQL } from 'modules/tiptap_editor/extensions/Card/graphql'
import { useCan, useUserContext, useUserSignedIn } from 'modules/user'
import { slugify } from 'utils/slugify'
import { DOC_NANOID_REGEX, getPathnameParts } from 'utils/url'

import { DOC_SUBSCRIPTION_GQL } from '../graphql'
import {
  reset,
  selectDoc,
  selectIsCollaborativeDocEditorType,
  setDoc,
} from '../reducer'
import { EditorContext, EditorContextType } from './EditorContext'

export * from './EditorContext'
type EditorContextProps = {
  children: React.ReactNode
}

export interface OptionalEditorContextType
  extends Omit<EditorContextType, 'docId'> {
  docId?: string
}
/**
 * The root context provider for the Document Editor, responsible for:
 *   - Parsing the path for the documentID and providing that to the tree
 *   - Fetching the document once it's ID has been parsed
 *   - Rewriting the path prefix to include the current document title
 *     e.g.
 *      /docs/kpdade17i6w6lzu
 *      >
 *      /docs/Summer-Event-Outline-kpdade17i6w6lzu
 *
 */
const EditorContextProvider = ({
  children,
}: EditorContextProps): JSX.Element => {
  const toast = useToast()
  const isCollaborativeEditorType = useAppSelector(
    selectIsCollaborativeDocEditorType
  )
  const { user, isUserLoading } = useUserContext()
  const collaborativeEditorInstanceRef = useRef<Editor | null>(null)

  const reduxDispatch = useAppDispatch()
  const { push, replace } = useRouter()
  const doc = useAppSelector(selectDoc)
  const [docPrefix, docUrlSlug] = getPathnameParts()
  const urlNanoid = docUrlSlug && docUrlSlug.match(DOC_NANOID_REGEX)
  const docId = urlNanoid ? urlNanoid[0] : null
  const [contextState, setContextState] = useState<OptionalEditorContextType>({
    isUnauthorized: undefined, // value has not been initialized because we dont know docData or user
    setCollaborativeEditorInstance: (editor) => {
      collaborativeEditorInstanceRef.current = editor
      setContextState((prevState) => {
        return { ...prevState, editor: editor }
      })
    },
    getCollaborativeEditorInstance: () =>
      collaborativeEditorInstanceRef.current,
    editor: undefined,
  })

  useAccessLinkCollaborator(doc)
  const [
    getDoc,
    {
      data: docData,
      loading: docLoading,
      called: getDocCalled,
      subscribeToMore,
    },
  ] = useGetDocLazyQuery({
    variables: {
      id: docId as string,
    },
    returnPartialData: true,
    nextFetchPolicy: 'cache-only',
  })
  const canView = useCan('view', docData?.doc)

  useUserSignedIn(getDoc)

  useEffect(() => {
    if (
      !process.browser ||
      !subscribeToMore ||
      !contextState.docId ||
      !getDocCalled
    ) {
      return
    }

    return subscribeToMore({
      document: DOC_SUBSCRIPTION_GQL,
      variables: { id: contextState.docId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.doc || !prev.doc) {
          return prev
        }
        // Shallow merge the result with the existing doc
        return {
          doc: {
            ...prev.doc,
            ...subscriptionData.data.doc,
            __typename: 'Doc',
          },
        }
      },
    })
  }, [contextState.docId, subscribeToMore, getDocCalled])

  const [
    getCards,
    {
      data: docCardsData,
      called: getCardsCalled,
      subscribeToMore: subscribeToMoreCards,
    },
  ] = useGetCardsLazyQuery({
    variables: {
      docId: docId as string,
    },
    returnPartialData: true,
    nextFetchPolicy: 'cache-only',
  })

  // Sync the cards into redux for consumers to easily subscribe to a single card
  useSyncCardsRedux(docCardsData?.docCards)

  useEffect(() => {
    if (
      !process.browser ||
      !subscribeToMoreCards ||
      !contextState.docId ||
      !getCardsCalled
    ) {
      return
    }

    return subscribeToMoreCards({
      document: CARDS_SUBSCRIPTION_GQL,
      variables: { docId: contextState.docId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.docCards)
          return prev

        // Result here will be merged based on the docCards merge policy
        return subscriptionData.data
      },
    })
  }, [contextState.docId, subscribeToMoreCards, getCardsCalled])

  useEffect(() => {
    // Once the docId is set, this hook should never run again.
    if (contextState.docId) return
    // The query param is not available immediately
    if (!docUrlSlug) return
    // Show a toast if the url doesnt have a valid doc id
    if (!docId) {
      toast({
        id: `invalid_doc_id_error`,
        title: `Oops! That doesn't look like a valid document url.`,
        description: '',
        status: 'error',
        position: 'top',
        isClosable: false,
        duration: null,
      })
      // Redirect to homescreen?
      return
    }
    // Save the docId in context state and fetch the doc from the API
    getDoc()
    getCards()
    setContextState((prevState) => {
      if (prevState.docId == docId) {
        return prevState
      }
      return { ...prevState, docId }
    })
  }, [
    contextState.docId,
    docUrlSlug,
    docId,
    getDoc,
    getCards,
    reduxDispatch,
    toast,
  ])

  useEffect(() => {
    /**
     * Redirect to other pages if necessary.
     * Note this handles redirects for all DocEditorTypes in one place.
     */
    if (isUserLoading || !getDocCalled || docLoading || !docUrlSlug) return // Too early
    const confirmedNoUser = !user
    // Doc and user fetches have completed. If you don't have view permission on the doc,
    // you'set unauthorized.
    if (!docData || !docData.doc || canView === false) {
      if (confirmedNoUser) {
        // ANONYMOUS and no access -> redirect to 404
        push(`/signin?redirect_to=${encodeURIComponent(window.location.href)}`)
      }
      setContextState((prevState) => {
        return { ...prevState, isUnauthorized: true }
      })
      return
    }

    // The doc is loaded, and you have at least view permission
    if (isCollaborativeEditorType && confirmedNoUser && !config.SHARE_TOKEN) {
      // No user and no token trying to access /docs page for a public doc
      // For now, dont allow this and redirect to /public
      // Keep the hash

      replace(
        `/public/${docUrlSlug}/${window.location.search}${window.location.hash}`
      )
      return
    }

    if (!isCollaborativeEditorType && docData.doc.archived) {
      // If we archive a public or example doc (i.e. non collaborative editor types at /public or /example),
      // we don't want people to be able to access them any more
      replace('/')
      return
    }

    // doc + user has been loaded, has permission to view
    setContextState((prevState) => {
      return { ...prevState, isUnauthorized: false }
    })
    reduxDispatch(setDoc({ doc: docData.doc }))
  }, [
    canView,
    docData,
    docLoading,
    docUrlSlug,
    getDocCalled,
    isCollaborativeEditorType,
    isUserLoading,
    push,
    reduxDispatch,
    replace,
    user,
  ])

  useEffect(() => {
    return () => {
      // Reset the context state when context unmounts
      reduxDispatch(reset())
    }
  }, [reduxDispatch])

  useEffect(() => {
    if (!doc || !docId) return

    // Once we have a doc and its ID, ensure the URL is slugified
    // with the current title of the doc.
    const expectedSlug = `${doc.title ? `${slugify(doc.title)}-` : ''}${docId}`
    if (docUrlSlug !== expectedSlug) {
      // Replace the current path with the slugified path, preserving the search and hash params
      replaceState({
        pathname: `/${docPrefix}/${expectedSlug}`,
        emitChange: false,
      })
    }
  }, [doc, docId, docUrlSlug, docPrefix])

  // Dont render the tree until we have a docId
  if (!contextState.docId) return <></>

  return (
    <EditorContext.Provider value={contextState as EditorContextType}>
      {children}
    </EditorContext.Provider>
  )
}

export function withEditorContext<T>(Comp: React.FC<T>) {
  const WithEditorComponent = (props: T) => (
    <EditorContextProvider>
      <Comp {...props} />
    </EditorContextProvider>
  )

  return memo(WithEditorComponent)
}
