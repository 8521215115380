import { Box, Center, Flex, Spinner } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { Editor, Extension } from '@tiptap/core'
import merge from 'lodash/merge'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useGetSnapshotQuery } from 'modules/api'
import { useCloneToast } from 'modules/example_decks/components/CloneToast'
import { getStore, useAppSelector } from 'modules/redux'
import { EditorCore } from 'modules/tiptap_editor'
import { Spotlight } from 'modules/tiptap_editor/extensions/spotlight'
import {
  Collaborator,
  setCollaborators,
  setLocalCollaboratorId,
} from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'

import { selectDoc } from '../../reducer'
import { MadeWithGamma } from './MadeWithGamma'

const localSessionId = 'sessionId1'

// TODO: Move this to the base cursor extension in EditorCore
// linear: https://linear.app/gamma-app/issue/G-1096/move-polyfill-logic-for-cursor-extension-in-static-editors-into-the
const CursorExtension = Extension.create<{}, { user: Partial<Collaborator> }>({
  addStorage() {
    return {
      user: {},
    }
  },
  addCommands() {
    return {
      user: (attributes: Partial<Collaborator>) => () => {
        // A short circuit implementation of the actual CollaborationCursor extension's .user command
        // Just updates redux with the new values for the single user.
        const store = getStore()
        this.storage.user = merge({}, this.storage.user, attributes)

        store.dispatch(
          setLocalCollaboratorId({
            sessionId: localSessionId,
          })
        )
        store.dispatch(
          setCollaborators({
            collaborators: [this.storage.user] as Collaborator[],
          })
        )
        return true
      },
    }
  },
})

/**
 * This is a non-collaborative (no Hocuspocus) version of the DocEditor that loads the
 * data for a snapshot instead. We pull in a couple extra extensions to support present mode
 * because the present mode state is coupled to the YJS awareness bus at the moment.
 */

export const ExampleStatic = ({
  docId,
  snapshotId,
  scrollingParentSelector,
  onCreate,
}: {
  docId: string
  snapshotId?: string
  scrollingParentSelector: string
  onCreate: (editor: Editor) => void
}) => {
  const doc = useAppSelector(selectDoc)
  useCloneToast(doc)
  const [extensions] = useState(() => [
    Spotlight.configure({ scrollerSelector: scrollingParentSelector }),
    CursorExtension,
  ])
  const { user, anonymousUser, isUserLoading, color } = useUserContext()
  const [editor, setEditor] = useState<Editor>()
  const { data, loading, error } = useGetSnapshotQuery({
    variables: { docId, snapshotId: snapshotId as string },
    skip: !snapshotId,
  })

  useEffect(() => {
    // Copied from PublicStatic component:
    // https://github.com/gamma-app/gamma/blob/6ac2fc1f550deb052e6f07e957c614d2f9c272a5/packages/client/src/sections/docs/editor/components/PublicStatic.tsx#L86-L98
    if (isUserLoading || !editor) return

    editor.commands.user({
      id: user?.id || anonymousUser.id,
      color: color.value,
      sessionId: localSessionId,
      idleSince: null,
      name: user?.displayName || anonymousUser.displayName || '',
      profileImageUrl: user?.profileImageUrl || '',
      isReady: !isUserLoading,
    })
  }, [editor, user, color, isUserLoading, anonymousUser])

  const isReady = data && data.snapshot !== undefined && !loading && doc

  return (
    <Box data-testid="example-static-editor-wrapper" flex="1" height="100%">
      {!isReady || error ? (
        <Flex
          direction="column"
          height="100%"
          flex="1"
          bg="gray.100"
          alignItems="center"
          justifyContent="center"
        >
          {!isReady && <Spinner />}
          {error && (
            <Center h="100%">
              There was an error loading the {DOC_DISPLAY_NAME}: {error.message}
            </Center>
          )}
        </Flex>
      ) : (
        <Box
          transitionProperty="common"
          transitionDuration="750ms"
          transitionTimingFunction="ease-out"
          opacity={isReady ? 1 : 0}
        >
          <EditorCore
            // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
            doc={doc}
            docId={docId}
            extensions={extensions}
            onCreate={({ editor: editorInstance }) => {
              setEditor(editorInstance)
              // A timeout here gives the editor nodeview components
              // a chance to render before we remove the skeleton
              setTimeout(() => {
                // Notify the parent that the editor has been created, in case it also needs
                // access to the editor instance so that it can run editor commands
                if (onCreate) {
                  onCreate(editorInstance)
                }
              }, 10)
            }}
            readOnly={true}
            initialContent={data.snapshot?.content.default}
            scrollingParentSelector={scrollingParentSelector}
          />
        </Box>
      )}
      <MadeWithGamma />
    </Box>
  )
}
