import {
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  capitalize,
  docTitleOrPlaceholder,
  getShortcutTextForOS,
  DOC_DISPLAY_NAME,
  GammaTooltip,
  LoadingToastInner,
} from '@gamma-app/ui'
import { format, parseISO } from 'date-fns'
import { useCallback } from 'react'

import { AnalyticsPanelModal } from 'modules/analytics/components'
import {
  Doc,
  useArchiveDocMutation,
  useHealthCheck,
  useUnArchiveDocMutation,
} from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { OfflineInfoBox } from 'modules/offline'
import { SharePanelModal } from 'modules/sharing'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'
import { useCan, useUserContext } from 'modules/user'
import { useEditorContext } from 'sections/docs'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { SnapshotsDrawer } from '../Snapshots'
import { ToolbarIconButton } from './components/ToolbarButton'
import { useHandlePDFExport } from './pdfHooks'

export const DocMenu = ({ doc }: { doc: Doc }) => {
  const { user } = useUserContext()
  const { isConnected } = useHealthCheck()
  const duplicateDoc = useDuplicateDoc()
  const { getCollaborativeEditorInstance } = useEditorContext()
  const title = docTitleOrPlaceholder(doc?.title)
  const createdTime =
    doc?.createdTime && format(parseISO(doc.createdTime), 'PPP')
  const docId = doc?.id
  const {
    isOpen: isSnapshotsDrawerOpen,
    onClose: onSnapshotsDrawerClose,
    onOpen: onSnapshotsDrawerOpen,
  } = useModalDisclosure({ id: 'snapshotsDrawer' })

  const {
    isOpen: isAnalyticsPanelOpen,
    onClose: onAnalyticsPanelClose,
    onOpen: onAnalyticsPanelOpen,
  } = useModalDisclosure({ id: 'analyticsPanelDisclosure' })

  const {
    isOpen: isSharePanelOpen,
    onClose: onSharePanelClose,
    onOpen: onSharePanelOpen,
  } = useModalDisclosure({ id: 'sharePanelDisclosure' })
  const toast = useToast()

  const [archiveDoc] = useArchiveDocMutation()
  const [unarchiveDoc] = useUnArchiveDocMutation()
  const canDuplicateDoc = Boolean(user && user.organizations!.length !== 0)
  const canExportDoc = useCan('view', doc) && Boolean(user)
  const canViewSnapshots = useCan('edit', doc)
  const canTrashDoc = useCan('manage', doc)
  const newPDFExportEnabled = useFeatureFlag('newPDFExport')
  const analyticsEnabled = useFeatureFlag('analytics') && Boolean(user)
  const canManage = useCan('manage', doc) && Boolean(user)

  const handleDuplicateDoc = () => {
    const editor = getCollaborativeEditorInstance()
    if (!editor) return
    const initialContent = editor.getJSON()
    const duplicateToast = toast({
      id: `duplicate-toast-${doc.id}`,
      position: 'top',
      duration: null,
      render: function LoadingToast({ onClose, id }) {
        return (
          <LoadingToastInner
            title="Duplicating..."
            isClosable={false}
            onClose={onClose}
            id={id}
          />
        )
      },
    }) as string
    duplicateDoc(doc.id, initialContent).then((result) => {
      if (!result?.data?.duplicateDoc) {
        console.error('[DocMenu] handleDuplicateDoc error')
        return
      }
      const { id } = result?.data?.duplicateDoc
      console.debug(
        `[Menu] Doc duplicated. New doc id (${id}). Navigating to Editor`
      )
      toast.update(duplicateToast, {
        status: 'success',
        description: 'Success!',
        duration: 5000,
      })
      // Hard reload to cleanly reset the editor state
      window.location.href = `/docs/${id}`
    })
  }

  const handlePrintPDF = useCallback(() => {
    setTimeout(() => {
      window.print()
    }, 50)
  }, [])

  const handleTrashClick = useCallback(
    (archived: boolean) => () => {
      if (!docId) return

      const variables = { id: docId }
      if (archived) {
        archiveDoc({
          variables,
          optimisticResponse: {
            archiveDoc: { ...variables, archived, __typename: 'Doc' },
          },
        })
      } else {
        unarchiveDoc({
          variables,
          optimisticResponse: {
            unarchiveDoc: { ...variables, archived, __typename: 'Doc' },
          },
        })
      }
    },
    [archiveDoc, docId, unarchiveDoc]
  )
  const { handlePDFExport, exportInProgress } = useHandlePDFExport(
    getCollaborativeEditorInstance(),
    doc
  )

  const showInitialDivider =
    canViewSnapshots ||
    canDuplicateDoc ||
    analyticsEnabled ||
    newPDFExportEnabled ||
    !isConnected

  return (
    <Menu>
      <MenuButton
        as={ToolbarIconButton}
        borderRadius="md"
        // We need to do this to suppress the tooltip when the drawer is open
        isDisabled={isSnapshotsDrawerOpen}
        icon={<FontAwesomeIcon icon={regular('ellipsis-h')} />}
        // Prevent the focus event from firing. Without this, clicking this button would trigger a focus on this element, revealing the tooltip when a user changes browser tabs then comes back (annoying!). See https://stackoverflow.com/a/45131490
        onMouseDown={preventDefaultToAvoidBlur}
        data-testid="doc-toolbar-menu-button"
      />
      <Portal>
        {doc && (
          <MenuList zIndex="popover">
            <Box px={4} py={0} maxWidth="300px">
              <Text>{title}</Text>
              <Text fontSize="sm" color="gray.500" noOfLines={1}>
                Created {createdTime}
              </Text>
              <GammaTooltip label={doc?.createdBy?.email}>
                <Text
                  display="inline-block"
                  fontSize="sm"
                  color="gray.500"
                  noOfLines={1}
                >
                  by {doc?.createdBy?.displayName}
                </Text>
              </GammaTooltip>
              {/* TBD what we'll actually show here, but leaving this in for ease */}
              {/* <Text
                display="inline-block"
                fontSize="sm"
                color="gray.500"
                noOfLines={1}
              >
                Last edited {formatDistanceToNow(new Date(doc.editedTime))} ago
              </Text> */}
            </Box>
            {showInitialDivider && <MenuDivider />}
            <OfflineInfoBox
              label={`${capitalize(
                DOC_DISPLAY_NAME
              )} options will be available when you reconnect.`}
              isConnected={isConnected}
              maxWidth="300px"
            />
            {isConnected && (
              <>
                {canViewSnapshots && (
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('history')} fixedWidth />
                    }
                    onClick={onSnapshotsDrawerOpen}
                  >
                    History
                  </MenuItem>
                )}
                {analyticsEnabled && (
                  <MenuItem
                    icon={
                      <FontAwesomeIcon
                        icon={regular('chart-column')}
                        fixedWidth
                      />
                    }
                    onClick={onAnalyticsPanelOpen}
                  >
                    Analytics
                  </MenuItem>
                )}
                {canDuplicateDoc && (
                  <MenuItem
                    icon={<FontAwesomeIcon icon={regular('copy')} fixedWidth />}
                    onClick={handleDuplicateDoc}
                  >
                    Duplicate
                  </MenuItem>
                )}
                {!newPDFExportEnabled && canExportDoc && (
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('file-pdf')} fixedWidth />
                    }
                    onClick={handlePrintPDF}
                    command={getShortcutTextForOS('Mod+P')}
                  >
                    Print/export PDF
                  </MenuItem>
                )}
                {newPDFExportEnabled && canExportDoc && (
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('file-pdf')} fixedWidth />
                    }
                    onClick={handlePDFExport}
                    isDisabled={exportInProgress}
                  >
                    Export PDF
                  </MenuItem>
                )}
                {canTrashDoc && <MenuDivider />}
                {canTrashDoc ? (
                  doc.archived ? (
                    /**
                     * TODO - Add option to "Permanently Delete" when archived
                     * See https://gamma-nt35338.slack.com/archives/C01CSCC3XU2/p1624934659026600?thread_ts=1624926112.013300&cid=C01CSCC3XU2
                     **/
                    <MenuItem
                      icon={
                        <FontAwesomeIcon
                          icon={regular('trash-restore')}
                          fixedWidth
                        />
                      }
                      onClick={handleTrashClick(false)}
                    >
                      Restore
                    </MenuItem>
                  ) : (
                    <MenuItem
                      data-testid="doc-menu-trash-button"
                      color="red.500"
                      icon={
                        <FontAwesomeIcon icon={regular('trash')} fixedWidth />
                      }
                      onClick={handleTrashClick(true)}
                    >
                      Send to trash
                    </MenuItem>
                  )
                ) : null}
              </>
            )}
          </MenuList>
        )}
      </Portal>
      {/* SnapshotsDrawer needs to be in this particular position in the Menu tree; otherwise,
          the browser's focus gets messed up (i.e. trapped inside MenuList items) */}
      {doc && (
        <SnapshotsDrawer
          doc={doc}
          isOpen={isSnapshotsDrawerOpen}
          onClose={onSnapshotsDrawerClose}
        />
      )}
      {/* Share panel modal is here so the analytics modal can open it */}
      {doc && (
        <>
          <AnalyticsPanelModal
            doc={doc}
            isAnalyticsPanelOpen={isAnalyticsPanelOpen}
            onAnalyticsPanelClose={onAnalyticsPanelClose}
            onSharePanelOpen={onSharePanelOpen}
            mode={canManage ? 'full' : 'personal'}
          />
          <SharePanelModal
            doc={doc}
            isSharePanelOpen={isSharePanelOpen}
            onSharePanelClose={onSharePanelClose}
            onAnalyticsPanelOpen={onAnalyticsPanelOpen}
          />
        </>
      )}
    </Menu>
  )
}
