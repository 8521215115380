import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'

import { Doc, DocChannel } from 'modules/api'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { useUpdatePublicChannels } from 'modules/sharing/useUpdatePublicChannels'

export const ChannelRow = ({
  channel,
  doc,
  isDisabled,
}: {
  channel: DocChannel
  doc: Doc
  isDisabled: boolean
}) => {
  const { removeDocChannel } = useUpdatePublicChannels()
  const onRemoveClick = useCallback(() => {
    if (!doc) return
    removeDocChannel(doc, channel.id)
  }, [channel.id, doc, removeDocChannel])

  return (
    <HStack
      py={2}
      key={channel.id}
      w="100%"
      justify="space-between"
      data-testid={`workspace-row-${channel.id}`}
    >
      <Box>
        <Flex alignItems="center" opacity={isDisabled ? 0.5 : 1}>
          <Box mx={2}>
            {isDisabled ? (
              <span className="fa-stack">
                <FontAwesomeIcon
                  icon={regular('slash')}
                  className="fa-stack-1x"
                />
                <FontAwesomeIcon
                  icon={CHANNELS_ICON}
                  className="
                  fa-stack-1x
               "
                />
              </span>
            ) : (
              <FontAwesomeIcon icon={CHANNELS_ICON} />
            )}
          </Box>
          <Box ml={3}>
            <Text>{channel.name}</Text>
            {/* TODO: Add channel membership info */}
            {/* https://linear.app/gamma-app/issue/G-1992/add-channel-membership-info */}
            {/* <Flex align="center">
              <Text fontSize="sm" color="gray.400">
                {memberCount} {memberCount === 1 ? 'member' : 'members'}
                {channel.isMember && ', including you.'}
              </Text>
            </Flex> */}
          </Box>
        </Flex>
      </Box>
      <Button
        size="sm"
        variant="ghost"
        colorScheme="red"
        onClick={onRemoveClick}
      >
        Remove
      </Button>
    </HStack>
  )
}
