import {
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip, SectionTitle, ScreenshotPreview } from '@gamma-app/ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { Doc } from 'modules/api'
import { useGetCard } from 'modules/cards'
import { useFeatureFlag } from 'modules/featureFlags'
import { navigateToCardLink } from 'modules/tiptap_editor/extensions/Link'
import { useEditorContext } from 'sections/docs'
import { lerp } from 'utils/easings'

import { CardLookupTable, CardTree } from '../types'
import { colorForProgress, findMaxParentTotal, sumChildren } from '../utils'
import { CircleCheckmark } from './CardsViewedProgressBar'

const scalePercentageLinearly = (max: number) => (x: number) =>
  lerp(0, 100, x / max)

type HorizontalBarCardTreeProps = {
  docId: Doc['id']
  cards: CardTree
  cardLookupTable: CardLookupTable
  isColorized?: boolean
  scaleToMax?: boolean
  includeChildren: boolean
  leftLabel: string
  rightLabel: string
  tooltipLabel: React.ReactNode | string
  getScreenshotLabel?: (x: number) => string
  onAnalyticsPanelClose: () => void
}

export const HorizontalBarCardTree = ({
  docId,
  cards,
  isColorized = false,
  scaleToMax = false,
  includeChildren,
  leftLabel,
  rightLabel,
  getScreenshotLabel,
  tooltipLabel,
  cardLookupTable,
  onAnalyticsPanelClose,
}: HorizontalBarCardTreeProps) => {
  const maxPercentage = includeChildren
    ? findMaxParentTotal(cards, cardLookupTable)
    : Math.max(...Object.values(cardLookupTable))

  const calculatePercentageScale = scalePercentageLinearly(maxPercentage)
  const calculatePercentageScaleCB = useCallback(
    (x: number) => calculatePercentageScale(x),
    [calculatePercentageScale]
  )

  return (
    <Box mt={6} mb={4}>
      <HStack mb={3}>
        <HStack>
          <SectionTitle>{leftLabel}</SectionTitle>
        </HStack>
        <Spacer />
        <HStack>
          <GammaTooltip placement="top" label={tooltipLabel}>
            <HStack>
              <SectionTitle
                borderBottom="0.125em dashed"
                borderBottomColor="gray.400"
              >
                {rightLabel}
              </SectionTitle>
            </HStack>
          </GammaTooltip>
        </HStack>
      </HStack>
      <Box width="100%">
        {Object.entries(cards).map(([card, children]) => (
          <CardRow
            key={card}
            id={card}
            docId={docId}
            childCards={children}
            percentage={cardLookupTable[card] ?? 0}
            calculatePercentageScale={calculatePercentageScaleCB}
            isColorized={isColorized}
            cardLookupTable={cardLookupTable}
            scaleToMax={scaleToMax}
            includeChildren={includeChildren}
            getScreenshotLabel={getScreenshotLabel}
            onAnalyticsPanelClose={onAnalyticsPanelClose}
          />
        ))}
      </Box>
    </Box>
  )
}

type CardRowProps = {
  id: string
  docId: Doc['id']
  cardLookupTable: CardLookupTable
  childCards: CardTree
  percentage: number
  calculatePercentageScale: (x: number) => number
  isColorized: boolean
  scaleToMax: boolean
  includeChildren: boolean
  getScreenshotLabel?: (x: number) => string
  onAnalyticsPanelClose: () => void
}

const CardRow = ({
  id,
  docId,
  cardLookupTable,
  childCards,
  percentage,
  calculatePercentageScale,
  isColorized,
  scaleToMax,
  includeChildren,
  getScreenshotLabel,
  onAnalyticsPanelClose,
}: CardRowProps) => {
  const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isHovering, setIsHovering] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const timeoutId = useRef<undefined | number>()
  const { editor } = useEditorContext()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'absolute',
  })
  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const onMouseEnter = useCallback(() => {
    timeoutId.current = window.setTimeout(() => setIsHovering(true), 200)
  }, [])

  const onMouseLeave = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    setIsHovering(false)
  }, [])

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [])

  const handleNavigateToCard = useCallback(
    (event: React.MouseEvent) => {
      if (!editor) return
      onAnalyticsPanelClose()
      navigateToCardLink(editor, event, id)
    },
    [editor, onAnalyticsPanelClose, id]
  )

  const cardData = useGetCard(id)

  const hasChildren = Object.values(childCards).length > 0
  const wasNotSeen = percentage === 0

  const totalPercentage = includeChildren
    ? percentage + sumChildren(childCards, cardLookupTable)
    : percentage

  // if expanded, the background will show the percentage of the sum of parent and children cards else show nothing
  const backgroundPercentage = isExpanded ? totalPercentage : 0
  const backgroundDisplayPercentage = scaleToMax
    ? calculatePercentageScale(totalPercentage)
    : // If not scaled, then make the sure the background and foreground percentages don't exceed 100
      Math.min(100, backgroundPercentage)

  const foregroundPercentage = isExpanded ? percentage : totalPercentage
  const foregroundDisplayPercentage = scaleToMax
    ? calculatePercentageScale(foregroundPercentage)
    : Math.min(100, foregroundPercentage)
  const colorRangeForColorizedBars = {
    // orange.300
    startColor: '#F6AD55',
    // green.300
    endColor: '#68D391',
  }
  const backgroundColor = isColorized
    ? colorForProgress({
        degree: backgroundDisplayPercentage / 100,
        ...colorRangeForColorizedBars,
      })
    : 'trueblue.50'
  const foregroundColor = isColorized
    ? colorForProgress({
        degree: foregroundDisplayPercentage / 100,
        ...colorRangeForColorizedBars,
      })
    : 'trueblue.100'

  return (
    <Stack>
      <Flex
        direction="column"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* The entire card bar */}
        <Flex
          ref={setReferenceElement}
          h={8}
          px={2}
          bg="gray.100"
          borderRadius="md"
          position="relative"
          overflow="hidden"
          alignItems="center"
          _focusWithin={{ shadow: 'outline' }}
        >
          {/* Card title and icons */}
          <HStack pos="absolute" zIndex={1}>
            {hasChildren && (
              <IconButton
                aria-label="expand-card"
                size="xs"
                variant="ghost"
                isRound
                colorScheme="blackAlpha"
                icon={<FontAwesomeIcon icon={regular('chevron-right')} />}
                transform={isExpanded ? 'rotate(90deg)' : ''}
                onClick={toggleExpanded}
                _focus={{ shadow: 'none' }}
              />
            )}
            {!hasChildren && (
              <Flex w={6} justifyContent="center" alignItems="center">
                {wasNotSeen ? (
                  <GammaTooltip label="This card has not been seen">
                    <Box color="blackAlpha.500">
                      <FontAwesomeIcon icon={solid('eye-slash')} size="sm" />
                    </Box>
                  </GammaTooltip>
                ) : (
                  <Box color="blackAlpha.300" fontSize="xx-small">
                    <FontAwesomeIcon icon={solid('circle-small')} size="sm" />
                  </Box>
                )}
              </Flex>
            )}
            <Text
              color={wasNotSeen ? 'blackAlpha.500' : undefined}
              noOfLines={1}
            >
              {cardData?.title || 'Untitled'}
            </Text>
          </HStack>
          {/* The bar background */}
          <Box
            position="absolute"
            w="100%"
            height="100%"
            top={0}
            left={0}
            pointerEvents="none"
          >
            {/* The secondary background progress */}
            <Box
              position="absolute"
              h="100%"
              bg={wasNotSeen ? 'blackAlpha.200' : backgroundColor}
              w={`${backgroundDisplayPercentage}%`}
            />
            {/* The foreground card progress */}
            <Box
              transitionProperty="all"
              transitionDuration="normal"
              position="absolute"
              h="100%"
              bg={wasNotSeen ? 'blackAlpha.300' : foregroundColor}
              w={`${foregroundDisplayPercentage}%`}
            />
          </Box>
          {isColorized && percentage === 100 && (
            <>
              <Spacer />
              <CircleCheckmark alignSelf="center" zIndex={1} />
            </>
          )}
        </Flex>
        <ScreenshotPreview
          src={
            screenshotsEnabled
              ? cardData?.previewUrl
              : placeholderBackground.src
          }
          fallbackSrc={placeholderBackground.src}
          shouldShow={isHovering}
          onClick={handleNavigateToCard}
          ref={setPopperElement}
          tooltip="Go to card (closes analytics panel)"
          label={
            getScreenshotLabel ? getScreenshotLabel(percentage) : undefined
          }
          style={styles.popper}
          {...attributes.popper}
          py={2}
        />
      </Flex>
      {/* Card's children */}
      <Box pl={8}>
        {hasChildren &&
          Object.entries(childCards).map(([card, children]) => (
            <Collapse in={isExpanded} key={card}>
              <CardRow
                id={card}
                docId={docId}
                childCards={children}
                percentage={cardLookupTable[card] ?? 0}
                cardLookupTable={cardLookupTable}
                calculatePercentageScale={calculatePercentageScale}
                scaleToMax={scaleToMax}
                isColorized={isColorized}
                getScreenshotLabel={getScreenshotLabel}
                includeChildren={includeChildren}
                onAnalyticsPanelClose={onAnalyticsPanelClose}
              />
            </Collapse>
          ))}
      </Box>
    </Stack>
  )
}
