import {
  HTMLChakraProps,
  SystemStyleObject,
  chakra,
  SystemProps,
  UseMenuItemProps,
  forwardRef,
  useMultiStyleConfig,
  PropsOf,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import React from 'react'

export interface StyledMenuItemProps extends HTMLChakraProps<'button'> {}

const StyledMenuItem = forwardRef<StyledMenuItemProps, 'button'>(
  function StyledMenuButton(props, ref) {
    const { type, ...rest } = props
    const styles = useMultiStyleConfig('Menu', rest)

    /**
     * Given another component, use its type if present
     * Else, use no type to avoid invalid html, e.g. <a type="button" />
     * Else, fall back to "button"
     */
    const btnType = rest.as ? type ?? undefined : 'button'

    const buttonStyles: SystemStyleObject = {
      textDecoration: 'none',
      color: 'inherit',
      userSelect: 'none',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'start',
      flex: '0 0 auto',
      outline: 0,
      ...styles.item,
    }

    return (
      <chakra.button
        ref={ref}
        type={btnType}
        {...rest}
        __css={buttonStyles}
        // @ts-ignore _focus is here...
        _hover={styles.item._focus}
      />
    )
  }
)

interface MenuItemOptions
  extends Pick<
    UseMenuItemProps,
    'isDisabled' | 'isFocusable' | 'closeOnSelect'
  > {
  /**
   * The icon to render before the menu item's label.
   * @type React.ReactElement
   */
  icon?: React.ReactElement
  /**
   * The spacing between the icon and menu item's label.
   * @type SystemProps["mr"]
   */
  iconSpacing?: SystemProps['mr']
  /**
   * Right-aligned label text content, useful for displaying hotkeys.
   */
  command?: string
  /**
   * The spacing between the command and menu item's label.
   * @type SystemProps["ml"]
   */
  commandSpacing?: SystemProps['ml']
}

type HTMLAttributes = React.HTMLAttributes<HTMLElement>

/**
 * Use prop `isDisabled` instead
 */
type IsDisabledProps = 'disabled' | 'aria-disabled'

export interface MenuItemProps
  extends Omit<HTMLChakraProps<'button'>, IsDisabledProps>,
    MenuItemOptions {}

export const GammaMenuItem = forwardRef<MenuItemProps, 'button'>(
  (props, ref) => {
    const {
      icon,
      iconSpacing = '0.75rem',
      command,
      commandSpacing = '0.75rem',
      children,
      ...rest
    } = props

    // const menuitemProps = useMenuItem(rest, ref) as HTMLAttributes

    const shouldWrap = icon || command

    const _children = shouldWrap ? (
      <span style={{ pointerEvents: 'none', flex: 1 }}>{children}</span>
    ) : (
      children
    )

    return (
      <StyledMenuItem {...rest} className={cx('chakra-menu__menuitem')}>
        {icon && (
          <MenuIcon fontSize="0.8em" marginEnd={iconSpacing}>
            {icon}
          </MenuIcon>
        )}
        {_children}
        {command && (
          <MenuCommand marginStart={commandSpacing}>{command}</MenuCommand>
        )}
      </StyledMenuItem>
    )
  }
)

GammaMenuItem.displayName = 'MenuItem'

const CheckIcon: React.FC<PropsOf<'svg'>> = (props) => (
  <svg viewBox="0 0 14 14" width="1em" height="1em" {...props}>
    <polygon
      fill="currentColor"
      points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"
    />
  </svg>
)

export const MenuIcon: React.FC<HTMLChakraProps<'span'>> = (props) => {
  const { className, children, ...rest } = props

  const child = React.Children.only(children)

  const clone = React.isValidElement(child)
    ? React.cloneElement(child, {
        focusable: 'false',
        'aria-hidden': true,
        className: cx('chakra-menu__icon', child.props.className),
      })
    : null

  const _className = cx('chakra-menu__icon-wrapper', className)

  return (
    <chakra.span
      className={_className}
      {...rest}
      __css={{
        flexShrink: 0,
      }}
    >
      {clone}
    </chakra.span>
  )
}

MenuIcon.displayName = 'MenuIcon'
export interface MenuCommandProps extends HTMLChakraProps<'span'> {}

export const MenuCommand = forwardRef<MenuCommandProps, 'span'>(
  (props, ref) => {
    const styles = useMultiStyleConfig('Menu', props)
    return (
      <chakra.span
        ref={ref}
        {...props}
        __css={styles.command}
        className="chakra-menu__command"
      />
    )
  }
)

MenuCommand.displayName = 'MenuCommand'

export interface MenuDividerProps extends HTMLChakraProps<'hr'> {}

export const GammaMenuDivider: React.FC<MenuDividerProps> = (props) => {
  const { className, ...rest } = props
  const styles = useMultiStyleConfig('Menu', props)
  return (
    <chakra.hr
      role="separator"
      aria-orientation="horizontal"
      className={cx('chakra-menu__divider', className)}
      {...rest}
      __css={styles.divider}
    />
  )
}

GammaMenuDivider.displayName = 'MenuDivider'
