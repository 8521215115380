import { Flex } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'

/**
 * Move the SX to a separate component that doesn't need to be recalculated
 * Previously when in Doc.tsx this caused a lot of sx prop js overhead for each render
 *
 * CardContentStyles are static and should probably just be in css
 */
export const DocEditorRootStyles: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <Flex
      alignSelf="stretch"
      position="relative"
      height="var(--100vh)"
      direction="column"
    >
      <GlobalCardStyles />
      {children}
    </Flex>
  )
}
