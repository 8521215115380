import { Box, Button, useToast } from '@chakra-ui/react'
import {
  capitalize,
  DOC_DISPLAY_NAME,
  GammaTooltip,
  LoadingToastInner,
} from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { useCallback } from 'react'

import { Doc } from 'modules/api'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'

export const ExampleTopbar = ({
  doc,
  editor,
}: {
  doc?: Doc
  editor?: Editor
}) => {
  const duplicateDoc = useDuplicateDoc()
  const toast = useToast()
  const analytics = useAnalytics()

  const onClick = useCallback(() => {
    if (!doc || !editor) return
    const duplicateToast = toast({
      id: `duplicate-toast-${doc.id}`,
      position: 'top',
      duration: null,
      render: function LoadingToast({ onClose, id }) {
        return (
          <LoadingToastInner
            title="Duplicating..."
            isClosable={false}
            onClose={onClose}
            id={id}
          />
        )
      },
    }) as string

    analytics?.track(SegmentEvents.EXAMPLE_DUPLICATED, {
      doc_id: doc.id,
      title: doc.title,
    })
    duplicateDoc(doc.id, editor.getJSON()).then((result) => {
      if (!result?.data?.duplicateDoc) {
        console.error('[ExampleTopbar] handleDuplicateDoc error')
        return
      }
      const { id } = result?.data?.duplicateDoc
      console.debug(
        `[ExampleTopbar] ${capitalize(
          DOC_DISPLAY_NAME
        )} duplicated. New doc id (${id}). Navigating to Editor`
      )
      toast.update(duplicateToast, {
        status: 'success',
        description: 'Success!',
        duration: 5000,
      })
      window.open(`/docs/${id}`, '_blank')
    })
  }, [doc, duplicateDoc, editor, toast, analytics])

  if (!doc || !editor) return null

  return (
    <Box
      bg="gradient.blue-to-orange"
      color="white"
      alignContent={'space-around'}
      textAlign={'center'}
      p={2}
      fontSize="sm"
      zIndex="banner"
    >
      Like this {DOC_DISPLAY_NAME}? Make it your own! &nbsp;
      <span role="img" aria-label="Finger pointing right">
        👉
      </span>
      <GammaTooltip
        label={`This ${DOC_DISPLAY_NAME} will be copied into your workspace`}
        aria-label={`This ${DOC_DISPLAY_NAME} will be copied into your workspace`}
      >
        <Button onClick={onClick} ml={2} size="xs">
          Clone {DOC_DISPLAY_NAME}
        </Button>
      </GammaTooltip>
    </Box>
  )
}
