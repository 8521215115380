import { FlexProps } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'

import { keyboardHandler } from 'modules/keyboard'
import { Panel } from 'modules/panels/Panel'
import { PanelComponent } from 'modules/panels/types'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { useEditorContext } from 'sections/docs/context/EditorContext'

import { CommentFeed } from './CommentFeed'

export const CommentFeedPanel: PanelComponent<FlexProps> = ({
  closePanel,
  ...rest
}) => {
  const { editor } = useEditorContext()
  useEffect(() => {
    const handleEvent = ({ open }) => {
      if (!open) return
      closePanel()
    }
    TiptapEventBus.on(EventBusEvent.POPUP_COMMENT_TOGGLED, handleEvent)
    return () => {
      TiptapEventBus.on(EventBusEvent.POPUP_COMMENT_TOGGLED, handleEvent)
    }
  }, [closePanel])

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (
        (document.activeElement as HTMLElement).closest(
          '[data-panelid="comment-feed-panel"]'
        )
      ) {
        if (e.key === 'Escape') {
          closePanel()
        }
        return true
      } else {
        return false
      }
    }
    return keyboardHandler.on('keydown', 'COMMENT_FEED_PANEL', keydownListener)
  }, [closePanel])

  return (
    <Panel {...rest} bgColor="white" data-panelid="comment-feed-panel">
      <CommentFeed onClose={closePanel} editor={editor} />
    </Panel>
  )
}

CommentFeedPanel.panelPosition = 'right'
