import { Editor } from '@tiptap/core'
import { useCallback, useEffect } from 'react'

import { keyboardHandler } from 'modules/keyboard'
import { selectIsAnyModalOpen } from 'modules/modal_state/reducer'
import { startSlideToSlide } from 'modules/performance/slideToSlidePerf'
import { getStore, useAppDispatch, useAppSelector } from 'modules/redux'
import { getScrollManager } from 'modules/scroll'
import {
  PresentNavigateDirections,
  PresentNavigateMethods,
  SegmentEvents,
  useAnalytics,
} from 'modules/segment'
import { isCardNode } from 'modules/tiptap_editor/extensions/Card'
import {
  isExpandableToggleNode,
  toggleExpandableOpen,
} from 'modules/tiptap_editor/extensions/Expandable/utils'
import { isMediaNode } from 'modules/tiptap_editor/extensions/media'
import { useSpotlightingByBlock } from 'modules/tiptap_editor/extensions/spotlight/hooks'
import {
  setFollowingAttached,
  setMediaNodeExpanded,
} from 'modules/tiptap_editor/reducer'
import { useEditorContext } from 'sections/docs'

export const NAV_KEY_SET = new Set([
  'h',
  'j',
  'k',
  'l',
  's',
  'PageUp',
  'PageDown',
  'ArrowRight',
  'ArrowLeft',
  'ArrowDown',
  'ArrowUp',
  'Enter',
  'Escape',
])

const NUDGE_PERCENTAGE = 0.5
const NUDGE_CARD_EDGE_THRESHOLD = 150 // Jump to end if you're this close to it in pixels
const JUMP_CARD_EDGE_THRESHOLD = 90

const nudgeUpDown = (editor: Editor, direction: 'up' | 'down') => {
  const scrollManager = getScrollManager('editor')
  const scroller = scrollManager.scroller
  if (!scroller) return

  // Since were performning a scroll action on behalf of the user, turn
  // off spotlight pos so that followers can follow our scroll.
  editor.commands.turnOffSpotlight()

  const bottom = scroller.scrollHeight - scroller.offsetHeight

  if (direction === 'down') {
    const isAtBottom = scrollManager.isAtBottom(JUMP_CARD_EDGE_THRESHOLD)
    if (isAtBottom) {
      editor.commands.spotlightNextCard(false, 'top')
    } else {
      // Nudge down
      let destination =
        scroller.scrollTop + scroller.offsetHeight * NUDGE_PERCENTAGE
      if (destination > bottom - NUDGE_CARD_EDGE_THRESHOLD) {
        destination = bottom
      }
      scrollManager.scrollTo({
        top: destination,
      })
    }
  } else {
    const isAtTop = scrollManager.isAtTop(JUMP_CARD_EDGE_THRESHOLD)
    if (isAtTop) {
      editor.commands.spotlightNextCard(true, 'bottom')
    } else {
      // Nudge up
      let destination =
        scroller.scrollTop - scroller.offsetHeight * NUDGE_PERCENTAGE
      if (destination < NUDGE_CARD_EDGE_THRESHOLD) {
        destination = 0
      }
      scrollManager.scrollTo({
        top: destination,
      })
    }
  }
}

export const useNavigationControls = ({ enabled }: { enabled: boolean }) => {
  const analytics = useAnalytics()
  const dispatch = useAppDispatch()
  const { editor } = useEditorContext()
  const isAnyModalOpen = useAppSelector(selectIsAnyModalOpen)

  const { enabled: spotlightingByBlock, pos: spotlightPos } =
    useSpotlightingByBlock(editor)

  const toggleSpotlight = useCallback(() => {
    if (!spotlightingByBlock) {
      editor?.commands.spotlightNextBlock()
    } else {
      editor?.commands.turnOffSpotlight()
    }
  }, [editor, spotlightingByBlock])

  // Handle Arrow or HJKL Keypresses
  useEffect(
    function handleNavKeyEvents() {
      if (!enabled) return

      const handleKeydown = (event: KeyboardEvent) => {
        const store = getStore()
        const target = event.target as HTMLElement
        if (
          !editor ||
          target?.closest('[data-gamma-child-tiptap-editor]') ||
          target?.closest('input') ||
          event.metaKey ||
          isAnyModalOpen
        ) {
          return false
        }

        if (NAV_KEY_SET.has(event.key)) {
          event.preventDefault()
        }

        switch (event.key) {
          case 's':
            toggleSpotlight()
            return true
          case 'l':
          case 'ArrowRight':
            startSlideToSlide()
            store.dispatch(setFollowingAttached({ attached: false }))
            analytics?.trackDocEvent(SegmentEvents.PRESENT_MODE_NAVIGATED, {
              direction: PresentNavigateDirections.CARD_NEXT,
              method: PresentNavigateMethods.KEYBOARD,
              key: event.key,
            })
            return editor.commands.spotlightNextCard()
          case 'h':
          case 'ArrowLeft':
            startSlideToSlide()
            store.dispatch(setFollowingAttached({ attached: false }))
            analytics?.trackDocEvent(SegmentEvents.PRESENT_MODE_NAVIGATED, {
              direction: PresentNavigateDirections.CARD_PREV,
              method: PresentNavigateMethods.KEYBOARD,
              key: event.key,
            })
            return editor.commands.spotlightNextCard(true)
          case 'j':
          case 'ArrowDown':
          case 'PageDown':
            startSlideToSlide()
            store.dispatch(setFollowingAttached({ attached: false }))
            if (!spotlightingByBlock) {
              nudgeUpDown(editor, 'down')
              return true
            }
            analytics?.trackDocEvent(SegmentEvents.PRESENT_MODE_NAVIGATED, {
              direction: PresentNavigateDirections.TELEPROMPTER_DOWN,
              method: PresentNavigateMethods.KEYBOARD,
              key: event.key,
            })
            return editor.commands.spotlightNextBlock()
          case 'k':
          case 'ArrowUp':
          case 'PageUp':
            startSlideToSlide()
            store.dispatch(setFollowingAttached({ attached: false }))
            if (!spotlightingByBlock) {
              nudgeUpDown(editor, 'up')
              return true
            }
            analytics?.trackDocEvent(SegmentEvents.PRESENT_MODE_NAVIGATED, {
              direction: PresentNavigateDirections.TELEPROMPTER_UP,
              method: PresentNavigateMethods.KEYBOARD,
              key: event.key,
            })
            return editor.commands.spotlightNextBlock(true)
          case 'Escape':
            if (spotlightingByBlock) {
              editor.commands.turnOffSpotlight()
              return true
            }
            startSlideToSlide()
            store.dispatch(setFollowingAttached({ attached: false }))
            analytics?.track(SegmentEvents.CARD_COLLAPSED, {
              is_present_mode: true,
              method: 'escape_key',
            })
            return editor.commands.ascendUpToParentCard(false)
          case 'Enter': {
            if (!spotlightingByBlock || !spotlightPos) return false
            const node = editor.state.doc.nodeAt(spotlightPos)
            if (!node) return false

            const $pos = editor.state.doc.resolve(spotlightPos)
            const parent = $pos.parent

            if (isCardNode(node)) {
              startSlideToSlide()
              store.dispatch(setFollowingAttached({ attached: false }))
              analytics?.track(SegmentEvents.CARD_EXPANDED, {
                is_present_mode: true,
                method: 'enter_key',
              })
              return editor.commands.descendIntoCurrentCard()
            } else if (isExpandableToggleNode(node)) {
              toggleExpandableOpen(node.attrs.id)
              editor.commands.spotlightNextBlock()
              return true
            } else if (isMediaNode(node)) {
              dispatch(setMediaNodeExpanded({ nodeId: node.attrs.id }))
              return true
            } else if (parent && isExpandableToggleNode(parent)) {
              // If we're inside a toggle and press enter, close it. This is most useful on the summary node
              toggleExpandableOpen(parent.attrs.id)
              editor.commands.spotlightNextBlock(true)
              return true
            }
            return false
          }
          default:
            return false
        }
      }

      // TODO: Figure out how to scope this listener in a way that it
      // doesnt respond if either of the Panels is open
      return keyboardHandler.on('keydown', 'NAVIGATION_CONTROLS', handleKeydown)
    },
    [
      editor,
      dispatch,
      analytics,
      enabled,
      toggleSpotlight,
      spotlightingByBlock,
      spotlightPos,
      isAnyModalOpen,
    ]
  )
}
