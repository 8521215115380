import { Editor } from '@tiptap/core'
import React, { useEffect } from 'react'

import { SegmentEvents, useAnalytics } from 'modules/segment'

import { cardViewedEmitter } from './CardViewedEmitter'
import { CARD_VIEWED_EVENT_FLUSH_INTERVAL } from './constants'
import { useEmitViewedCard } from './hooks'

export type TimeOnCardProps = {
  editor: Editor
}

export const TimeOnCard: React.FC<TimeOnCardProps> = ({ editor }) => {
  const analytics = useAnalytics()

  useEmitViewedCard(editor, cardViewedEmitter)

  useEffect(() => {
    if (!analytics) return

    cardViewedEmitter.initialize()

    cardViewedEmitter.on('flush', ({ viewed }) => {
      for (const [cardId, duration] of Object.entries(viewed)) {
        analytics.trackDocEvent(SegmentEvents.CARD_VIEWED, {
          card_id: cardId,
          duration,
        })
      }
    })

    const id = setInterval(() => {
      cardViewedEmitter.flush()
    }, CARD_VIEWED_EVENT_FLUSH_INTERVAL)

    return () => {
      clearInterval(id)
      // destroy will flush and unbind all listeners
      cardViewedEmitter.destroy()
    }
  }, [analytics])

  return null
}
