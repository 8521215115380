import { useFeatureFlag } from 'modules/featureFlags'
import { useBaseHoneycombDocData } from 'modules/performance/baseHoneycombDocData'
import { useMeasureKeydownPerformance } from 'modules/performance/hooks'
import { SegmentEvents, useAnalyticsEffect } from 'modules/segment'

import { DocEditorType, DocEditorTypeType } from '../constants'
import { useEditorContext } from '../context/EditorContext'

export const useDocTracking = (
  docId: string,
  docEditorType: DocEditorTypeType
) => {
  const { isUnauthorized } = useEditorContext()
  useAnalyticsEffect(
    (analytics) => {
      if (isUnauthorized === true || isUnauthorized === undefined) {
        // true - the user and doc have been loaded and the user does not have permission
        // undefined - the user or doc have not yet been loaded yet
        return
      }
      analytics.track(SegmentEvents.DOC_VIEWED, {
        doc_id: docId,
      })

      if (docEditorType === DocEditorType.EXAMPLE_STATIC) {
        analytics.track(SegmentEvents.EXAMPLE_VIEWED, {
          doc_id: docId,
        })
      }
    },
    [docId, docEditorType, isUnauthorized]
  )

  // Setup real user metrics (RUM)
  useBaseHoneycombDocData()
  const rumEnabled = useFeatureFlag('realUserMetrics')
  const metricsEnabled =
    docEditorType === DocEditorType.COLLABORATIVE && rumEnabled
  const rumKeystrokeSampleRate = useFeatureFlag('rumKeystrokeSampleRate')
  useMeasureKeydownPerformance(metricsEnabled, rumKeystrokeSampleRate)
}
