import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { URLChangedPayload, URLEventEmitter } from 'modules/history'
import { useAppStore } from 'modules/redux'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { goToCard } from 'modules/tiptap_editor/extensions/Card'
import { setTextSelection } from 'modules/tiptap_editor/prosemirror-utils'
import { selectCardIds, selectMode } from 'modules/tiptap_editor/reducer'
import { updateCardHash } from 'modules/tiptap_editor/utils/url'
import { useEditorContext } from 'sections/docs/context'
import { parseUrlHash } from 'utils/url'

export const useHandleCardHash = () => {
  const { editor } = useEditorContext()
  const router = useRouter()
  const store = useAppStore()

  useEffect(
    function observeHashChanges() {
      if (!editor) return
      const handleURLChange = (payload: URLChangedPayload) => {
        const { cardId } = parseUrlHash(payload.url)
        const state = store.getState()
        const mode = selectMode(state)
        console.debug('[useHandleCardHash][handleURLChange]', {
          payload,
          cardId,
        })
        // If a popstate event has a fromPos defined, scroll to that position
        // instead of using goToCard when in DOC_VIEW
        // This is in an effort to mimic the native <a> element spec for history state scroll-position
        // See https://html.spec.whatwg.org/multipage/browsing-the-web.html#persisted-user-state-restoration:she-scroll-position
        if (
          mode === EditorModeEnum.DOC_VIEW &&
          payload.state.fromPos &&
          payload.method === 'pop'
        ) {
          const { fromPos, fromPct } = payload.state
          editor
            .chain()
            .scrollToPositionInCard(fromPos, fromPct || undefined)
            .command(({ tr }) => {
              setTextSelection(fromPos)(tr)
              return true
            })
            .run()
        } else {
          const firstCardId = selectCardIds(state)[0]
          goToCard({
            cardId: cardId || firstCardId,
            editor,
          })
        }
      }

      // IMPORTANT: Return false here to stop the Next router from handling this, otherwise
      // the entire DocEditor Next page re-renders
      // https://nextjs.org/docs/api-reference/next/router#routerbeforepopstate
      router.beforePopState(({ as }) => {
        if (as.startsWith('/docs/')) {
          return false
        }
        return true
      })

      const unsub = URLEventEmitter.on('changed', handleURLChange)
      return () => {
        // This is the only way to "unsubscribe" the NextRouter beforePopState handler
        router.beforePopState(() => true)
        unsub()
      }
    },
    [editor, router, store]
  )

  useEffect(
    function init() {
      if (!editor) return
      // We need the editor to be initialized before we try to jump to any cards
      const { cardId } = parseUrlHash(window.location.href)
      if (!cardId) {
        return
      }
      updateCardHash({
        cardId,
        method: 'replace',
      })
    },
    [editor]
  )
}
