import { Box, Flex } from '@chakra-ui/react'
import throttle from 'lodash/throttle'
import { RefObject, useEffect, useMemo, useState } from 'react'

import {
  PANEL_MAX_WIDTH,
  PANEL_MIN_WIDTH,
  PANEL_SNAP_CLOSED_WIDTH,
} from './constants'
import { PanelPosition } from './types'

/**
 * Helper util for figuring out the scrollbar width (transparent vs always showing)
 */
const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  // @ts-ignore
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  // @ts-ignore
  outer.parentNode.removeChild(outer)

  return scrollbarWidth
}
const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

type PanelResizerProps = {
  width: number
  setWidth: (width: number) => void
  position: PanelPosition
  onClose: () => void
  panelRef: RefObject<HTMLElement>
  initialWidth: number
  isDragging: boolean
  setIsDragging: (val: boolean) => void
}
export const PanelResizer = ({
  setWidth,
  width,
  position,
  onClose,
  panelRef,
  initialWidth,
  isDragging,
  setIsDragging,
}: PanelResizerProps) => {
  const scrollbarWidth = useMemo(() => getScrollbarWidth(), [])
  // scrollbars only matter on the right
  const systemHasVisibleScrollbars = scrollbarWidth > 0 && position === 'right'

  const [startWidth, setStartWidth] = useState(width)
  const [startPos, setStartPos] = useState<number | null>(null)

  // throttle per 30ms -> 30fps?
  const handleDrag = throttle((event) => {
    let draggingWidth
    if (position === 'left') {
      draggingWidth = event.clientX
    } else {
      draggingWidth = (event.clientX - startPos!) * -1 + startWidth
    }
    let newWidth = clamp(draggingWidth, PANEL_MIN_WIDTH, PANEL_MAX_WIDTH)
    if (draggingWidth <= PANEL_SNAP_CLOSED_WIDTH) {
      panelRef.current!.style.transitionProperty = 'width'
      panelRef.current!.style.transitionDuration =
        'var(--chakra-transition-duration-normal)'
      newWidth = 0
    } else if (newWidth > PANEL_MIN_WIDTH) {
      panelRef.current!.style.transitionProperty = ''
      panelRef.current!.style.transitionDuration = ''
    }

    panelRef.current!.style.width = `${newWidth}px`
  }, 30)

  const handleDragEnd = () => {
    setIsDragging(false)
    setStartPos(null)
    const endWidth = panelRef.current!.style.width

    if (endWidth === '0px') {
      // snap close
      onClose()
      // reset width to initial width for re-opening
      setWidth(initialWidth)
    } else {
      setWidth(parseInt(endWidth.replace('px', ''), 10))
    }
    panelRef.current!.style.transitionProperty = ''
    panelRef.current!.style.transitionDuration = ''

    window.removeEventListener('mousemove', handleDrag)
    window.removeEventListener('mouseup', handleDragEnd)
  }

  const handleDragStart = (event) => {
    event.preventDefault()
    setIsDragging(true)
    setStartPos(event.clientX)
    setStartWidth(width)
  }

  useEffect(() => {
    if (startPos) {
      window.addEventListener('mousemove', handleDrag)
      window.addEventListener('mouseup', handleDragEnd)
    }
    return () => {
      window.removeEventListener('mousemove', handleDrag)
      window.removeEventListener('mouseup', handleDragEnd)
    }
  }, [startPos])

  let placementStyles = {}
  if (position === 'left') {
    placementStyles = {
      right: -4,
      position: 'absolute',
    }
  } else {
    placementStyles = systemHasVisibleScrollbars
      ? { position: 'relative' }
      : {
          left: -4,
          position: 'absolute',
        }
  }

  return (
    <Flex
      h="100%"
      bg={
        systemHasVisibleScrollbars
          ? 'gray.200'
          : isDragging
          ? 'blackAlpha.200'
          : 'rgba(0,0,0,0)'
      }
      w={systemHasVisibleScrollbars ? 2 : 4}
      justifyContent="center"
      alignItems="center"
      cursor="col-resize"
      _hover={{
        bg: systemHasVisibleScrollbars ? 'gray.400' : 'blackAlpha.200',
      }}
      transitionProperty="common"
      transitionDuration="normal"
      role="group"
      onMouseDown={handleDragStart}
      position="absolute"
      display={['none', 'none', 'none', 'flex']}
      {...placementStyles}
    >
      {!systemHasVisibleScrollbars && (
        <Box
          bg="white"
          borderRadius="full"
          w={1}
          h={10}
          opacity={isDragging ? 1 : '.5'}
          transitionProperty="common"
          transitionDuration="normal"
          _groupHover={{ opacity: 1 }}
        />
      )}
    </Flex>
  )
}
