import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, GammaTooltip } from '@gamma-app/ui'
import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { Editor } from '@tiptap/core'
import BezierEasing from 'bezier-easing'
import { Lethargy } from 'lethargy'
import clamp from 'lodash/clamp'
import isEqual from 'lodash/isEqual'
import { Node as ProseMirrorNode } from 'prosemirror-model'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { useScrollManager } from 'modules/scroll'
import {
  getCardSelector,
  isCardNode,
} from 'modules/tiptap_editor/extensions/Card'
import {
  selectCardIdMap,
  selectPresentingCardId,
} from 'modules/tiptap_editor/reducer'
import {
  lerp,
  easeOutLinear,
  easeOutSine,
  easeOutCubic,
  easeOutCirc,
  easeOutQuad,
  easeOutQuart,
  easeOutExpo,
} from 'utils/easings'

const EasingMap = {
  easeOutLinear,
  easeOutSine,
  easeOutCubic,
  easeOutCirc,
  easeOutQuad,
  easeOutQuart,
  easeOutExpo,
  // Create these here: https://matthewlein.com/tools/ceaser
  bezierCustom1: BezierEasing(0.13, 0.97, 0.035, 0.73),
  bezierCustom2: BezierEasing(0.105, 1.15, 0.15, 0.355),
} as const

type EasingMapType = keyof typeof EasingMap

// Debugger configurable defaults
const TOUCHPAD_ADJUSTMENT_FACTOR = 1.5
const WHEEL_EVENT_TRIGGER_THRESHOLD = 1200

const PROGRESS_BAR_HEIGHT_OFFSET = 8
const PEEK_ELEMENT_HEIGHT = 40
const PEEK_ELEMENT_OFFSET = 16
const PEEK_ELEMENT_TOTAL = PEEK_ELEMENT_HEIGHT + PEEK_ELEMENT_OFFSET

const OPACITY_TRANSITION_TIME = 300 // How long does the peek tab take to fade to 0
const TRIGGER_ACTION_DELAY = 250 // How long do we leave the UI around after triggering
const WHEEL_IGNORE_MAX_TIME = 1000
const WHEEL_INACTIVITY_DELAY = 500 // How long we wait for the next wheel event before reset

const Direction = {
  UP: 'up',
  DOWN: 'down',
} as const

type DirectionType = typeof Direction[keyof typeof Direction]

type DebugOptions = {
  easing?: EasingMapType
  touchpadFactor?: number
  triggerThreshold?: number
}

const timeSince = (val: number | null) =>
  val ? +new Date() - +new Date(val) : Infinity

export const usePresentScroll = (
  editor?: Editor,
  debugOptions?: DebugOptions
) => {
  /**
   * Lethargy is a library that attempts to detect if scroll/wheel events
   * are inertial (simulated inertia events by an input device to make it
   * feel more "real", like youre spinning an actual wheel).
   *
   * https://github.com/d4nyll/lethargy#options
   */
  const lethargy = useMemo(() => new Lethargy(6, 120, 0.3, 150), [])

  const {
    easing = 'easeOutCubic',
    touchpadFactor = TOUCHPAD_ADJUSTMENT_FACTOR,
    triggerThreshold = WHEEL_EVENT_TRIGGER_THRESHOLD,
  } = debugOptions || {}

  const scrollManger = useScrollManager('editor')
  const presentingCardId = useAppSelector(selectPresentingCardId)
  const debugRef = useRef<HTMLDivElement>(null)
  const prevCardRef = useRef<HTMLDivElement>(null)
  const nextCardRef = useRef<HTMLDivElement>(null)
  const animateInProgress = useRef(false)
  const scrollInProgress = useRef<number | null>(null)
  const triggerInProgress = useRef<number | null>(null)
  const [showPrevCard, setShowPrevCard] = useState(false)
  const [showNextCard, setShowNextCard] = useState(false)
  const state = useRef<{
    counterCurrent: number
    counterTarget: number
    direction: DirectionType
  }>({
    counterTarget: 0,
    counterCurrent: 0,
    direction: Direction.DOWN,
  })

  const updateDebugger = useCallback(() => {
    if (!debugRef.current) return

    const { counterTarget } = state.current

    const percent = (counterTarget / triggerThreshold) * 100
    const determinant = (percent ?? 0) * 2.64
    const strokeDasharray = `${determinant} ${264 - determinant}`

    const percentLabelEl =
      debugRef.current.querySelector('.chakra-progress')?.lastElementChild
    const dashArrayEl = debugRef.current.querySelector(
      '.chakra-progress__indicator'
    )
    if (dashArrayEl) {
      dashArrayEl.setAttribute('stroke-dasharray', strokeDasharray)
    }
    if (percentLabelEl) {
      percentLabelEl.innerHTML = `${percent.toFixed(0)}%`
    }
  }, [triggerThreshold])

  const updatePeekElement = useCallback(() => {
    const nextEl = nextCardRef.current
    const prevEl = prevCardRef.current
    if (!prevEl || !nextEl) {
      return
    }
    const nextPctEl = nextEl.querySelector('.chakra-progress__indicator')
    const prevPctEl = prevEl.querySelector('.chakra-progress__indicator')
    if (!nextPctEl || !prevPctEl) {
      return
    }

    const currentPct = state.current.counterCurrent / triggerThreshold

    const force100Percent =
      timeSince(triggerInProgress.current) <
      TRIGGER_ACTION_DELAY + OPACITY_TRANSITION_TIME

    // https://github.com/chakra-ui/chakra-ui/blob/c11743b47f38f8f38a21b120add3a9cf765b81ee/packages/progress/src/circular-progress.tsx#L133-L139
    const determinant = (force100Percent ? 1 : currentPct ?? 0) * 264
    const strokeDasharray = `${determinant} ${264 - determinant}`
    const currentDistance =
      PEEK_ELEMENT_TOTAL * EasingMap[easing](Math.min(currentPct, 1))
    const offsetPx = Math.max(PEEK_ELEMENT_TOTAL - currentDistance, 0)

    nextEl.style.transform = `translateY(${offsetPx}px)`
    prevEl.style.transform = `translateY(-${offsetPx}px)`
    prevPctEl.setAttribute('stroke-dasharray', strokeDasharray)
    nextPctEl.setAttribute('stroke-dasharray', strokeDasharray)
  }, [easing, triggerThreshold])

  /**
   * Function to manually animate the peek and doc elements Y position.
   * Necessary because we need a fluid constantly changing animation
   * target based on the wheel input events.
   * This function is idempotent and calls itself recursively as long as
   * there is animation "work" to do (target is not equal to current)
   */
  const animate = useCallback(() => {
    if (animateInProgress.current) return

    const LERP_ALPHA = 0.3
    const LERP_TRIGGER_THRESHOLD = 0.1
    let previousTimeStamp: DOMHighResTimeStamp

    const go = (timestamp: DOMHighResTimeStamp) => {
      const elapsed = previousTimeStamp ? timestamp - previousTimeStamp : 0
      previousTimeStamp = timestamp

      // Handle frame rate dropping
      const lastKnownFPS = elapsed ? 1000 / elapsed : 60
      const normalizationFactor = 60 / lastKnownFPS

      const distance =
        state.current.counterTarget - state.current.counterCurrent
      if (Math.abs(distance) < LERP_TRIGGER_THRESHOLD) {
        state.current.counterCurrent = state.current.counterTarget
        updatePeekElement()
        animateInProgress.current = false
        return
      }

      state.current.counterCurrent = lerp(
        state.current.counterCurrent,
        state.current.counterTarget,
        clamp(LERP_ALPHA * normalizationFactor, LERP_ALPHA, 1)
      )
      updatePeekElement()
      requestAnimationFrame(go)
    }

    requestAnimationFrame(go)
  }, [updatePeekElement])

  const resetCounter = useCallback(() => {
    state.current.counterTarget = 0
    setShowPrevCard(false)
    setShowNextCard(false)
    updateDebugger()
    animate()
  }, [animate, updateDebugger])

  useEffect(() => {
    if (!presentingCardId || !editor) {
      return resetCounter()
    }

    const el = document.querySelector(getCardSelector(presentingCardId))
    if (!el) {
      return resetCounter()
    }

    let scrollTimeout: NodeJS.Timeout
    let wheelTimeout: NodeJS.Timeout

    const cbWheel = (e: WheelEvent) => {
      if ((e.target as HTMLElement).closest('[data-comments-wrapper]')) {
        return
      }
      const lethargyCheck = lethargy.check(e) // Always notify lethargy of this event

      if (nextCardRef.current === null || prevCardRef.current === null) {
        return
      }

      // If we have recently scrolled, we may ignore this event
      const timeSinceScroll = timeSince(scrollInProgress.current)
      const scrollIgnore =
        timeSinceScroll < 500
          ? true
          : timeSinceScroll < WHEEL_IGNORE_MAX_TIME
          ? lethargyCheck === false
          : false

      if (scrollIgnore) {
        return
      }

      // If we have recently triggered next/prev card, we may ignore this event
      const timeSinceTrigger = timeSince(triggerInProgress.current)
      const triggerIgnore =
        timeSinceTrigger < TRIGGER_ACTION_DELAY * 2
          ? true
          : timeSinceTrigger < WHEEL_IGNORE_MAX_TIME
          ? lethargyCheck === false
          : false

      if (triggerIgnore) {
        return
      }

      const direction =
        Math.abs(e.deltaY) === 0 // NB: e.deltaY can be 0 or -0!
          ? state.current.direction
          : e.deltaY > 0
          ? Direction.DOWN
          : Direction.UP

      const isAtEdge =
        direction === 'down'
          ? scrollManger.isAtBottom()
          : scrollManger.isAtTop()

      // Only handle wheel events at the ends of the scroll container
      if (!isAtEdge) {
        return
      }

      // Now that we know were handling this event, clear the existing wheel timeout
      clearTimeout(wheelTimeout)

      // https://stackoverflow.com/questions/10744645/detect-touchpad-vs-mouse-in-javascript
      const isTouchPad = e['wheelDeltaY']
        ? e['wheelDeltaY'] === -3 * e.deltaY
        : e.deltaMode === 0

      const incrementAmountRaw = Math.abs(
        // NB: e.deltaY can be 0!
        typeof e.deltaY !== 'undefined' ? e.deltaY : 40
      )
      // Add power to touchpad events based on touchpadFactor
      const incrementAmount =
        incrementAmountRaw * (isTouchPad ? touchpadFactor : 1)

      if (direction !== state.current.direction) {
        state.current.direction = direction
        resetCounter()
        return
      }

      setShowNextCard(direction === Direction.DOWN)
      setShowPrevCard(direction === Direction.UP)
      state.current.counterTarget += incrementAmount
      state.current.direction = direction

      const pct = state.current.counterTarget / triggerThreshold
      if (pct >= 1) {
        editor.commands.spotlightNextCard(
          direction === Direction.UP,
          direction === Direction.UP ? 'bottom' : 'top'
        )
        triggerInProgress.current = +new Date()

        setTimeout(() => {
          resetCounter()
          updateDebugger()
        }, TRIGGER_ACTION_DELAY)

        setTimeout(() => {
          triggerInProgress.current = null
        }, WHEEL_IGNORE_MAX_TIME)
      } else {
        // If we dont get another wheel event for a bit, reset!
        // Note this timeout is cleared above on each subsequent wheel event
        wheelTimeout = setTimeout(resetCounter, WHEEL_INACTIVITY_DELAY)
      }
      animate()
      updateDebugger()
    }

    const cbScroll = () => {
      scrollInProgress.current = +new Date()
      clearTimeout(scrollTimeout)
      resetCounter()

      scrollTimeout = setTimeout(() => {
        scrollInProgress.current = null
      }, WHEEL_IGNORE_MAX_TIME)
    }

    el.addEventListener('scroll', cbScroll)
    el.addEventListener('wheel', cbWheel, { passive: true })

    updateDebugger()

    return () => {
      el.removeEventListener('scroll', cbScroll)
      el.removeEventListener('wheel', cbWheel)
    }
  }, [
    editor,
    lethargy,
    animate,
    easing,
    touchpadFactor,
    triggerThreshold,
    scrollManger,
    updateDebugger,
    updatePeekElement,
    resetCounter,
    presentingCardId,
  ])

  return { debugRef, nextCardRef, prevCardRef, showNextCard, showPrevCard }
}

type PeekCardData = {
  cardId: string
  isSibling: boolean
}

type PeekCards = {
  prevCard: PeekCardData | null
  nextCard: PeekCardData | null
}

export const PresentScroll = ({ editor }: { editor?: Editor }) => {
  // Settings for debugger configuration
  const debuggerEnabled = useFeatureFlag('debugLogging')
  const [easing, setEasing] = useState<EasingMapType>('easeOutExpo')
  const [touchpadFactor, setTouchpadFactor] = useState(
    TOUCHPAD_ADJUSTMENT_FACTOR
  )
  const [triggerThreshold, setTriggerThreshold] = useState(
    WHEEL_EVENT_TRIGGER_THRESHOLD
  )

  const { debugRef, nextCardRef, prevCardRef, showNextCard, showPrevCard } =
    usePresentScroll(editor, {
      easing,
      touchpadFactor,
      triggerThreshold,
    })

  const { prevCard, nextCard } = useAppSelector<PeekCards>((state) => {
    const presentingCardId = selectPresentingCardId(state) || '' // Currently presenting card ID
    const cardIdMap = selectCardIdMap(state) // The tree of card IDs with children
    const presentingCardsParents = cardIdMap.parents[presentingCardId] || []
    // The portion of the card tree anchored at the parent of the presenting card (it + its siblings)
    const presentingCardSiblingTree = presentingCardsParents.reduce(
      (acc, curr) => acc[curr],
      cardIdMap.tree
    )
    const siblingCardIds = Object.keys(presentingCardSiblingTree)
    let prev: PeekCardData | null = null
    let next: PeekCardData | null = null

    // Do all this to calculate previous and next cards (if any),
    // along with whether or not they are siblings or parents
    siblingCardIds.forEach((cardId, idx) => {
      if (cardId !== presentingCardId) return

      const parentCardIdList = cardIdMap.parents[presentingCardId]

      // prevCard
      if (idx === 0) {
        if (parentCardIdList.length) {
          prev = {
            cardId: parentCardIdList[parentCardIdList.length - 1],
            isSibling: false,
          }
        }
      } else {
        prev = {
          cardId: siblingCardIds[idx - 1],
          isSibling: true,
        }
      }

      // nextCard
      if (idx === siblingCardIds.length - 1) {
        if (parentCardIdList.length) {
          next = {
            cardId: parentCardIdList[parentCardIdList.length - 1],
            isSibling: false,
          }
        }
      } else {
        next = {
          cardId: siblingCardIds[idx + 1],
          isSibling: true,
        }
      }
    })
    return { prevCard: prev, nextCard: next }
  }, isEqual)

  const nextCardId = nextCard?.cardId
  const prevCardId = prevCard?.cardId

  const nodes = useMemo<{
    prev: ProseMirrorNode | null
    next: ProseMirrorNode | null
  }>(() => {
    let prev: ProseMirrorNode | null = null
    let next: ProseMirrorNode | null = null
    if (!editor) return { prev, next }

    editor.state.doc.descendants((n) => {
      if (!isCardNode(n)) return true

      if (n.attrs.id === prevCardId) {
        prev = n
      } else if (n.attrs.id === nextCardId) {
        next = n
      }
      return Boolean(!prev && prevCardId) || Boolean(!next && nextCardId)
    })

    return { prev, next }
  }, [editor, nextCardId, prevCardId])

  return (
    <>
      {debuggerEnabled && (
        <PeekDebugger
          ref={debugRef}
          setEasing={setEasing}
          setTouchpadFactor={setTouchpadFactor}
          setTriggerThreshold={setTriggerThreshold}
        />
      )}
      <PeekCardTab
        ref={prevCardRef}
        direction="up"
        node={nodes.prev}
        enabled={showPrevCard}
        offset={PEEK_ELEMENT_OFFSET}
      />
      <PeekCardTab
        ref={nextCardRef}
        direction="down"
        node={nodes.next}
        enabled={showNextCard}
        offset={PROGRESS_BAR_HEIGHT_OFFSET + PEEK_ELEMENT_OFFSET}
      />
    </>
  )
}

const PeekDebugger = React.forwardRef(
  (
    {
      setEasing,
      setTouchpadFactor,
      setTriggerThreshold,
    }: {
      setEasing: React.Dispatch<React.SetStateAction<DebugOptions['easing']>>
      setTouchpadFactor: React.Dispatch<
        React.SetStateAction<DebugOptions['touchpadFactor']>
      >
      setTriggerThreshold: React.Dispatch<
        React.SetStateAction<DebugOptions['triggerThreshold']>
      >
    },
    ref: MutableRefObject<HTMLDivElement | null>
  ) => {
    return (
      <Flex
        position="absolute"
        alignItems="center"
        bg="gray.100"
        left={2}
        bottom={16}
        zIndex="overlay"
        borderRadius="md"
        border="solid 2px var(--chakra-colors-trueblue-300)"
        p={1}
      >
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} colorScheme="trueblue">
            Options
          </MenuButton>
          <MenuList minWidth="240px">
            <GammaTooltip
              label="How much total scrolling work do we need to do to trigger next/prev card?"
              placement="right"
            >
              <Text color="gray" fontSize="sm" fontWeight="600" ml={2} pb={2}>
                Scroll Threshold{' '}
                <i>(default={WHEEL_EVENT_TRIGGER_THRESHOLD})</i>
              </Text>
            </GammaTooltip>
            <NumberInput
              defaultValue={WHEEL_EVENT_TRIGGER_THRESHOLD}
              min={100}
              max={10000}
              precision={0}
              step={100}
              onChange={(_, num) => setTriggerThreshold(num)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <MenuDivider />
            <GammaTooltip
              label="How much extra weight do we give to touchpad scroll events?"
              placement="right"
            >
              <Text color="gray" fontSize="sm" fontWeight="600" ml={2} pb={2}>
                Touchpad Multiplier{' '}
                <i>(default={TOUCHPAD_ADJUSTMENT_FACTOR})</i>
              </Text>
            </GammaTooltip>
            <NumberInput
              defaultValue={TOUCHPAD_ADJUSTMENT_FACTOR}
              min={1}
              max={10}
              precision={1}
              step={0.1}
              onChange={(_, num) => setTouchpadFactor(num)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <MenuDivider />
            <MenuOptionGroup
              defaultValue="easeOutExpo"
              title="Easing (of peek element)"
              type="radio"
              onChange={(v: EasingMapType) => setEasing(v)}
            >
              <MenuItemOption value="easeOutLinear">
                easeOutLinear
              </MenuItemOption>
              <MenuItemOption value="easeOutSine">easeOutSine</MenuItemOption>
              <MenuItemOption value="easeOutCubic">easeOutCubic</MenuItemOption>
              <MenuItemOption value="easeOutCirc">easeOutCirc</MenuItemOption>
              <MenuItemOption value="easeOutQuad">easeOutQuad</MenuItemOption>
              <MenuItemOption value="easeOutQuart">easeOutQuart</MenuItemOption>
              <MenuItemOption value="easeOutExpo">easeOutExpo</MenuItemOption>
              <MenuItemOption value="bezierCustom1">
                bezierCustom1
              </MenuItemOption>
              <MenuItemOption value="bezierCustom2">
                bezierCustom2
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <Flex
          ref={ref}
          direction="column"
          alignItems="center"
          width="85px"
          ml={1}
          sx={{
            '.chakra-progress__indicator': {
              transition: 'none !important',
              opacity: 1,
            },
          }}
        >
          <CircularProgress value={40} color="trueblue.500">
            <CircularProgressLabel></CircularProgressLabel>
          </CircularProgress>
        </Flex>
      </Flex>
    )
  }
)

PeekDebugger.displayName = 'PeekDebugger'

const PeekCardTab = React.forwardRef(
  (
    {
      direction,
      enabled,
      node,
      offset,
    }: {
      direction: DirectionType
      enabled: boolean
      node: ProseMirrorNode | null
      offset: number
    },
    ref: MutableRefObject<HTMLDivElement | null>
  ) => {
    const [showCheck, setCheck] = useState(false)
    const [nodeToUse, setNode] = useState(node)

    useEffect(() => {
      setCheck(true)
      // When the node changes, wait a bit to show the next node info
      const timeout = setTimeout(() => {
        setCheck(false)
        setNode(node)
      }, TRIGGER_ACTION_DELAY + OPACITY_TRANSITION_TIME)

      return () => {
        clearTimeout(timeout)
      }
    }, [node])

    // USE MEMO THIS
    const title = useMemo(() => {
      return nodeToUse ? getCardTitle(nodeToUse.toJSON()) : ''
    }, [nodeToUse])

    const isInside = Boolean(nodeToUse) // Not the start or end of the deck

    return (
      <Flex
        ref={ref}
        pointerEvents="none"
        position="absolute"
        justifyContent="center"
        width="100%"
        zIndex="overlay"
        top={direction === 'up' ? `${offset}px` : 'inherit'}
        bottom={direction === 'down' ? `${offset}px` : 'inherit'}
      >
        <Flex
          bg="gray.100"
          borderRadius="md"
          boxShadow="md"
          h={`${PEEK_ELEMENT_HEIGHT}px`}
          maxWidth="450px"
          justifyContent="center"
          opacity={enabled ? 1 : 0}
          transition={`opacity ${OPACITY_TRANSITION_TIME}ms ease-out`}
          textAlign="center"
          alignItems="center"
          direction="row"
          px={2}
          sx={{
            '.chakra-progress__indicator': {
              transition: 'none !important',
              opacity: 1,
            },
          }}
        >
          <CircularProgress
            display={isInside ? 'block' : 'none'}
            color="trueblue.500"
            size="20px"
            thickness="16px"
          >
            <CircularProgressLabel
              visibility={showCheck ? 'visible' : 'hidden'}
            >
              <FontAwesomeIcon
                icon={regular('circle-check')}
                fontSize="14px"
                style={{
                  color: 'var(--chakra-colors-gray-100)',
                  backgroundColor: 'var(--chakra-colors-trueblue-500)',
                  borderRadius: '10px',
                }}
              />
            </CircularProgressLabel>
          </CircularProgress>
          {!isInside && direction === 'up' ? (
            <FontAwesomeIcon
              icon={regular('flag-checkered')}
              size="xs"
              color="var(--chakra-colors-gray-600)"
            />
          ) : !isInside && direction === 'down' ? (
            <FontAwesomeIcon
              icon={regular('trophy-star')}
              size="xs"
              color="var(--chakra-colors-gray-600)"
            />
          ) : null}
          <Flex width={1} />
          <Text
            noOfLines={1}
            textOverflow="ellipsis"
            overflowX="hidden"
            fontSize="md"
            color={isInside ? 'gray.800' : 'gray.600'}
            px={2}
          >
            {isInside
              ? title
              : direction === 'down'
              ? `End of ${DOC_DISPLAY_NAME}`
              : `Start of ${DOC_DISPLAY_NAME}`}
          </Text>
        </Flex>
      </Flex>
    )
  }
)

PeekCardTab.displayName = 'PeekCardTab'
