import { Flex } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { docTitleOrPlaceholder, DOC_DISPLAY_NAME } from '@gamma-app/ui'
import Head from 'next/head'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'modules/redux'

import { DocEditorType, DocEditorTypeType } from '../constants'
import { useEditorContext, withEditorContext } from '../context'
import { selectDoc, setDocEditorType } from '../reducer'
import { MobileEditorWrapper } from './components/MobileDocView/MobileEditorWrapper'
import { useHandleCardHash } from './hooks/useHandleCardHash'
import { useDocTracking } from './useDocTracking'
interface MobileDocViewerProps {
  docEditorType?: DocEditorTypeType
}

const MobileDocViewerComponent = ({
  docEditorType = DocEditorType.COLLABORATIVE,
}: MobileDocViewerProps) => {
  const dispatch = useAppDispatch()
  const { docId, isUnauthorized, editor } = useEditorContext()
  useDocTracking(docId, docEditorType)

  useHandleCardHash()
  const editorWrapperRef = useRef<HTMLDivElement | null>(null)
  const doc = useSelector(selectDoc)

  useEffect(() => {
    dispatch(setDocEditorType({ docEditorType }))
  }, [dispatch, docEditorType])

  console.debug(
    `%c[MobileDocViewerComponent] RENDER - ${docId}`,
    'background-color: gold'
  )

  const commentsEnabled = docEditorType === DocEditorType.COLLABORATIVE

  return (
    <>
      <Head>
        <title>
          {`${doc ? docTitleOrPlaceholder(doc.title) + ' | ' : ''}`}
          Gamma
        </title>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no"
        ></meta>
      </Head>
      <Global
        styles={{
          html: {
            // Prevents side scroll
            overflowX: 'hidden',
          },
          body: {
            // Prevents side scroll
            overflow: 'hidden scroll',
            // Keeps vertical scrollbar visible
            WebkitOverflowScrolling: 'touch',
          },
        }}
      />
      {isUnauthorized ? (
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          color="gray.500"
          fontSize="2xl"
          p={6}
          textAlign="center"
        >
          It looks like you don't have access to this {DOC_DISPLAY_NAME}.
        </Flex>
      ) : (
        <Flex
          id="doc-main"
          direction="column"
          w="100%"
          h="100%"
          data-testid="doc-editor-root"
          ref={editorWrapperRef}
          flex="1"
        >
          <MobileEditorWrapper
            doc={doc}
            docEditorType={docEditorType}
            commentsEnabled={commentsEnabled}
          />
        </Flex>
      )}
    </>
  )
}

export const MobileDocViewer = withEditorContext(MobileDocViewerComponent)
