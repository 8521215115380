import { Editor } from '@tiptap/core'
import { findChildren } from '@tiptap/react'
import { debounce, uniqBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { useScrollManager } from 'modules/scroll'
import { isCardNode } from 'modules/tiptap_editor/extensions/Card'
import { getOffsetFromParent } from 'utils/dom'

import {
  selectExpandedCardsMap,
  selectMode,
  selectPresentingCardId,
} from '../reducer'
import { EditorModeEnum } from '../types'
import { CardViewedEmitter } from './CardViewedEmitter'
import { CARD_VIEWED_INTERVAL_TICK } from './constants'
import { computeIsViewing, OverlapResult } from './utils'

type WhichCardData = {
  scrollTop: number
  containerHeight: number
  presentingCardId: string | null
  expandedCards: Record<string, boolean>
}

export const useEmitViewedCard = (
  editor: Editor,
  emitter: CardViewedEmitter
) => {
  const scrollManager = useScrollManager('editor')
  const [rootEl, setRootEl] = useState<Element | null>(null)
  const ref = useRef<WhichCardData>({
    presentingCardId: null,
    scrollTop: 0,
    containerHeight: 0,
    expandedCards: {},
  })

  const mode = useAppSelector(selectMode)

  // sync presentingCardId selector to ref
  const presentingCardId = useAppSelector(selectPresentingCardId)
  useEffect(() => {
    ref.current.presentingCardId = presentingCardId ?? null
  }, [presentingCardId])

  // sync expandedCardsMap selector to ref
  const expandedCardsMap = useAppSelector(selectExpandedCardsMap)
  useEffect(() => {
    ref.current.expandedCards = expandedCardsMap
  }, [expandedCardsMap])

  // get initial scroll manager containerHeight and scrollTop
  useEffect(() => {
    const el = document.querySelector(scrollManager.scrollSelector)
    if (!el) return

    setRootEl(el)
    const rootRect = el.getBoundingClientRect()
    const { height } = rootRect
    ref.current.scrollTop = el.scrollTop
    ref.current.containerHeight = height
  }, [scrollManager.scrollSelector, ref])

  // update scrollTop on scroll
  useEffect(() => {
    const handleScroll = debounce(
      () => {
        if (!rootEl) return
        ref.current.scrollTop = rootEl.scrollTop
      },
      250,
      {
        trailing: true,
        maxWait: 500,
      }
    )
    window.addEventListener('scroll', handleScroll, true)
    return () => window.removeEventListener('scroll', handleScroll, true)
  }, [rootEl])

  // update containerHeight on resize
  useEffect(() => {
    const handleResize = debounce(
      () => {
        if (!rootEl) return
        ref.current.containerHeight = rootEl.getBoundingClientRect().height
      },
      250,
      {
        trailing: true,
        maxWait: 500,
      }
    )
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [rootEl])

  useEffect(() => {
    if (!editor) return

    // In SLIDE_VIEW mode we only need to look at the presentingCardId
    if (mode === EditorModeEnum.SLIDE_VIEW) {
      const slideViewIntervalId = setInterval(() => {
        emitter.emit('cardViewed', {
          cardId: ref.current.presentingCardId!,
          interval: CARD_VIEWED_INTERVAL_TICK,
        })
      }, CARD_VIEWED_INTERVAL_TICK)
      return () => clearInterval(slideViewIntervalId)
    }

    /**
     * The job of this set interval is soley to figure out which cards are
     * being viewed at a given interval (1000ms) and emit a cardViewed event
     */
    const id = setInterval(() => {
      // dont tick when document is hidden
      if (document.hidden) return

      const { scrollTop, containerHeight, expandedCards } = ref.current
      const containerStart = scrollTop
      const containerEnd = scrollTop + containerHeight

      // unique by card id, because findChildren can return double for nested cards
      uniqBy(findChildren(editor.state.doc, isCardNode), (a) => a.node.attrs.id)
        .map<(OverlapResult & { id: string }) | null>((card) => {
          const cardId = card.node.attrs.id
          const isExpanded = expandedCards[cardId]
          // try catch for reloading
          let domNode
          try {
            domNode = editor.view.nodeDOM(card.pos)
          } catch (e) {
            return null
          }

          if (domNode instanceof HTMLElement) {
            // Compute the offset from the top of the domNode to the top of the scroller
            // container (which for nested cards could be several parent offsetTops combined)
            const start = getOffsetFromParent(
              domNode,
              scrollManager.scrollSelector
            )
            const { offsetHeight } = domNode
            const end = offsetHeight + start

            return {
              id: cardId,
              ...computeIsViewing(
                containerStart,
                containerEnd,
                start,
                end,
                isExpanded
              ),
            }
          }
          return null
        })
        // filtered out cards that computeOverlap determines as "viewing"
        .filter((a): a is OverlapResult & { id: string } => !!a?.viewing)
        .forEach((a) => {
          emitter.emit('cardViewed', {
            cardId: a.id,
            interval: CARD_VIEWED_INTERVAL_TICK,
          })
        })
    }, CARD_VIEWED_INTERVAL_TICK)

    return () => clearInterval(id)
  }, [mode, editor, ref, emitter, scrollManager.scrollSelector])
}
