import { ApolloError } from '@apollo/client'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  Button,
} from '@chakra-ui/react'

import {
  useGetDocCardsAnalyticsQuery,
  useGetMemoAnalyticsQuery,
} from 'modules/api'

type AnalyticsErrorProps = BoxProps & {
  error: ApolloError
  refetch: ReturnType<
    typeof useGetMemoAnalyticsQuery | typeof useGetDocCardsAnalyticsQuery
  >['refetch']
}
export const AnalyticsError = ({
  error,
  refetch,
  ...props
}: AnalyticsErrorProps) => {
  return (
    <Box px={6} py={4} {...props}>
      <Alert
        p={6}
        status="error"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon mr={0} boxSize="30px" alignSelf="center" />
        <AlertTitle mt={4} mb={2} fontSize="md">
          There was an error loading the analytics data.
        </AlertTitle>
        <AlertDescription maxWidth="md">{error.message}</AlertDescription>
        <Button mt={4} onClick={() => refetch()}>
          Retry
        </Button>
      </Alert>
    </Box>
  )
}
