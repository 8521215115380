import { gql } from '@apollo/client'

export const DEFAULT_CARD_FIELDS_FRAGMENT = gql`
  fragment DefaultCardFields on Card {
    id
    archived
    docId
    title
    text
    previewUrl
    createdTime
    updatedTime
  }
`

export const CREATE_CARD_GQL = gql`
  mutation CreateCard($id: ID!, $docId: ID!) {
    createCard(id: $id, docId: $docId) {
      id
    }
  }
`

// Note: This GQL is only for getting the default card fields. Don't add
// comments or reactions to this.
export const GET_CARD_GQL = gql`
  ${DEFAULT_CARD_FIELDS_FRAGMENT}
  query GetCard($id: ID!, $docId: ID!) {
    card(id: $id, docId: $docId) {
      ...DefaultCardFields
    }
  }
`

export const GET_CARDS_GQL = gql`
  ${DEFAULT_CARD_FIELDS_FRAGMENT}
  query GetCards($docId: ID!) {
    docCards(docId: $docId) {
      ...DefaultCardFields
    }
  }
`

export const CARDS_SUBSCRIPTION_GQL = gql`
  ${DEFAULT_CARD_FIELDS_FRAGMENT}
  subscription SubscribeToCards($docId: ID!) {
    docCards(docId: $docId) {
      ...DefaultCardFields
    }
  }
`
