import {
  Box,
  Heading,
  HStack,
  Kbd,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SectionTitle, getShortcutTextForOS } from '@gamma-app/ui'

import {
  DOC_MODE_SHORTCUTS,
  PRESENT_MODE_SHORTCUTS,
  ShortcutConfig,
  TEXT_FORMATTING_SHORTCUTS,
} from './keyboardShortcutConfig'

const tdStyles = {
  borderColor: 'gray.100',
  py: 2,
  px: 2,
}

const ShortcutsTable = ({ list }: { list: Array<ShortcutConfig> }) => {
  return (
    <TableContainer w="100%" mb={8}>
      <Table variant="simple">
        <Tbody>
          {list.map(({ shortcut, description }, index) => {
            return (
              <Tr key={index}>
                <Td w="60%" {...tdStyles}>
                  {description}
                </Td>
                <Td w="40%" {...tdStyles}>
                  {Array.isArray(shortcut) ? (
                    shortcut.map((s, index) => (
                      <Kbd
                        display="inline-block"
                        px={2}
                        py={1}
                        mr={1}
                        key={index}
                      >
                        {typeof s === 'string' ? getShortcutTextForOS(s) : s}
                      </Kbd>
                    ))
                  ) : typeof shortcut == 'string' ? (
                    <Kbd display="inline-block" px={2} py={1}>
                      {getShortcutTextForOS(shortcut)}
                    </Kbd>
                  ) : (
                    <Kbd display="inline-block" px={2} py={1}>
                      {shortcut}
                    </Kbd>
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
export const KeyboardShortcutsPopover = ({ isOpen, onClose }) => {
  return (
    <Popover
      returnFocusOnClose={false}
      closeOnBlur={false}
      placement="top"
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverAnchor>
        <Box position="fixed" bottom={0} right={0} />
      </PopoverAnchor>
      <PopoverContent
        zIndex="menu"
        h="80vh"
        w="400px"
        flex={1}
        position="relative"
      >
        <PopoverHeader border="0 none" mb={2} position="sticky" bgColor="white">
          <HStack spacing={2}>
            <FontAwesomeIcon icon={regular('keyboard')} />
            <Heading size="md">Shortcuts</Heading>
          </HStack>
          <PopoverCloseButton />
        </PopoverHeader>
        <PopoverBody overflowY="auto">
          <Tabs variant="soft-rounded" size="sm" isFitted>
            <TabList mb={6}>
              <Tab>
                <Box mr={2}>
                  <FontAwesomeIcon icon={regular('pencil')} />
                </Box>
                Doc mode
              </Tab>
              <Tab>
                <Box mr={2}>
                  <FontAwesomeIcon icon={regular('presentation-screen')} />
                </Box>
                Present mode
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pb={1} mt={4}>
                <ShortcutsTable list={DOC_MODE_SHORTCUTS} />
                <SectionTitle pt={4} pb={4}>
                  Text & formatting
                </SectionTitle>
                <ShortcutsTable list={TEXT_FORMATTING_SHORTCUTS} />
              </TabPanel>
              <TabPanel p={0} pb={1} mt={4}>
                <ShortcutsTable list={PRESENT_MODE_SHORTCUTS} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
