import { useEffect } from 'react'

const BODY_DRAG_CLASS = 'drag-in-progress'

// Add a class to the body whenever a drag event is in progress
export const useBodyDragClass = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleDragStart = () => {
      document.body.classList.add(BODY_DRAG_CLASS)
    }
    const handleDragEnd = () => {
      document.body.classList.remove(BODY_DRAG_CLASS)
    }
    document.body.addEventListener('dragstart', handleDragStart, true)
    document.body.addEventListener('dragend', handleDragEnd, true)
    document.body.addEventListener('drop', handleDragEnd, true)
    // This is a safety net to prevent the yucky state where the class remains
    // when it shouldnt. If there is a better way to do this, I'm all ears.
    document.body.addEventListener('mouseup', handleDragEnd, true)

    return () => {
      document.body.removeEventListener('dragstart', handleDragStart, true)
      document.body.removeEventListener('dragend', handleDragEnd, true)
      document.body.removeEventListener('drop', handleDragEnd, true)
      document.body.removeEventListener('mouseup', handleDragEnd, true)
    }
  }, [])
}
