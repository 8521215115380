import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { useRef, useState } from 'react'

import { useUpdateDocTitleMutation } from 'modules/api'

type TitleModalProps = {
  isOpen: boolean
  onClose: () => void
  onSaveSuccess: () => void
  docId: string
}
export const TitleModal = ({
  isOpen,
  onClose,
  onSaveSuccess,
  docId,
}: TitleModalProps) => {
  const toast = useToast()
  const initialFocusRef = useRef(null)
  const [title, setTitle] = useState('')
  const [updateDocTitleMutation, { loading }] = useUpdateDocTitleMutation()
  const handleTitleSave = () => {
    updateDocTitleMutation({ variables: { id: docId, title: title } })
      .then(() => {
        setTitle(() => '')
        // Invokes share panel dialog, which will open the SharePanel dialog
        onSaveSuccess()
      })
      .catch((e) => {
        // Console.error here? How do we get Sentry to capture it
        console.error(e)
        toast({
          title: `Error saving title: ${e.message}`,
          position: 'top',
          status: 'error',
        })
      })
  }

  return (
    <Modal
      returnFocusOnClose={false}
      onClose={onClose}
      closeOnOverlayClick={true}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Name your {DOC_DISPLAY_NAME} before sharing</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            ref={initialFocusRef}
            value={title}
            required
            placeholder={`Untitled ${DOC_DISPLAY_NAME}`}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTitleSave()
              }
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} direction="row" align="center">
            <Button
              isLoading={loading}
              disabled={loading || !title || !title.length}
              onClick={() => {
                handleTitleSave()
              }}
            >
              Save
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
