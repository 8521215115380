import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'

import { ScrollManagerTypes, useScrollManager } from '../scrollManager'
// Adapted from https://www.fabrizioduroni.it/2022/01/02/react-detect-scroll-direction/

export const useScrollDirection = (type: ScrollManagerTypes) => {
  const scrollManager = useScrollManager(type)

  const threshold = 0
  const [scrollDir, setScrollDir] = useState<'up' | 'down'>('up')
  useEffect(() => {
    const rootEl = document.querySelector(scrollManager.scrollSelector)
    if (!rootEl) return
    let previousScrollYPosition = rootEl?.scrollTop || 0

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold

    const isScrollingUp = (currentScrollYPosition: number) => {
      const result =
        currentScrollYPosition > previousScrollYPosition &&
        !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
        !(currentScrollYPosition > 0 && previousScrollYPosition === 0)

      return result
    }

    const updateScrollDirection = () => {
      const currentScrollYPosition = rootEl?.scrollTop || 0
      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
          ? 'down'
          : 'up'
        setScrollDir(newScrollDirection)
        previousScrollYPosition =
          currentScrollYPosition > 0 ? currentScrollYPosition : 0
      }
    }

    // UGH I had to add this because this does weird stuff to the TOC when you jump to different cards.
    const onScroll = throttle(
      () => window.requestAnimationFrame(updateScrollDirection),
      500,
      { leading: true }
    )

    rootEl?.addEventListener('scroll', onScroll)

    return () => rootEl?.removeEventListener('scroll', onScroll)
  }, [scrollManager.scrollSelector])

  return scrollDir
}
