import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { memo, useCallback, useState } from 'react'

import { useHealthCheck, useUpdateDocThemeMutation } from 'modules/api'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { emptyTheme, Theme } from 'modules/theming'
import { ThemeEditorDrawer } from 'modules/theming/components/ThemeEditorDrawer'
import { DocThemeDrawer } from 'modules/tiptap_editor/components/drawers/DocThemeDrawer/DocThemeDrawer'
import { Document } from 'modules/tiptap_editor/extensions/Document'
import { DocumentAttributes } from 'modules/tiptap_editor/extensions/Document/DocumentAttrs/attributes'
import { useEditorContext } from 'sections/docs/context'

import { ToolbarButton } from './components/ToolbarButton'

export const ThemeBlock = memo(() => {
  const toast = useToast()
  const { isConnected } = useHealthCheck()
  const { docId, getCollaborativeEditorInstance } = useEditorContext()
  const {
    isOpen: isThemeEditorOpen,
    onOpen: onThemeEditorOpen,
    onClose: onThemeEditorClose,
  } = useModalDisclosure({ id: 'themeEditor' })
  const {
    isOpen: isStyleDrawerOpen,
    onOpen: onStyleDrawerOpen,
    onClose: onStyleDrawerClose,
  } = useModalDisclosure({ id: 'themeStyleDrawer' })

  const docContent = getCollaborativeEditorInstance()?.state.doc.toJSON()

  const [theme, setTheme] = useState<Theme>(emptyTheme)

  const openThemeEditor = useCallback(
    (themeToEdit?: Theme) => {
      setTheme(themeToEdit || emptyTheme)
      onThemeEditorOpen()
    },
    [onThemeEditorOpen]
  )

  const editor = getCollaborativeEditorInstance()
  const analytics = useAnalytics()

  const updateAttributesAndTrack = useCallback(
    (docAttrs: Partial<DocumentAttributes>) => {
      if (!editor) return
      editor.commands.updateAttributes(Document.name, docAttrs)
      analytics?.track(SegmentEvents.DOC_ATTRIBUTES_UPDATED, docAttrs)
    },
    [editor, analytics]
  )

  const [updateDocTheme] = useUpdateDocThemeMutation()

  const setDocTheme = useCallback(
    (newTheme: Theme) => {
      if (docId) {
        updateDocTheme({
          variables: { id: docId, themeId: newTheme.id },
        }).catch((e) => {
          console.error(e)
          toast({
            title: `Error saving doc theme: ${e.message}`,
            position: 'top',
            status: 'error',
          })
        })
      }
    },
    [docId, updateDocTheme, toast]
  )

  return (
    <Flex>
      <GammaTooltip
        label={
          isConnected ? (
            'Edit theme'
          ) : (
            <>
              <Text>You're offline</Text>
              <Text color="gray.300">
                Themes will be available when you reconnect.
              </Text>
            </>
          )
        }
        aria-label="Edit theme"
      >
        <Box>
          <ToolbarButton
            onClick={onStyleDrawerOpen}
            isDisabled={!isConnected}
            icon={<FontAwesomeIcon icon={regular('palette')} />}
            data-testid="toolbar-theme-button"
          >
            Theme
          </ToolbarButton>
        </Box>
      </GammaTooltip>
      <DocThemeDrawer
        getEditor={getCollaborativeEditorInstance}
        isOpen={isStyleDrawerOpen && !isThemeEditorOpen}
        onClose={onStyleDrawerClose}
        openThemeEditor={openThemeEditor}
        setDocTheme={setDocTheme}
        updateAttributesAndTrack={updateAttributesAndTrack}
      />
      <ThemeEditorDrawer
        isOpen={isThemeEditorOpen}
        onClose={onThemeEditorClose}
        theme={theme}
        setTheme={setTheme}
        docContent={docContent}
        setDocTheme={setDocTheme}
      />
    </Flex>
  )
})

ThemeBlock.displayName = 'ThemeBlock'
