import { Text } from '@chakra-ui/react'
import { capitalize } from '@gamma-app/ui'
import { format, formatISO, parseISO } from 'date-fns'

import { DocDayStats } from 'modules/api'

import { Datum } from '../types'
import { BarChart, Data } from './BarChart'

// 'LLL d' format is 'Jan 1'
const formatDate = (date?: string) => {
  if (!date) return ''
  return format(parseISO(date), 'LLL d')
}

const formatViewsDataForPastDays = (
  dayStats: DocDayStats[],
  dayCount: number
): Data => {
  const startDate = new Date()
  // set startDate to `dayCount` days ago plus 1 to include today
  startDate.setDate(startDate.getDate() - dayCount + 1)
  const data: { x: string; y: number }[] = []
  for (let i = 0; i < dayCount; i++) {
    const date = new Date(startDate)
    date.setDate(date.getDate() + i)
    const dateFormatted = formatISO(date, { representation: 'date' })
    const currentView = dayStats.find((day) => day.date === dateFormatted)
    // use the current view's y-value if available, otherwise set y-value to 0
    if (currentView) {
      data.push({
        x: formatDate(currentView.date),
        y: currentView.uniqueVisitors ? currentView.uniqueVisitors : 0,
      })
    } else {
      data.push({ x: formatDate(dateFormatted), y: 0 })
    }
  }
  return data
}

type ViewersTimeBarChartProps = {
  viewData: DocDayStats[]
  dayCount: number
}

const viewerLabel = 'unique viewers'
const viewerLabelSingular = 'unique viewer'

export const ViewersTimeBarChart = ({
  viewData,
  dayCount,
}: ViewersTimeBarChartProps) => {
  const formattedViewsData = formatViewsDataForPastDays(viewData, dayCount)

  const formattedChartTitle = `${capitalize(viewerLabel)} (last ${
    formattedViewsData.length
  } days)`

  return (
    <BarChart
      data={formattedViewsData}
      my={3}
      chartTitle={formattedChartTitle}
      TooltipInner={ViewersTimeBarChartTooltip}
    />
  )
}

const ViewersTimeBarChartTooltip = ({ x, y }: Datum) => {
  return (
    <>
      <Text>
        {y ?? 0} {y === 1 ? viewerLabelSingular : viewerLabel}
      </Text>
      <Text>on {x}</Text>
    </>
  )
}
