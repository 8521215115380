import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Circle,
  CloseButton,
  Collapse,
  Flex,
  Heading,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Spacer,
  Stack,
  StackProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CHANNEL_DISPLAY_NAME_PLURAL,
  DOC_DISPLAY_NAME,
  DOC_DISPLAY_NAME_PLURAL,
  GammaTooltip,
  LinkCopier,
  SectionTitle,
} from '@gamma-app/ui'
import isNil from 'lodash/isNil'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import {
  Doc,
  DocChannel,
  DocCollaborator,
  DocInvitation,
  Permission,
  useAddCollaboratorsMutation,
  useHealthCheck,
  useInviteMembersMutation,
  useRemoveCollaboratorsMutation,
  useUpdateDocAccessLinksMutation,
  useUpdateDocOrgAccessMutation,
  useUpdateDocPublicAccessMutation,
} from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { OfflineInfoBox } from 'modules/offline'
import {
  getVisibility,
  isOtherOrgDoc,
  VisibilityMap,
} from 'modules/sharing/utils'
import { useCan, useUserContext } from 'modules/user'
import { useHandleError } from 'utils/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { generateSlugifiedDocUrl } from 'utils/url'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { MANAGE_PERMISSION_LABEL } from '../../constants'
import { ChannelRow } from './ChannelRow'
import { ChannelSearchBar } from './ChannelSearchBar'
import { CollaboratorSearchBar } from './CollaboratorSearchBar'
import {
  NoAccessPermission,
  PermissionMapToHumanReadable,
  PermissionsMenu,
  PermissionsMenuProps,
} from './PermissionsMenu'

type IndividualCollaborator = DocCollaborator | DocInvitation

const collabNameSortFn = (
  a: IndividualCollaborator,
  b: IndividualCollaborator
) => {
  const nameA =
    a.__typename === 'DocCollaborator'
      ? a.user.displayName?.toUpperCase()
      : a.__typename === 'DocInvitation'
      ? a.email.toUpperCase()
      : ''
  const nameB =
    b.__typename === 'DocCollaborator'
      ? b.user.displayName?.toUpperCase()
      : b.__typename === 'DocInvitation'
      ? b.email.toUpperCase()
      : ''

  if (!nameB || (nameA && nameA < nameB)) {
    return -1
  }
  if (!nameA || (nameB && nameA > nameB)) {
    return 1
  }

  return 0
}

const managerThenNameSortFn = (
  a: IndividualCollaborator,
  b: IndividualCollaborator
) => {
  if (a.permission !== b.permission) {
    if (a.permission === 'manage') return -1
    if (b.permission === 'manage') return 1
  }
  return collabNameSortFn(a, b)
}

interface PermissionsSettingsSectionProps extends StackProps {
  children: React.ReactNode
  showBorder?: boolean
}
const PermissionsSettingsSection = ({
  showBorder = true,
  children,
  ...stackProps
}: PermissionsSettingsSectionProps) => {
  return (
    <VStack
      borderTopWidth={showBorder ? '1px' : 0}
      borderTopStyle={showBorder ? 'solid' : undefined}
      borderTopColor={showBorder ? 'gray.200' : undefined}
      px={6}
      py={4}
      my={'0 !important'}
      {...stackProps}
    >
      {children}
    </VStack>
  )
}
interface PermissionsSettingsRowProps {
  title: string
  subtitle?: string
  img: JSX.Element
  isDisabled?: boolean
  tooltipText?: string
  options: PermissionsMenuProps['options']
  disabledOptions?: PermissionsMenuProps['disabledOptions']
  selected: Permission | NoAccessPermission
  guest?: boolean
  email?: string
  displayName?: string
  workspaceName?: string
  onClick?: (option: Permission | NoAccessPermission) => void
  onRemove?: () => void
  publicAccess?: Permission
  workspaceId?: string
  testId?: string
}
export const PermissionsSettingsRow = ({
  title,
  subtitle,
  img,
  isDisabled = false,
  options,
  disabledOptions,
  selected,
  guest,
  email,
  displayName,
  workspaceName,
  onClick,
  onRemove,
  tooltipText,
  workspaceId,
  testId,
}: PermissionsSettingsRowProps) => {
  const [inviteMembers] = useInviteMembersMutation()
  const [hasClickedInvite, setHasClickedInvite] = useState(false)
  const toast = useToast()
  return (
    <HStack
      w={'100%'}
      justify="space-between"
      py={2}
      data-testid={`permission-settings-row-${testId}`}
    >
      <Box>
        <Flex alignItems="center">
          {img}
          <Box ml={3}>
            <Text>{title}</Text>
            <Flex align="center">
              {!isDisabled && guest && (
                <Popover closeOnBlur={true}>
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <Button
                          leftIcon={
                            <FontAwesomeIcon icon={regular('passport')} />
                          }
                          size="xs"
                          colorScheme="gray"
                          mr={1}
                          px={2}
                          h="auto"
                          py={1}
                        >
                          Guest
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent bg="white">
                        <PopoverArrow />
                        <PopoverHeader fontWeight="bold">
                          Invite guest to workspace?
                        </PopoverHeader>
                        <PopoverBody>
                          <Text fontSize="sm">
                            {displayName} is not a member of the {workspaceName}{' '}
                            workspace. If you invite them to this workspace,
                            they’ll have access to {DOC_DISPLAY_NAME}s shared
                            with workspace members.
                          </Text>
                          <Button
                            disabled={hasClickedInvite}
                            w="100%"
                            mt={2}
                            onClick={() => {
                              if (!workspaceId || !email) return
                              setHasClickedInvite(true)
                              const variables = {
                                workspaceId,
                                invitees: [{ email }],
                              }
                              inviteMembers({
                                variables,
                              })
                                .then(() => {
                                  toast({
                                    title: `Invitation sent`,
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                    position: 'top',
                                  })
                                })
                                .catch((e) => {
                                  console.error(e)
                                  toast({
                                    title: `Error sending invitation: ${e.message}`,
                                    status: 'error',
                                    isClosable: true,
                                    position: 'top',
                                  })
                                })
                                .finally(() => {
                                  setHasClickedInvite(false)
                                  onClose()
                                })
                            }}
                          >
                            Invite to workspace
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              )}
              {subtitle && (
                <Text fontSize="xs" color="gray.500">
                  {subtitle}
                </Text>
              )}
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box>
        {!isDisabled ? (
          <GammaTooltip
            isDisabled={!tooltipText}
            label={<Text>{tooltipText}</Text>}
            placement="top"
          >
            <Box>
              <PermissionsMenu
                isDisabled={isDisabled}
                options={options}
                disabledOptions={disabledOptions}
                selected={selected}
                onClick={onClick}
                onRemove={onRemove}
              />
            </Box>
          </GammaTooltip>
        ) : (
          <Text size="sm" color="gray.500">
            {PermissionMapToHumanReadable[selected].title}
          </Text>
        )}
      </Box>
    </HStack>
  )
}

type SharePanelModalProps = {
  doc?: Doc
  isSharePanelOpen: boolean
  onSharePanelClose: () => void
  onAnalyticsPanelOpen: () => void
}
export const SharePanelModal = ({
  doc,
  isSharePanelOpen,
  onSharePanelClose,
  onAnalyticsPanelOpen,
}: SharePanelModalProps) => {
  const orgIdErrorLogged = useRef(false)
  const [hasDismissedDocWorkspaceNotice, setHasDismissedDocWorkspaceNotice] =
    useLocalStorage<boolean | string>(
      USER_SETTINGS_CONSTANTS.hasDismissedDocWorkspaceNotice,
      false
    )
  const analytics = useFeatureFlag('analytics')

  const [verifySearchDone, setVerifySearchDone] = useState<() => Promise<any>>(
    // Allows the CollaboratorSearchBar to register a done fn that we'll call before
    // closing the dialog, allowing it to interrupt closing if needed
    () => () => Promise.resolve()
  )
  const toast = useToast()
  const { isConnected } = useHealthCheck()
  const [addCollaborators, { error: addError }] = useAddCollaboratorsMutation()
  const [removeCollaborators, { error: removeError }] =
    useRemoveCollaboratorsMutation()

  useHandleError('Error updating collaborator.', addError)
  useHandleError('Error removing collaborator.', removeError)

  const [updateDocOrgAccessMutation] = useUpdateDocOrgAccessMutation()
  const [updateDocPublicAccessMutation] = useUpdateDocPublicAccessMutation()
  const [updateDocAccessLinksMutation] = useUpdateDocAccessLinksMutation()
  const { user, currentWorkspace, isGammaOrgUser } = useUserContext()
  const firstAccessLink = doc?.accessLinks?.[0]
  const shareUrl = useMemo(
    () =>
      generateSlugifiedDocUrl({
        docTitle: doc?.title,
        docId: doc?.id,
        token: firstAccessLink?.token,
      }),
    [doc, firstAccessLink?.token]
  )

  const canManage = useCan('manage', doc)
  const canManageAndConnected = canManage && isConnected
  const allowUpdatingChannels = !isOtherOrgDoc(doc, currentWorkspace)
  const canViewAnalytics = analytics && canManage
  const shouldShowDocWorkspaceNotice =
    allowUpdatingChannels &&
    Boolean(!hasDismissedDocWorkspaceNotice && doc && !doc.orgAccess)
  const isInChannelsButNoOrgAccess = Boolean(
    !doc?.orgAccess &&
      doc?.channels &&
      doc?.channels.length > 0 &&
      doc?.channels.every((c) => isNil(c.permission))
  )

  const closeIfReady = useCallback(() => {
    verifySearchDone().then(
      () => onSharePanelClose(),
      () => {
        console.debug(
          '[handleSharePanelClose] CollaboratorSearchBar rejected 👎🏼'
        )
      }
    )
  }, [onSharePanelClose, verifySearchDone])

  const handleViewAnalyticsClick = useCallback(() => {
    onSharePanelClose()
    onAnalyticsPanelOpen()
  }, [onAnalyticsPanelOpen, onSharePanelClose])

  if (!user) return null

  const collaborators = doc?.collaborators || []
  const invitations = doc?.invitations || []
  const workspaceId = doc?.organization?.id
  const managerCount = collaborators!.filter(
    (c) => c.permission === Permission.Manage
  ).length

  const individualCollabList = [...collaborators!, ...invitations!].sort(
    managerThenNameSortFn
  )
  const permissionChangedToastFn = (verb: 'updated' | 'removed') => () => {
    toast({
      title: `Permission ${verb}`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const visibilityType = getVisibility(doc, user)
  const visibility = visibilityType ? VisibilityMap[visibilityType] : null

  // We should never get this far, but err on the side of
  // caution by rendering nothing and logging an error
  if (doc && visibility === null) {
    console.error('[SharePanel] Unknown VisibilityValue for doc', doc)
    return null
  }

  if (doc && !workspaceId && !orgIdErrorLogged.current) {
    console.error(
      '[SharePanelModal] Doc unexpectedly doesnt have a workspaceId',
      {
        docId: doc.id,
        organization: doc.organization,
      }
    )
    orgIdErrorLogged.current = true
  }

  const selectedAccessLinkPermission = firstAccessLink?.permission
    ? firstAccessLink?.permission
    : NoAccessPermission.NoAccess

  return (
    <Modal
      onEsc={closeIfReady}
      isOpen={isSharePanelOpen}
      onClose={onSharePanelClose}
      trapFocus={true}
      size="lg"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!doc ? 'Share' : `Share ${doc?.title}`}</ModalHeader>
        <ModalCloseButton
          onClick={(e) => {
            e.preventDefault()
            closeIfReady()
          }}
        />
        {!doc ? (
          <ModalBody pb={6} data-testid="share-panel-modal-body">
            <Stack w="calc(100% + 3rem)" marginInlineStart={-6}>
              <Stack px={6}>
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
              </Stack>
            </Stack>
          </ModalBody>
        ) : (
          <ModalBody pb={6} data-testid="share-panel-modal-body">
            <Stack w="calc(100% + 3rem)" marginInlineStart={-6}>
              {doc && workspaceId && (
                <>
                  {canManageAndConnected && (
                    <CollaboratorSearchBar
                      workspaceId={workspaceId}
                      docId={doc.id}
                      user={user}
                      existingDocCollaborators={collaborators}
                      existingInvitations={invitations}
                      registerDoneFn={setVerifySearchDone}
                      isDisabled={!canManage}
                    />
                  )}
                  <OfflineInfoBox
                    label="Sharing will be available when you reconnect."
                    isConnected={isConnected}
                    mx={6}
                    mb={4}
                  />
                  <HStack mt={4} mb={0} px={6}>
                    <SectionTitle>Collaborators</SectionTitle>
                    <Spacer />
                    <SectionTitle>Permissions</SectionTitle>
                  </HStack>
                  {individualCollabList && (
                    <PermissionsSettingsSection showBorder={false}>
                      {individualCollabList.map((c) => {
                        // Disable changing the manage permission if there aren't multiple managers
                        const hasMinimumManagers =
                          c.permission === Permission.Manage && managerCount < 2
                        const isDisabled =
                          !canManageAndConnected || hasMinimumManagers
                        const tooltipText = hasMinimumManagers
                          ? `At least 1 ${MANAGE_PERMISSION_LABEL} collaborator is required.`
                          : undefined

                        const commonProps = {
                          tooltipText,
                          isDisabled,
                          selected: c.permission,
                          workspaceName: doc.organization?.name,
                          workspaceId: workspaceId,
                        }

                        if (c.__typename === 'DocCollaborator') {
                          const u = c.user
                          return (
                            <PermissionsSettingsRow
                              {...commonProps}
                              key={u.id}
                              testId={u.email}
                              title={`${u.displayName} ${
                                u.id === user.id ? '(You)' : ''
                              }`}
                              subtitle={!isDisabled ? u.email : undefined}
                              options={[
                                Permission.Manage,
                                Permission.Edit,
                                Permission.Comment,
                                Permission.View,
                              ]}
                              disabledOptions={
                                c.guest
                                  ? [
                                      {
                                        permission: Permission.Manage,
                                        reason: `Only workspace members can have ${MANAGE_PERMISSION_LABEL} permission.`,
                                      },
                                    ]
                                  : []
                              }
                              guest={c.guest}
                              email={c.user.email}
                              displayName={u.displayName}
                              img={
                                <Avatar
                                  name={u.displayName}
                                  src={u.profileImageUrl}
                                  size="sm"
                                />
                              }
                              isDisabled={!canManageAndConnected}
                              onClick={(option) => {
                                const newPermission = option as Permission
                                const variables = {
                                  docId: doc.id,
                                  collaborators: [
                                    {
                                      userId: u.id,
                                      permission: newPermission,
                                    },
                                  ],
                                }
                                addCollaborators({
                                  variables,
                                  optimisticResponse: {
                                    addCollaborators: {
                                      id: variables.docId,
                                      collaborators: collaborators!.map(
                                        // Update this user's permission to the selected option
                                        (collab) => ({
                                          ...collab,
                                          permission:
                                            collab.user.id === u.id
                                              ? newPermission
                                              : collab.permission,
                                        })
                                      ),
                                      invitations,
                                      __typename: 'Doc',
                                    },
                                  },
                                })
                                  .then(permissionChangedToastFn('updated'))
                                  .catch(() => {})
                              }}
                              onRemove={() => {
                                const variables = {
                                  docId: doc.id,
                                  collaborators: [{ userId: u.id }],
                                }
                                removeCollaborators({
                                  variables,
                                  optimisticResponse: {
                                    removeCollaborators: {
                                      id: variables.docId,
                                      // Remove this user from the list of collaborators
                                      collaborators: collaborators!.filter(
                                        (collab) => collab.user.id !== u.id
                                      ),
                                      invitations,
                                      __typename: 'Doc',
                                    },
                                  },
                                }).then(permissionChangedToastFn('removed'))
                              }}
                            />
                          )
                        }
                        if (c.__typename === 'DocInvitation') {
                          return (
                            <PermissionsSettingsRow
                              {...commonProps}
                              key={c.email}
                              testId={c.email}
                              title={c.email}
                              subtitle={'Invitation Sent'}
                              email={c.email}
                              isDisabled={!canManageAndConnected}
                              options={[
                                Permission.Manage,
                                Permission.Edit,
                                Permission.Comment,
                                Permission.View,
                              ]}
                              disabledOptions={[
                                {
                                  permission: Permission.Manage,
                                  reason: `Only workspace members can have ${MANAGE_PERMISSION_LABEL} permission.`,
                                },
                              ]}
                              img={
                                <Circle bg="trueblue.50" size={8}>
                                  <FontAwesomeIcon
                                    icon={regular('envelope')}
                                    size="sm"
                                  />
                                </Circle>
                              }
                              onClick={(option) => {
                                const newPermission = option as Permission
                                const variables = {
                                  docId: doc.id,
                                  collaborators: [
                                    {
                                      email: c.email,
                                      permission: newPermission,
                                    },
                                  ],
                                }
                                addCollaborators({
                                  variables,
                                  optimisticResponse: {
                                    addCollaborators: {
                                      id: variables.docId,
                                      invitations: invitations!.map(
                                        // Update this user's permission to the selected option
                                        (invitation) => ({
                                          ...invitation,
                                          permission:
                                            invitation.email === c.email
                                              ? newPermission
                                              : invitation.permission,
                                        })
                                      ),
                                      collaborators,
                                      __typename: 'Doc',
                                    },
                                  },
                                })
                                  .then(permissionChangedToastFn('updated'))
                                  .catch(() => {
                                    console.warn('Error updating permission')
                                  })
                              }}
                              onRemove={() => {
                                const variables = {
                                  docId: doc.id,
                                  collaborators: [{ email: c.email }],
                                }
                                removeCollaborators({
                                  variables,
                                  optimisticResponse: {
                                    removeCollaborators: {
                                      id: variables.docId,
                                      // Remove this user from the list of invitations
                                      invitations: invitations!.filter(
                                        (invitation) =>
                                          invitation.email !== c.email
                                      ),
                                      collaborators,
                                      __typename: 'Doc',
                                    },
                                  },
                                }).then(permissionChangedToastFn('removed'))
                              }}
                            />
                          )
                        }
                        return null
                      })}
                    </PermissionsSettingsSection>
                  )}
                  {canManage && (
                    <PermissionsSettingsSection showBorder={false}>
                      <PermissionsSettingsRow
                        title={'Add collaborators via link'}
                        isDisabled={!canManageAndConnected}
                        img={
                          <Circle
                            color="gray.800"
                            textAlign="center"
                            bg="gray.200"
                            size="32px"
                          >
                            <FontAwesomeIcon
                              size="1x"
                              icon={regular('share')}
                            />
                          </Circle>
                        }
                        selected={selectedAccessLinkPermission}
                        options={[
                          Permission.Edit,
                          Permission.Comment,
                          Permission.View,
                          NoAccessPermission.NoAccess,
                        ]}
                        onClick={(option) => {
                          const existingAccessLinkId = firstAccessLink?.id
                          const variables = {
                            id: doc.id,
                            accessLinks:
                              option === NoAccessPermission.NoAccess
                                ? [
                                    {
                                      permission: null,
                                    },
                                  ]
                                : [
                                    {
                                      permission: option,
                                    },
                                  ],
                          } as const
                          updateDocAccessLinksMutation({
                            // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
                            // @ts-ignore
                            variables,
                            optimisticResponse: {
                              // @ts-ignore
                              updateDoc: {
                                ...variables,
                                accessLinks: [
                                  // The API input only allows "permission", but we need the __typename and id so
                                  // that the apollo cache can identify the entry
                                  {
                                    __typename: 'AccessLink',
                                    id: existingAccessLinkId
                                      ? existingAccessLinkId
                                      : 'temp-id',
                                    // @ts-ignore
                                    permission:
                                      option === NoAccessPermission.NoAccess
                                        ? null
                                        : option,
                                  },
                                ],
                                __typename: 'Doc',
                              },
                            },
                          }).then(
                            permissionChangedToastFn(
                              option === NoAccessPermission.NoAccess
                                ? 'removed'
                                : 'updated'
                            )
                          )
                        }}
                      />
                      <Collapse
                        animateOpacity
                        in={Boolean(firstAccessLink?.permission)}
                      >
                        <VStack w={'100%'} mt={2}>
                          {/* TODO: Show "Give the doc a title" prompt */}
                          <LinkCopier
                            url={shareUrl}
                            customLabel="Copy link"
                            buttonWidth="8rem"
                          />
                          <Text
                            textAlign="left"
                            fontSize="xs"
                            color="gray.600"
                            mt={2}
                            display="block"
                            w="100%"
                          >
                            Instead of adding people individually, send them
                            this link &#8212; anyone who opens it will be added
                            as a collaborator with{' '}
                            <Text as="span" fontWeight="bold">
                              {selectedAccessLinkPermission}
                            </Text>{' '}
                            permission.
                          </Text>
                          {/* We're adding this Slack app callout temporarily, while we wait for approval to be listed in their App store. */}
                          <HStack
                            background="teal.50"
                            fontSize="sm"
                            width="100%"
                            padding={3}
                            borderRadius="md"
                          >
                            <FontAwesomeIcon icon={regular('message-smile')} />
                            <Text>
                              Give your deck a nice Slack preview with our{' '}
                              <Link
                                href="https://gamma.app/slack"
                                isExternal
                                fontWeight={600}
                                textDecoration="underline"
                              >
                                Slack app
                              </Link>
                              .
                            </Text>
                          </HStack>
                        </VStack>
                      </Collapse>
                    </PermissionsSettingsSection>
                  )}
                  <PermissionsSettingsSection>
                    <SectionTitle w="100%" mt={2}>
                      Workspace
                    </SectionTitle>
                    <Collapse in={shouldShowDocWorkspaceNotice} unmountOnExit>
                      <Stack
                        spacing={2}
                        bg="gray.100"
                        p={6}
                        position="relative"
                        borderRadius="md"
                      >
                        {/* You can change the access and then it goes away. If you specifically click the X, you'll never see it again */}
                        <CloseButton
                          pos="absolute"
                          right={3}
                          top={3}
                          onClick={() => {
                            setHasDismissedDocWorkspaceNotice(true)
                          }}
                        />
                        <Heading textAlign="center" color="gray.600">
                          <FontAwesomeIcon icon={duotone('folder-open')} />
                        </Heading>
                        <Text textAlign="center" fontWeight="700">
                          Add workspace access to use{' '}
                          {CHANNEL_DISPLAY_NAME_PLURAL}
                        </Text>
                        <Text fontSize="sm" color="gray.700" textAlign="center">
                          Once you've added workspace access, you can use{' '}
                          {CHANNEL_DISPLAY_NAME_PLURAL} to organize and discover{' '}
                          {DOC_DISPLAY_NAME_PLURAL}.
                        </Text>
                      </Stack>
                    </Collapse>
                    <PermissionsSettingsRow
                      testId={'workspace'}
                      title={'Workspace members'}
                      subtitle={`Everyone in ${doc?.organization?.name}`}
                      isDisabled={!canManageAndConnected}
                      img={
                        <Circle
                          color="gray.800"
                          textAlign="center"
                          bg="gray.200"
                          size="32px"
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={regular('building')}
                          />
                        </Circle>
                      }
                      selected={
                        doc.orgAccess
                          ? doc.orgAccess
                          : NoAccessPermission.NoAccess
                      }
                      options={[
                        Permission.Manage,
                        Permission.Edit,
                        Permission.Comment,
                        Permission.View,
                        NoAccessPermission.NoAccess,
                      ]}
                      onClick={(option) => {
                        const variables = {
                          id: doc.id,
                          orgAccess:
                            option === NoAccessPermission.NoAccess
                              ? null
                              : option,
                        }
                        updateDocOrgAccessMutation({
                          // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
                          // @ts-ignore
                          variables,
                          optimisticResponse: {
                            // @ts-ignore
                            updateDoc: { ...variables, __typename: 'Doc' },
                          },
                          // Refetch any outstanding docs queries because changing orgAccess can impact which docs are returned
                          refetchQueries: ['GetDocs'],
                        }).then(
                          permissionChangedToastFn(
                            option === NoAccessPermission.NoAccess
                              ? 'removed'
                              : 'updated'
                          )
                        )
                      }}
                    />
                    {allowUpdatingChannels && (
                      <GammaTooltip
                        label={'Give access to your workspace to add channels'}
                        aria-label={
                          'Give access to your workspace to add channels'
                        }
                        isDisabled={Boolean(doc.orgAccess)}
                      >
                        <Box w="100%" mt={2}>
                          <ChannelSearchBar
                            existingChannels={doc.channels as DocChannel[]}
                            workspaceId={workspaceId}
                            docId={doc.id}
                            isDisabled={!doc.orgAccess || !isConnected}
                          />
                        </Box>
                      </GammaTooltip>
                    )}
                    {allowUpdatingChannels &&
                      doc.channels?.map((channel) => {
                        return (
                          <ChannelRow
                            channel={channel}
                            doc={doc}
                            key={channel.id}
                            isDisabled={
                              isInChannelsButNoOrgAccess || !isConnected
                            }
                          />
                        )
                      })}
                    <Collapse
                      in={allowUpdatingChannels && isInChannelsButNoOrgAccess}
                    >
                      <Alert status="warning">
                        <AlertIcon />
                        Your {DOC_DISPLAY_NAME} will be hidden from these
                        channels until you provide workspace access
                      </Alert>
                    </Collapse>
                  </PermissionsSettingsSection>

                  {(doc.publicAccess || isGammaOrgUser) && (
                    <PermissionsSettingsSection
                      showBorder={false}
                      background={isGammaOrgUser ? 'sunglow.50' : undefined}
                      borderStyle={isGammaOrgUser ? 'dashed' : undefined}
                      borderWidth={isGammaOrgUser ? '3px' : undefined}
                      borderColor={isGammaOrgUser ? 'sunglow.500' : undefined}
                    >
                      <PermissionsSettingsRow
                        title={'Public access'}
                        img={
                          <Circle
                            color="gray.800"
                            textAlign="center"
                            bg="gray.200"
                            size="32px"
                          >
                            <FontAwesomeIcon
                              size="1x"
                              icon={regular('globe-americas')}
                            />
                          </Circle>
                        }
                        selected={
                          doc.publicAccess
                            ? doc.publicAccess
                            : NoAccessPermission.NoAccess
                        }
                        isDisabled={!canManageAndConnected}
                        options={[Permission.View, NoAccessPermission.NoAccess]}
                        onClick={(option) => {
                          const variables = {
                            id: doc.id,
                            publicAccess:
                              option === NoAccessPermission.NoAccess
                                ? null
                                : option,
                          } as const
                          updateDocPublicAccessMutation({
                            // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
                            // @ts-ignore
                            variables,
                            optimisticResponse: {
                              // @ts-ignore
                              updateDoc: { ...variables, __typename: 'Doc' },
                            },
                          }).then(() => {
                            toast({
                              title: `Public access ${
                                option === NoAccessPermission.NoAccess
                                  ? 'removed'
                                  : 'updated'
                              }`,
                              status: 'success',
                              duration: 5000,
                              isClosable: true,
                              position: 'top',
                            })
                          })
                        }}
                      />
                    </PermissionsSettingsSection>
                  )}
                </>
              )}
            </Stack>
          </ModalBody>
        )}
        <ModalFooter>
          <Flex
            justifyContent={canViewAnalytics ? 'space-between' : 'flex-end'}
            flex={1}
          >
            {canViewAnalytics && (
              <Button
                disabled={!isConnected}
                leftIcon={<FontAwesomeIcon icon={regular('chart-column')} />}
                variant="ghost"
                onClick={handleViewAnalyticsClick}
              >
                View analytics
              </Button>
            )}
            <Button
              onClick={(e) => {
                e.preventDefault()
                closeIfReady()
              }}
              variant="solid"
            >
              Done
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
