/**
 * Wrapper around useDisclosure, with the side effect of registering a modal
 * to the ModalState store
 * `useModalDisclosure({id: 'some id'})`
 */

import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setModalOpen } from '../reducer'

interface UseModalDisclosureProps extends UseDisclosureProps {
  id: string
}

export const useModalDisclosure = ({
  id,
  ...disclosureProps
}: UseModalDisclosureProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure(disclosureProps)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return
    dispatch(setModalOpen({ id, isOpen }))
  }, [isOpen, dispatch, id])

  useEffect(() => {
    return () => {
      if (!id) return
      dispatch(setModalOpen({ id, isOpen: false }))
    }
  }, [dispatch, id])

  return { isOpen, onOpen, onClose }
}
