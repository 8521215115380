import {
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo, useCallback } from 'react'

import { useMouseHotspot } from 'modules/hotspots'
import { useAppSelector } from 'modules/redux'
import { selectIsPresentModeAndNotEditing } from 'modules/tiptap_editor/reducer'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { HELP_WIDGET_POPOVER_CONTENT_PROPS } from '../constants'
import { HelpWidgetWrapper } from '../HelpWidgetWrapper'
import { CoachBar } from './CoachBar'

export const PresentModeHelpWidget = memo(() => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [hasSeenCoachBar, setHasSeenCoachBar] = useLocalStorage<boolean>(
    USER_SETTINGS_CONSTANTS.hasSeenCoachBar,
    false
  )
  const isPresentModeAndNotEditing = useAppSelector(
    selectIsPresentModeAndNotEditing
  )

  const handleClose = useCallback(() => {
    if (!hasSeenCoachBar) {
      setHasSeenCoachBar(true)
    }
    onClose()
  }, [hasSeenCoachBar, onClose, setHasSeenCoachBar])
  const isPopoverOpen = !hasSeenCoachBar || isOpen
  const isOnHotspot = useMouseHotspot(
    useCallback((e: MouseEvent) => {
      return (
        e.clientX > window.innerWidth - 150 &&
        e.clientY > window.innerHeight - 150
      )
    }, [])
  )

  return (
    <HelpWidgetWrapper
      mr={
        isPresentModeAndNotEditing &&
        hasSeenCoachBar &&
        !isPopoverOpen &&
        !isOnHotspot
          ? '-100%'
          : '0px'
      }
      transitionProperty="margin-right"
      transitionDuration="ultra-slow"
      transitionTimingFunction="ease-in-out"
    >
      <Popover
        returnFocusOnClose={false}
        isOpen={isPopoverOpen}
        onClose={handleClose}
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <IconButton
            onClick={() => {
              if (isOpen) {
                setHasSeenCoachBar(true)
              }
              onToggle()
            }}
            aria-label="Help"
            isRound
            size="md"
            icon={<FontAwesomeIcon icon={regular('keyboard')} />}
          />
        </PopoverTrigger>
        <PopoverContent {...HELP_WIDGET_POPOVER_CONTENT_PROPS}>
          <PopoverBody>
            <CoachBar onClose={handleClose} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HelpWidgetWrapper>
  )
})

PresentModeHelpWidget.displayName = 'PresentModeHelpWidget'
