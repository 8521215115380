import {
  Box,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Link,
  Text,
  useBreakpointValue,
  useToast,
  useTheme,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { findChildren } from '@tiptap/core'
import { AnimatePresence, motion } from 'framer-motion'
import NextLink from 'next/link'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Doc, useHealthCheck, useUpdateDocTitleMutation } from 'modules/api'
import { useGetCard } from 'modules/cards'
import { useFeatureFlag } from 'modules/featureFlags'
import { useAppStore } from 'modules/redux'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { isCardNode } from 'modules/tiptap_editor/extensions/Card'
import {
  selectCardIdMap,
  selectMode,
  selectPresentingCardId,
} from 'modules/tiptap_editor/reducer'
import { updateCardHash } from 'modules/tiptap_editor/utils/url'
import { useCan, useUserContext } from 'modules/user'
import { useEditorContext } from 'sections/docs/context'
import { useSessionStorage } from 'utils/hooks/useSessionStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

const TITLE_INPUT_WIDTH = 300
interface BreadcrumbBlockProps {
  doc?: Pick<Doc, 'id' | 'title' | 'collaborators'>
}

const MotionFlex = motion(Flex)

export const BreadcrumbBlock = ({ doc }: BreadcrumbBlockProps) => {
  return (
    <MotionFlex
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AnimatePresence initial={false}>
        <BreadcrumbGroup doc={doc} />
      </AnimatePresence>
    </MotionFlex>
  )
}

interface BreadcrumbButtonProps {
  cardId?: string
  isEllipsis?: boolean
  isHome?: boolean
  value?: string
  onClick?: () => void
  href?: string
  NextLink?: React.ElementType
}

const EditableDocTitle = ({ doc }: { doc?: Doc }) => {
  const { editor } = useEditorContext()
  const { isConnected } = useHealthCheck()
  const toast = useToast()
  const inputRef = useRef<HTMLInputElement>(null)
  const spanRef = useRef<HTMLDivElement>(null)
  const [updateDocTitle, { loading }] = useUpdateDocTitleMutation()
  const [currentTitle, setCurrentTitle] = useState<string | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [titleEdited, setTitleEdited] = useState<boolean>(false)
  const canEdit = useCan('edit', doc)

  const getComputedTitle = useCallback(() => {
    if (!editor) return ''

    const [firstCard] = findChildren(editor.state.doc, isCardNode)
    if (!firstCard) return ''

    const computedTitle = getCardTitle(firstCard.node.toJSON())
    return computedTitle || ''
  }, [editor])

  useEffect(() => {
    if (!doc || isEditing || loading) return
    setCurrentTitle(doc.title || '')
    setTitleEdited(!!doc.titleEdited)
  }, [doc, isEditing, loading])

  const setSpanWidth = () => {
    // Manually sets with width of the input element to match the width
    // of the hidden/ghost span to achieve the effect of dynamically
    // resizing the input box as you type.
    // See https://dev.to/matrixersp/how-to-make-an-input-field-grow-shrink-as-you-type-513l
    inputRef.current!.style.width = spanRef.current!.offsetWidth + 0 + 'px'
  }

  return (
    <GammaTooltip
      label={
        !canEdit
          ? null
          : isConnected
          ? 'Rename'
          : 'You must be online to edit the title.'
      }
      aria-label="rename"
    >
      <Editable
        value={currentTitle || ''}
        placeholder={currentTitle || getComputedTitle()}
        isDisabled={loading || !canEdit || !isConnected}
        isPreviewFocusable={canEdit}
        maxW={TITLE_INPUT_WIDTH}
        width="100%"
        fontWeight="normal"
        onCancel={() => {
          setSpanWidth()
          setIsEditing(false)
        }}
        onFocus={() => {
          setSpanWidth()
          if (!doc || loading) return
          setIsEditing(true)
        }}
        onChange={(nextVal: string) => {
          setSpanWidth()
          if (!isEditing) return
          setCurrentTitle(nextVal)
          setTitleEdited(!!nextVal)
        }}
        onSubmit={() => {
          if (!doc || loading) return
          setIsEditing(false)
          const titleToSave = currentTitle || getComputedTitle()

          updateDocTitle({
            variables: { id: doc!.id, title: titleToSave, titleEdited },
          }).catch((e) => {
            console.error(e)
            toast({
              title: `Error saving title: ${e.message}`,
              position: 'top',
              status: 'error',
            })
          })
        }}
      >
        <EditablePreview
          px={1}
          py={0.5}
          display={isEditing ? 'inline' : 'block'}
          overflowX="hidden"
          textOverflow="ellipsis"
          color={titleEdited ? 'gray.800' : 'inherit'}
        />
        <EditableInput
          ref={inputRef}
          px={1}
          py={0.5}
          color={titleEdited ? 'gray.800' : 'inherit'}
          maxW={TITLE_INPUT_WIDTH}
          _focus={{
            outline: 'none',
            shadow: '0 0 0 2px rgb(66 153 225 / 60%)',
          }}
        />
        {/** Hidden ghost span to help dynamically resize the input above */}
        <Box
          ref={spanRef}
          as="span"
          display="inline-block"
          position="absolute"
          left="-9999px"
          opacity={0}
          zIndex={-1}
          minW="180px"
          px={1}
          dangerouslySetInnerHTML={{
            __html: (currentTitle || '').replace(/\s/g, '&nbsp;'),
          }}
        ></Box>
      </Editable>
    </GammaTooltip>
  )
}

const BreadcrumbButton = ({
  cardId,
  isEllipsis,
  value,
  onClick,
}: BreadcrumbButtonProps) => {
  const card = useGetCard(cardId)

  if (isEllipsis) {
    return <FontAwesomeIcon icon={regular('ellipsis-h')} />
  }

  if (!cardId && !value) {
    return null
  }

  const label = value ? value : card?.title || ''

  return (
    <Text noOfLines={1} onClick={onClick} maxW={180} fontWeight="normal">
      {label}
    </Text>
  )
}

/**
 * A Breadcrumb item can be explicitly null (not rendered)
 * or take one of 4 different shapes:
 *   - Home
 *   - Ellipsis
 *   - Card Title
 *   - Doc Title
 */
type BreadcrumbBlockItem = {
  // When this is true, the block is just an ellipsis
  isEllipsis?: boolean

  // If this is true, the block is the home button
  isHome?: boolean

  isDocTitle?: boolean

  // If a value is present, it's the doc title
  value?: string

  // If the cardId
  cardId?: string
} | null

/**
 * Computes the current values for the breadcrumbs.
 * There are 4 possible total slots, but not all of them
 * are used unless were 3+ nested levels deep.
 */
export const useBreadcrumbData = () => {
  const reduxStore = useAppStore()
  const lastPresentingCardId = useRef('')
  const lastMode = useRef<EditorModeEnum>()
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbBlockItem[]>([
    null,
    null,
    null,
    null,
  ])
  const isPresentModeFlat = useFeatureFlag('presentModeFlat')

  useEffect(() => {
    const callback = () => {
      const state = reduxStore.getState()
      const mode = selectMode(state)
      const presentingCardId = selectPresentingCardId(state) || ''
      const cardIdMap = selectCardIdMap(state)
      const presentingCardsParents = cardIdMap.parents[presentingCardId] || []

      if (
        lastPresentingCardId.current === presentingCardId &&
        lastMode.current &&
        lastMode.current === mode
      ) {
        // Nothing changed, so bail
        return
      }

      if (mode === 'DOC_VIEW' || isPresentModeFlat) {
        // In doc view, always show Home and the doc title
        setBreadcrumbs([{ isHome: true }, { isDocTitle: true }])
        return
      }

      const firstCardId = presentingCardsParents[0]
      const presentingCardParentId =
        presentingCardsParents[presentingCardsParents.length - 1]

      setBreadcrumbs([
        { isHome: true },

        // At 2+ levels of nesting, ensure we show the top level card
        presentingCardsParents.length > 1 ? { cardId: firstCardId } : null,

        // At 3+ levels of nesting, show the ellipsis
        presentingCardsParents.length > 2 ? { isEllipsis: true } : null,

        // At all nested levels, show the direct parent
        presentingCardsParents.length > 0
          ? { cardId: presentingCardParentId }
          : null,

        // Presenting card is always last
        { cardId: presentingCardId },
      ])

      lastPresentingCardId.current = presentingCardId
      lastMode.current = mode
    }

    callback()

    return reduxStore.subscribe(callback)
  }, [reduxStore, isPresentModeFlat])

  return breadcrumbs as typeof breadcrumbs
}

export const BreadcrumbGroup = ({ doc }: { doc?: Doc }) => {
  const theme = useTheme()
  const breadcrumbs = useBreadcrumbData()
  const [lastHomeUrl] = useSessionStorage<string>(
    USER_SETTINGS_CONSTANTS.lastHomeUrl,
    '/'
  )
  const offlineEditingEnabled = useFeatureFlag('offline')
  const { user } = useUserContext()
  const { isConnected } = useHealthCheck()
  const size = useBreakpointValue({ base: 'xs', sm: 'sm' })

  return (
    <HStack
      {...theme.components.Button.baseStyle}
      {...theme.components.Button.variants.plain}
      spacing={0}
      pr={3}
    >
      <Breadcrumb
        display="flex"
        alignItems="center"
        whiteSpace="nowrap"
        size={size}
        spacing={['4px', '8px']}
        separator={
          <Box color="gray.300" mt={['0.2rem', '0.25rem']}>
            <FontAwesomeIcon icon={regular('chevron-right')} />
          </Box>
        }
      >
        {breadcrumbs.map((breadcrumb, idx) => {
          if (!breadcrumb) return null

          // The last item always represents where you currently are
          // (The Doc title in DOC mode or the presenting card in SLIDE mode)
          const isLast = idx === breadcrumbs.length - 1
          const { cardId, isEllipsis, isDocTitle, isHome, value } = breadcrumb
          const key = isHome
            ? 'home'
            : isEllipsis
            ? 'ellipsis'
            : cardId
            ? cardId
            : value
            ? value
            : idx

          if (isHome && !user) return null
          const homeLinkProps = {
            as: NextLink,
            href: lastHomeUrl || '/',
            colorScheme: 'gray',
            fontWeight: 'normal',
            'data-testid': 'toolbar-home-link',
          }
          return (
            <BreadcrumbItem key={key}>
              <MotionFlex
                key={key}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <BreadcrumbLink
                  isCurrentPage={isLast}
                  pointerEvents={isLast && !isDocTitle ? 'none' : 'inherit'}
                  color={isLast ? 'gray.500' : 'inherit'}
                  {...(isHome ? { ...homeLinkProps } : {})}
                >
                  {isDocTitle ? (
                    <EditableDocTitle doc={doc} />
                  ) : isHome ? (
                    <Link href={homeLinkProps.href}>
                      <FontAwesomeIcon size="1x" icon={regular('home')} />
                    </Link>
                  ) : (
                    <BreadcrumbButton
                      cardId={cardId}
                      value={value}
                      isEllipsis={isEllipsis}
                      onClick={() => {
                        if (cardId) {
                          updateCardHash({ cardId, method: 'push' })
                        }
                      }}
                    />
                  )}
                </BreadcrumbLink>
              </MotionFlex>
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
      {!isConnected && (
        <GammaTooltip
          label={
            <>
              <Text>You're offline</Text>
              {offlineEditingEnabled && (
                <Text color="gray.300">
                  Your changes will sync when you reconnect.
                </Text>
              )}
            </>
          }
          aria-label="offline"
        >
          <Flex color="gray.300" pl={2} alignItems="center">
            <FontAwesomeIcon icon={regular('cloud-slash')} />
          </Flex>
        </GammaTooltip>
      )}
    </HStack>
  )
}
