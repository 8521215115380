import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  CloseButton,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { capitalize } from '@gamma-app/ui'

import { CommentStatus } from 'modules/api'

import {
  COMMENT_DATE_DIVIDER_ZINDEX,
  COMMENT_FEED_HEADER_HEIGHT,
} from './constants'

type CommentFeedHeaderProps = {
  count: number
  onClose: () => void
  commentStatusFilter: CommentStatus
  setCommentStatusFilter: (CommentStatus) => void
}

export const CommentFeedHeader = ({
  count,
  onClose,
  commentStatusFilter,
  setCommentStatusFilter,
}: CommentFeedHeaderProps) => {
  return (
    <Flex
      width="100%"
      px={6}
      py={4}
      justifyContent="space-between"
      alignItems="center"
      h={COMMENT_FEED_HEADER_HEIGHT}
      tabIndex={0}
      outline="none"
    >
      <Heading size="md" flex={1} noOfLines={1}>
        {`${count} ${count === 1 ? 'comment' : 'comments'}`}
      </Heading>
      <Menu closeOnSelect={true}>
        <MenuButton
          as={Button}
          size="sm"
          rightIcon={<ChevronDownIcon />}
          mr={8} // Make room for the close button
        >
          {capitalize(commentStatusFilter)}
        </MenuButton>
        <MenuList zIndex={COMMENT_DATE_DIVIDER_ZINDEX + 1}>
          <MenuOptionGroup
            defaultValue={commentStatusFilter}
            title="Filter by"
            type="radio"
          >
            <MenuItemOption
              onClick={() => setCommentStatusFilter(CommentStatus.Open)}
              value="open"
            >
              Open
            </MenuItemOption>
            <MenuItemOption
              onClick={() => setCommentStatusFilter(CommentStatus.Closed)}
              value="closed"
            >
              Closed
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      <CloseButton position="absolute" top={2} right={2} onClick={onClose} />
    </Flex>
  )
}
