import {
  Box,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { GammaTooltip, getShortcutTextForOS } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import isHotkey from 'is-hotkey'
import { useCallback, useEffect, useRef, useState } from 'react'

import { GetDocQuery } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { keyboardHandler } from 'modules/keyboard'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { selectIsAnyModalOpen } from 'modules/modal_state/reducer'
import { useMeasureModeChangePerformance } from 'modules/performance/hooks'
import { AppDispatch, useAppDispatch, useAppSelector } from 'modules/redux'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { setDefaultCardCollapse } from 'modules/tiptap_editor/extensions/Card/CardCollapse'
import {
  selectIsEditingInSlideView,
  selectIsPresentingNestedCard,
  selectMode,
  setFollowingAttached,
  setMode,
} from 'modules/tiptap_editor/reducer'
import { useCan, useUserContext } from 'modules/user'
import { useEditorContext } from 'sections/docs/context'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButton, ToolbarIconButton } from './components/ToolbarButton'
import { MeetModal } from './MeetModal'

type ShortcutConfigType = {
  [key in EditorModeEnum]: {
    buttonLabel: string
    shortcutKeys: string
    shortcutLabel: string
    shortcutIcon: FontAwesomeIconProps['icon']
  }
}

const SHORTCUT_CONFIG: ShortcutConfigType = {
  [EditorModeEnum.DOC_VIEW]: {
    buttonLabel: 'Present',
    shortcutIcon: solid('play'),
    shortcutKeys: 'Mod+Enter',
    shortcutLabel: 'Switch to present view',
  },
  [EditorModeEnum.SLIDE_VIEW]: {
    buttonLabel: 'Exit',
    shortcutIcon: solid('stop'),
    shortcutKeys: 'Esc',
    shortcutLabel: 'Exit present view',
  },
}
const isEnterPresentMode = isHotkey('mod+Enter')
const isLeavePresentMode = isHotkey('Esc')
const isEnterPresentModeFullscreen = isHotkey('shift+mod+Enter')

type PresentBlockProps = {
  doc?: GetDocQuery['doc']
  isStaticEditor: boolean
  editorWrapperEl?: HTMLDivElement | null
  scrollingParentSelector: string
}

// Action creator to set the mode and attached as one action
const setModeAndAttach = (mode: EditorModeEnum) => {
  return function (dispatch: AppDispatch) {
    dispatch(setFollowingAttached({ attached: false }))
    dispatch(setMode({ mode }))
  }
}

const TOP_OFFSET = 100

/**
 * Grab the top center node and keep it scrolled into view using Spotlight's
 * scrollToNodeWithPin method. Used to "pin" an element during a DOC<>SLIDE transition.
 */
const scrollToTopCenterWithPin = (editor: Editor, parentSelector: string) => {
  const rootEl = document.querySelector(parentSelector)
  if (!rootEl) return
  const rootRect = rootEl.getBoundingClientRect()

  editor.commands.scrollToNodeWithPin(
    document.elementFromPoint(rootRect.width / 2, TOP_OFFSET) as HTMLElement,
    TOP_OFFSET
  )
}

export const PresentBlock = ({
  doc,
  isStaticEditor,
  editorWrapperEl,
  scrollingParentSelector,
}: PresentBlockProps) => {
  const {
    onOpen: onMeetModalOpen,
    onClose: onMeetModalClose,
    isOpen: isMeetModalOpen,
  } = useModalDisclosure({ id: 'meetModal' })
  const { user } = useUserContext()
  const reduxDispatch = useAppDispatch()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [exitedFullscreenByClick, setExitedFullscreenByClick] = useState(false)
  const { editor } = useEditorContext()
  const editorMode = useAppSelector(selectMode)
  const editorModeRef = useRef<EditorModeEnum>(editorMode)
  useEffect(() => {
    editorModeRef.current = editorMode
  }, [editorMode])
  const isPresentingNestedCard = useAppSelector(selectIsPresentingNestedCard)
  const isEditingInSlideView = useAppSelector(selectIsEditingInSlideView)
  const isAnyModalOpen = useAppSelector(selectIsAnyModalOpen)
  const { shortcutLabel, shortcutIcon, shortcutKeys, buttonLabel } =
    SHORTCUT_CONFIG[editorMode]
  const accessLinkUrl = doc?.accessLinks?.[0]?.url
  const isMeetLinkPossible = Boolean(doc?.accessLinks?.[0]?.permission)
  const canManage = useCan('manage', doc || undefined)
  const analytics = useAnalytics()

  const handleSetMode = useCallback(
    (mode: EditorModeEnum) => {
      if (!editor || editor.isDestroyed) return
      // YOU ("localCollaborator") changed DOC<>SLIDE mode
      // Turn on/off our spotlight accordingly
      // If we're exiting slide view, we need to keep the top center
      // node pinned during transition.
      if (mode === EditorModeEnum.SLIDE_VIEW) {
        setDefaultCardCollapse(editor.state, reduxDispatch)
        editor.commands.spotlightCurrentCard()
      } else {
        editor.commands.turnOffSpotlight(true)
        scrollToTopCenterWithPin(editor, scrollingParentSelector)
      }
      reduxDispatch(setModeAndAttach(mode))
    },
    [reduxDispatch, editor, scrollingParentSelector]
  )

  const enterFullscreen = useCallback(() => {
    try {
      // Chrome + others
      if (document.body?.requestFullscreen) {
        document.body?.requestFullscreen()
        return
      }

      // Safari desktop and Safari iPad
      // "Only available on iPad, not on iPhone. Shows an overlay button which can not be disabled."
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullscreen#browser_compatibility
      // @ts-ignore
      if (document.body?.webkitRequestFullscreen) {
        // @ts-ignore
        document.body?.webkitRequestFullscreen()
      }
    } catch (e) {
      // Entering present mode on Safari mobile on an iPhone won't work.
      console.debug('[PresentBlock] Error entering fullscreen:', e)
    }
  }, [])

  const exitFullscreen = useCallback(() => {
    try {
      // Chrome + others
      if (document.fullscreenElement) {
        document.exitFullscreen()
        return
      }
      // Safari
      if (
        // @ts-ignore
        document.webkitCurrentFullScreenElement &&
        // @ts-ignore
        document.webkitExitFullscreen
      ) {
        // @ts-ignore
        document.webkitExitFullscreen()
      }
    } catch (e) {
      console.debug('[PresentBlock] Error exiting fullscreen:', e)
    }
  }, [])

  // Handlers for entering and exiting and slide view
  const metricsEnabled = useFeatureFlag('realUserMetrics')
  const { recordExitSlideModePerformance, recordEnterSlideModePerformance } =
    useMeasureModeChangePerformance(metricsEnabled)

  const enterSlideView = useCallback(
    ({ fullscreen = false }: { fullscreen: boolean }) => {
      if (editorModeRef.current === EditorModeEnum.SLIDE_VIEW) {
        return
      }
      handleSetMode(EditorModeEnum.SLIDE_VIEW)
      if (fullscreen) enterFullscreen()

      analytics?.trackDocEvent(SegmentEvents.PRESENT_MODE_ENTERED)
      recordEnterSlideModePerformance({ isFullscreen: fullscreen })
    },
    [
      handleSetMode,
      recordEnterSlideModePerformance,
      enterFullscreen,
      editorModeRef,
      analytics,
    ]
  )

  const exitSlideView = useCallback(() => {
    if (editorModeRef.current === EditorModeEnum.DOC_VIEW) {
      return
    }
    handleSetMode(EditorModeEnum.DOC_VIEW)
    if (isFullscreen) exitFullscreen()

    analytics?.trackDocEvent(SegmentEvents.PRESENT_MODE_EXITED)
    recordExitSlideModePerformance({ isFullscreen })
  }, [
    handleSetMode,
    isFullscreen,
    exitFullscreen,
    recordExitSlideModePerformance,
    editorModeRef,
    analytics,
  ])

  useEffect(() => {
    const onToggleFullScreen = () => {
      // When you hit Esc while in fullscreen (which triggers the browser's native exit full screen)
      // document.fullscreenElement gets set to null.
      // We'll piggyback on this to exit slide view.
      if (
        // Chrome
        document.fullscreenElement === null &&
        !exitedFullscreenByClick
      ) {
        exitSlideView()
      }

      if (
        // Safari
        // @ts-ignore
        document.webkitCurrentFullScreenElement === null &&
        !exitedFullscreenByClick
      ) {
        exitSlideView()
      }
      setIsFullscreen((prev) => !prev)
      setExitedFullscreenByClick(false)
    }
    document.addEventListener('fullscreenchange', onToggleFullScreen)
    document.addEventListener('webkitfullscreenchange', onToggleFullScreen)
    document.addEventListener('mozfullscreenchange', onToggleFullScreen)
    return () => {
      document.removeEventListener('fullscreenchange', onToggleFullScreen)
      document.removeEventListener('webkitfullscreenchange', onToggleFullScreen)
      document.removeEventListener('mozfullscreenchange', onToggleFullScreen)
    }
  }, [exitedFullscreenByClick, exitSlideView, exitFullscreen])

  useEffect(() => {
    const keydownListener = (e) => {
      if (isAnyModalOpen) {
        return
      }
      if (isEnterPresentMode(e)) {
        if (e?.target?.closest('[data-gamma-child-tiptap-editor]')) {
          return
        }
        e.preventDefault()
        enterSlideView({ fullscreen: false })
        return true
      }
      if (isEnterPresentModeFullscreen(e)) {
        if (e?.target?.closest('[data-gamma-child-tiptap-editor]')) return
        e.preventDefault()
        enterSlideView({ fullscreen: true })
        return true
      }
      if (
        isLeavePresentMode(e) &&
        !isPresentingNestedCard &&
        !isEditingInSlideView &&
        editorModeRef.current === EditorModeEnum.SLIDE_VIEW
      ) {
        if (e?.target?.closest('[data-gamma-child-tiptap-editor]')) return
        exitSlideView()
        return true
      }
      return
    }
    // TODO: Figure out how to scope this listener in a way that it
    // doesnt respond if either of the Panels is focused
    return keyboardHandler.on('keydown', 'PRESENT_BLOCK', keydownListener)
  }, [
    enterSlideView,
    exitSlideView,
    enterFullscreen,
    isPresentingNestedCard,
    isEditingInSlideView,
    editorWrapperEl,
    isAnyModalOpen,
  ])

  return (
    <Flex>
      <ButtonGroup isAttached size="sm">
        <GammaTooltip
          closeOnMouseDown={false}
          closeOnClick={false}
          label={shortcutLabel}
          shortcut={shortcutKeys}
        >
          <ToolbarButton
            fontWeight="normal"
            size="sm"
            variant="solid"
            onClick={() => {
              editorMode === EditorModeEnum.SLIDE_VIEW
                ? exitSlideView()
                : enterSlideView({ fullscreen: false })
            }}
            onMouseDown={preventDefaultToAvoidBlur}
            icon={<FontAwesomeIcon icon={shortcutIcon} />}
          >
            {buttonLabel}
          </ToolbarButton>
        </GammaTooltip>
        <Menu>
          <MenuButton
            variant="solid"
            as={ToolbarIconButton}
            icon={<FontAwesomeIcon icon={regular('chevron-down')} />}
          />
          <Portal>
            <MenuList zIndex="popover">
              {editorMode === EditorModeEnum.DOC_VIEW && (
                <MenuGroup title="Switch to present view">
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('window')} fixedWidth />
                    }
                    command={getShortcutTextForOS('Mod+Enter')}
                    onClick={() => enterSlideView({ fullscreen: false })}
                  >
                    In this tab
                  </MenuItem>
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('desktop')} fixedWidth />
                    }
                    command={getShortcutTextForOS('Mod+Shift+Enter')}
                    onClick={() => enterSlideView({ fullscreen: true })}
                  >
                    Full screen
                  </MenuItem>
                </MenuGroup>
              )}
              {editorMode === EditorModeEnum.SLIDE_VIEW && (
                <MenuItem
                  icon={<FontAwesomeIcon icon={solid('stop')} fixedWidth />}
                  command="ESC"
                  onClick={exitSlideView}
                >
                  Exit present view
                </MenuItem>
              )}
              {!isStaticEditor && user && canManage && (
                <>
                  <MenuDivider />
                  <GammaTooltip
                    isDisabled={isMeetLinkPossible}
                    label={
                      'Enable access in the share panel to create a follow link'
                    }
                  >
                    <Box>
                      <MenuItem
                        onClick={() => {
                          if (doc?.accessLinks?.[0].permission) {
                            onMeetModalOpen()
                          }
                        }}
                        isDisabled={!isMeetLinkPossible}
                        icon={
                          <FontAwesomeIcon
                            icon={regular('screen-users')}
                            fixedWidth
                          />
                        }
                      >
                        <Stack spacing="0">
                          <Text>Share a follow link</Text>
                          <Text fontSize="sm" color="gray.600">
                            People who join will follow you automatically
                          </Text>
                        </Stack>
                      </MenuItem>
                    </Box>
                  </GammaTooltip>
                </>
              )}
              {!isStaticEditor && user && accessLinkUrl && (
                <MeetModal
                  isOpen={isMeetModalOpen}
                  onClose={onMeetModalClose}
                  accessLinkUrl={accessLinkUrl}
                  onPlayClick={() => {
                    enterSlideView({ fullscreen: false })
                    // should we set the URL to the meet link url? this is probably what people will try to share around
                  }}
                />
              )}
            </MenuList>
          </Portal>
        </Menu>
      </ButtonGroup>
      {editorMode === EditorModeEnum.SLIDE_VIEW && (
        <Flex ml={editorMode === EditorModeEnum.SLIDE_VIEW ? 2 : 0}>
          <GammaTooltip
            label={isFullscreen ? 'Exit full screen' : 'Enter full screen'}
          >
            <ToolbarIconButton
              aria-label={
                isFullscreen ? 'Exit full screen' : 'Enter full screen'
              }
              borderRadius="md"
              icon={
                <FontAwesomeIcon
                  icon={isFullscreen ? regular('compress') : regular('expand')}
                />
              }
              onClick={
                isFullscreen
                  ? () => {
                      setExitedFullscreenByClick(true)
                      exitFullscreen()
                    }
                  : enterFullscreen
              }
            />
          </GammaTooltip>
        </Flex>
      )}
    </Flex>
  )
}
