import { Box, BoxProps, Center } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { Editor, Extension } from '@tiptap/core'
import { motion, AnimatePresence } from 'framer-motion'
import merge from 'lodash/merge'
import React, { Suspense, useEffect, useState } from 'react'

import { useGetSnapshotQuery } from 'modules/api'
import { getStore, useAppSelector } from 'modules/redux'
import { EditorCore } from 'modules/tiptap_editor'
import { Spotlight } from 'modules/tiptap_editor/extensions/spotlight'
import {
  setCollaborators,
  setLocalCollaboratorId,
  Collaborator,
} from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'

import { selectDoc } from '../../reducer'
import { MadeWithGamma } from './MadeWithGamma'

const localSessionId = 'sessionId1'

const MotionBox = motion<BoxProps>(Box)
const LottieComponent = React.lazy(() =>
  import('modules/lottie').then((module) => ({
    default: module.LottieComponent,
  }))
)

const CursorExtension = Extension.create<{}, { user: Partial<Collaborator> }>({
  addStorage() {
    return {
      user: {},
    }
  },
  addCommands() {
    return {
      user: (attributes: Partial<Collaborator>) => () => {
        // A short circuit implementation of the actual CollaborationCursor extension's .user command
        // Just updates redux with the new values for the single user.
        const store = getStore()
        this.storage.user = merge({}, this.storage.user, attributes)

        store.dispatch(
          setLocalCollaboratorId({
            sessionId: localSessionId,
          })
        )
        store.dispatch(
          setCollaborators({
            collaborators: [this.storage.user] as Collaborator[],
          })
        )
        return true
      },
    }
  },
})

/**
 * This is a non-collaborative (no Hocuspocus) version of the DocEditor that loads the
 * data for a snapshot instead. We pull in a couple extra extensions to support present mode
 * because the present mode state is coupled to the YJS awareness bus at the moment.
 */

export const PublicStatic = ({
  docId,
  snapshotId,
  scrollingParentSelector,
  onCreate,
}) => {
  const [extensions] = useState(() => [
    Spotlight.configure({ scrollerSelector: scrollingParentSelector }),
    CursorExtension,
  ])
  const { user, isUserLoading, anonymousUser, color } = useUserContext()
  const [editor, setEditor] = useState<Editor>()
  const [isLottieReady, setLottieReady] = useState(false)
  const { data, loading, error } = useGetSnapshotQuery({
    variables: { docId, snapshotId },
    skip: !snapshotId,
  })
  const doc = useAppSelector(selectDoc)

  useEffect(() => {
    // NOTE: If you update this hook, make sure that you update the corresponding
    // hook in ExampleStatic.
    if (isUserLoading || !editor) return

    editor.commands.user({
      id: user?.id || anonymousUser.id,
      color: color.value,
      sessionId: localSessionId,
      idleSince: null,
      name: user?.displayName || anonymousUser.displayName || '',
      profileImageUrl: user?.profileImageUrl || '',
      isReady: !isUserLoading,
    })
  }, [editor, user, color, isUserLoading, anonymousUser])

  useEffect(() => {
    setTimeout(() => {
      setLottieReady(true)
    }, 1500)
  }, [])

  const isReady =
    data && data.snapshot !== undefined && !loading && isLottieReady && doc

  return (
    <Box data-testid="public-static-editor-wrapper" flex="1">
      <AnimatePresence>
        {!isReady && (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: '0.5' }}
            flex="1"
            position="absolute"
            bg="gray.100"
            data-testid="current-snapshot-editor-skeleton"
            mt={1}
            inset={0}
            zIndex={999}
          >
            <Center h="100%">
              <Suspense fallback={<></>}>
                <MotionBox
                  initial={{ transform: 'scale(1)' }}
                  animate={{ transform: 'scale(1)' }}
                  exit={{ transform: 'scale(1.5)' }}
                  transition={{ ease: 'easeOut', duration: '0.5' }}
                >
                  <LottieComponent
                    w="30vw"
                    h="30vw"
                    lottie="logoCube"
                    playerProps={{
                      speed: 2.8,
                      loop: false,
                    }}
                    wrapperProps={{}}
                    onCreate={() => {
                      // Placeholder in case we wanna try reversing
                      // const onComplete = () => {
                      //   // Play the animation in reverse, once.
                      //   instance.setDirection(-1)
                      //   instance.setSpeed(4)
                      //   instance.play()
                      //   instance.removeEventListener('complete', onComplete)
                      // }
                      // instance.addEventListener('complete', onComplete)
                    }}
                  />
                </MotionBox>
              </Suspense>
            </Center>
          </MotionBox>
        )}
      </AnimatePresence>
      {isReady && error ? (
        <Box
          flexDirection="column"
          height="100%"
          flex="1"
          bg="gray.100"
          alignItems="center"
        >
          <Center h="100%">
            There was an error loading the {DOC_DISPLAY_NAME}: {error.message}
          </Center>
        </Box>
      ) : (
        <Box
          transitionProperty="common"
          transitionDuration="750ms"
          transitionTimingFunction="ease-out"
          opacity={isReady ? 1 : 0}
        >
          {isReady ? (
            <EditorCore
              // @ts-ignore G-440/fix-typescript-issue-where-certain-fields-arent-needed
              doc={doc}
              docId={docId}
              extensions={extensions}
              onCreate={({ editor: editorInstance }) => {
                setEditor(editorInstance)
                // A timeout here gives the editor nodeview components
                // a chance to render before we remove the skeleton
                setTimeout(() => {
                  // Notify the parent that the editor has been created, in case it also needs
                  // access to the editor instance so that it can run editor commands
                  if (onCreate) {
                    onCreate(editorInstance)
                  }
                }, 10)
              }}
              readOnly={true}
              initialContent={data.snapshot?.content.default}
              scrollingParentSelector={scrollingParentSelector}
            />
          ) : null}
        </Box>
      )}
      <MadeWithGamma />
    </Box>
  )
}
