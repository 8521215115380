import { Box, Stack } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { AnimatePresence, motion } from 'framer-motion'

import { Comment, Doc, User } from 'modules/api'
import { CommentBox } from 'modules/tiptap_editor/extensions/Annotatable/components/Comments/Comment'

type CommentNotificationListProps = {
  commentFeedItems: Comment[]
  editor?: Editor
  user?: User
  doc: Doc
}

const MotionBox = motion(Box)

export const CommentNotificationList = ({
  commentFeedItems,
  editor,
  doc,
  user,
}: CommentNotificationListProps) => {
  if (!editor || !user) return null

  return (
    <Stack p={2}>
      <AnimatePresence initial={false}>
        {commentFeedItems.map((comment) => {
          return (
            <MotionBox
              key={comment.id}
              layout
              initial={{ opacity: 0, scale: 0.85 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.85 }}
              bg="gray.100"
              borderColor="gray.200"
              borderWidth="1px"
              borderRadius="md"
              shadow="sm"
            >
              <CommentBox
                key={comment.id}
                comment={comment}
                editor={editor}
                selfUser={user}
                docId={doc.id}
                userCanComment={false}
                instanceType="notification"
              />
            </MotionBox>
          )
        })}
      </AnimatePresence>
    </Stack>
  )
}
