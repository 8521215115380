import { Button, Stack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CustomThemeEmptyState = ({ openThemeEditor }) => {
  return (
    <Stack
      bg="gray.100"
      p={4}
      textAlign="left"
      spacing={4}
      borderRadius="md"
      my={2}
    >
      <Text textAlign="center" fontWeight="700">
        Make it yours, in seconds
      </Text>
      <Text fontSize="sm" color="gray.700">
        Create a beautiful, branded theme. Just pick your fonts, color, and
        background, and upload your logo!
      </Text>
      <Button
        onClick={openThemeEditor}
        variant="solid"
        leftIcon={<FontAwesomeIcon icon={regular('sparkles')} />}
        data-testid="new-theme-button"
      >
        New custom theme
      </Button>
    </Stack>
  )
}
