import {
  Button,
  ButtonGroup,
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CHANNEL_DISPLAY_NAME,
  CHANNEL_DISPLAY_NAME_PLURAL,
  DOC_DISPLAY_NAME,
} from '@gamma-app/ui'
import isNil from 'lodash/isNil'
import { useCallback, useState } from 'react'

import {
  Channel,
  Doc,
  Permission,
  useUpdateDocOrgAccessMutation,
} from 'modules/api'
import { useAbility, useCan, useUserContext } from 'modules/user'

import { useUpdatePublicChannels } from '../useUpdatePublicChannels'
import { isOtherOrgDoc } from '../utils'
import { PermissionsSettingsRow } from './SharePanel'

export const WorkspacePermissionsPrompt = ({
  onClose,
  onSubmit,
  doc,
}: {
  onClose: () => void
  onSubmit: (orgAccess: Permission) => void
  doc: Doc | null
}) => {
  const canManage = useCan('manage', doc || undefined)
  const [orgAccess, setOrgAccess] = useState<Permission>(Permission.Manage)
  if (!doc) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent data-testid="workspace-permissions-prompt">
        <ModalHeader>Set workspace permissions for {doc.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={6}>
            Everyone in your workspace will get the permissions you specify
            below, and you'll be able to add it to {CHANNEL_DISPLAY_NAME_PLURAL}
            .
          </Text>

          <PermissionsSettingsRow
            testId={'workspace'}
            title={'Workspace members'}
            subtitle={`Everyone in ${doc?.organization?.name}`}
            isDisabled={!canManage}
            img={
              <Circle
                color="gray.800"
                textAlign="center"
                bg="gray.200"
                size="32px"
              >
                <FontAwesomeIcon size="1x" icon={regular('building')} />
              </Circle>
            }
            selected={orgAccess}
            options={[
              Permission.Manage,
              Permission.Edit,
              Permission.Comment,
              Permission.View,
            ]}
            onClick={(option: Permission) => {
              setOrgAccess(option)
            }}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              data-testid="workspace-permissions-submit"
              onClick={() => {
                onSubmit(orgAccess)
                onClose()
              }}
            >
              Done
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

/**
 * Hook to manage adding a doc to a Channel, which includes:
 * 1a. Validating that the user has permission
 * 1b. Validating that the doc is not already in the Channel
 * 2.  Ensuring the doc has workspace access (prompting if not)
 * 3.  Adding the doc to the channel
 *
 * Use in conjunction with the WorkspacePermissionsPrompt component above
 */
export const useAddDocToChannel = () => {
  const { onOpen, isOpen, onClose } = useDisclosure({
    id: 'workspacePermissionPromptDisclosure',
  })

  const [updateDocOrgAccessMutation] = useUpdateDocOrgAccessMutation()
  const { currentWorkspace } = useUserContext()
  const toast = useToast()
  const { addDocChannel } = useUpdatePublicChannels()
  const [tempDoc, setTempDoc] = useState<Doc | null>(null)
  const [targetChannel, setTargetChannel] = useState<Channel | null>(null)
  const ability = useAbility()

  const validateBeforeDrop = useCallback(
    (doc: Doc, channel: Channel) => {
      const isAlreadyInChannel = (doc.channels || []).find(
        (c) => c.id === channel.id
      )
      const isFromAnotherWorkspace = isOtherOrgDoc(doc, currentWorkspace)
      const cannotUpdateOrgAccess =
        ability.cannot('manage', doc) && isNil(doc.orgAccess)

      if (isFromAnotherWorkspace) {
        toast({
          description: `You don't have permission to add a ${DOC_DISPLAY_NAME} from another workspace to a ${CHANNEL_DISPLAY_NAME}.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
        return false
      }
      if (cannotUpdateOrgAccess) {
        toast({
          description: `You don't have permission to add this ${DOC_DISPLAY_NAME} to a ${CHANNEL_DISPLAY_NAME}.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
        return false
      }
      if (isAlreadyInChannel) {
        toast({
          description: `This ${DOC_DISPLAY_NAME} is already in this ${CHANNEL_DISPLAY_NAME}.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
        return false
      }
      return true
    },
    [ability, toast, currentWorkspace]
  )

  const updateWorkspacePermissionThenAddToChannel = useCallback(
    (orgAccess: Permission) => {
      if (!tempDoc || !targetChannel) return

      const { id } = tempDoc
      const variables = { id, orgAccess }
      updateDocOrgAccessMutation({
        variables: {
          id,
          orgAccess,
        },
        optimisticResponse: {
          updateDoc: { ...variables, __typename: 'Doc' },
        },
      })
        .then(() => {
          addDocChannel({
            doc: tempDoc,
            channel: targetChannel,
            existingChannels: tempDoc?.channels,
          })
        })
        .catch((e) => {
          console.error(
            '[useAddDocToChannel.updatedocOrgAccess] Error setting doc orgAccess for doc id',
            id
          )
          toast({
            title: 'Error',
            description: `There was an error updating workspace access: ${e.message}`,
            status: 'error',
          })
        })
    },
    [addDocChannel, tempDoc, targetChannel, toast, updateDocOrgAccessMutation]
  )

  const handleAddDocToChannel = useCallback(
    (doc: Doc, channel: Channel) => {
      const validDrop = validateBeforeDrop(doc, channel)
      if (!validDrop) return
      if (doc.orgAccess) {
        addDocChannel({ doc, channel, existingChannels: doc?.channels || [] })
      } else {
        setTempDoc(doc)
        setTargetChannel(channel)
        onOpen()
      }
    },
    [addDocChannel, onOpen, validateBeforeDrop]
  )

  const handleClose = useCallback(() => {
    setTargetChannel(null)
    setTempDoc(null)
    onClose()
  }, [onClose])

  return {
    isOpen,
    onClose: handleClose,
    doc: tempDoc,
    handleAddDocToChannel,
    updateWorkspacePermissionThenAddToChannel,
  }
}
