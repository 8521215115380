import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { useCallback } from 'react'

import { useGetThemesQuery } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { Theme } from 'modules/theming'
import { useUserContext } from 'modules/user'

import { CustomThemeEmptyState } from './CustomThemeEmptyState'
import { ThemeSkeleton } from './DocThemeDrawer'
import { ThemeList } from './ThemeList'

export type ThemePanelProps = {
  currentTheme: Theme
  setDocTheme: (newTheme: Theme) => void
  openThemeEditor: (themeToEdit?: Theme) => void
}

export const ThemePanel = ({
  currentTheme,
  setDocTheme,
  openThemeEditor,
}: ThemePanelProps) => {
  // And this lets you make your own themes
  const themeEditorEnabled = useFeatureFlag('themeEditor')

  const { currentWorkspace } = useUserContext()
  const { data: workspaceData, loading: loadingWorkspaceThemes } =
    useGetThemesQuery({
      variables: {
        workspaceId: currentWorkspace?.id,
        archived: false,
      },
      skip: !currentWorkspace,
    })
  const { data: globalData, loading: loadingGlobalThemes } = useGetThemesQuery({
    variables: {
      // @ts-ignore
      workspaceId: null,
      archived: false,
    },
  })

  const workspaceThemes = workspaceData?.themes || []
  const globalThemes = globalData?.themes || []

  const openThemeEditorWithNewTheme = useCallback(() => {
    openThemeEditor()
  }, [openThemeEditor])

  const hasWorkspaceThemes = workspaceThemes?.length > 0

  return (
    <>
      {currentTheme.archived ? (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <Box>
            <AlertTitle>
              This {DOC_DISPLAY_NAME} is using a deleted theme.
            </AlertTitle>
            <AlertDescription>
              You can pick a new one, but you won't be able to go back once you
              do.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {themeEditorEnabled ? (
        <>
          <HStack>
            <Text color="gray.500" fontWeight="600" fontSize="sm">
              Custom themes
            </Text>
            <Spacer />
            {hasWorkspaceThemes && currentWorkspace && (
              <Button
                size="xs"
                leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
                onClick={openThemeEditorWithNewTheme}
                data-testid="new-theme-button"
              >
                New theme
              </Button>
            )}
          </HStack>
          {loadingWorkspaceThemes ? (
            <ThemeSkeleton size={2} />
          ) : hasWorkspaceThemes ? (
            <ThemeList
              themes={workspaceThemes}
              currentTheme={currentTheme}
              setDocTheme={setDocTheme}
              openThemeEditor={openThemeEditor}
            />
          ) : currentWorkspace ? (
            <CustomThemeEmptyState
              openThemeEditor={openThemeEditorWithNewTheme}
            />
          ) : null}
          <HStack pt={2}>
            <Text color="gray.500" fontWeight="600" fontSize="sm">
              Standard themes
            </Text>
            <Spacer />
          </HStack>
        </>
      ) : null}
      {loadingGlobalThemes ? (
        <ThemeSkeleton size={4} />
      ) : (
        <ThemeList
          themes={globalThemes}
          currentTheme={currentTheme}
          setDocTheme={setDocTheme}
          openThemeEditor={openThemeEditor}
        />
      )}
    </>
  )
}
