import { Box, BoxProps, Button, Flex, Image, Link } from '@chakra-ui/react'

import LogoGradient from 'publicImages/GammaLogoGradient.svg'
import { isMobileDevice } from 'utils/deviceDetection'

const GAMMA_URL = 'https://gamma.app'

const getPositionProps = (): BoxProps => {
  if (isMobileDevice) {
    return {
      display: 'flex',
      justifyContent: 'center',
      m: 6,
      position: 'relative',
    }
  }
  return {
    position: 'fixed',
    right: [1, 6],
    bottom: [1, 2],
  }
}
export const MadeWithGamma = () => {
  return (
    <Flex {...getPositionProps()}>
      <Button
        as={Link}
        variant="plain"
        target="_blank"
        href={GAMMA_URL}
        textDecoration="none !important"
        leftIcon={<Image src={LogoGradient.src} h="1em" />}
        size="sm"
      >
        Made with Gamma
      </Button>
    </Flex>
  )
}
