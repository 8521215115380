import {
  Flex,
  HStack,
  Link,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { undo } from '@gamma-app/y-prosemirror'
import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { NodeViewProps } from '@tiptap/core'
import copy from 'copy-to-clipboard'
import { forwardRef, useCallback } from 'react'

import { GammaMenuList } from 'gamma_components/Menu/Menu'
import { GammaMenuDivider, GammaMenuItem } from 'gamma_components/Menu/MenuItem'
import { selectCard } from 'modules/cards/reducer'
import { useAppSelector, useAppStore } from 'modules/redux'
import { VaultEventEmitter } from 'modules/tiptap_editor/components/menus/InsertWidget/vault'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { useEditorContext } from 'sections/docs'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { CardAttributes } from '..'
import { getSerializedNodeAtPos } from '../../Clipboard/utils'

export type ManageCardMenuOptions = {
  cardUrl: string
  getPos: () => number | null
  // These can be undefined when called from the TOC
  openStyleDrawer?: (index: number) => void
  isNested?: boolean
  isCollapsed?: boolean
  isFirstCard?: boolean
  hasCardBackground?: boolean
  previewContent?: CardAttributes['previewContent']
  updateAttributes?: NodeViewProps['updateAttributes']
}

type ManageCardMenuProps = {
  onClose?: () => void
  style: any
} & ManageCardMenuOptions

const iconVault = <FontAwesomeIcon icon={regular('box-archive')} fixedWidth />
const iconBrush = <FontAwesomeIcon icon={regular('brush')} fixedWidth />
const iconDuplicate = <FontAwesomeIcon icon={regular('clone')} fixedWidth />
const iconCopy = <FontAwesomeIcon icon={regular('paste')} fixedWidth />
const iconLink = <FontAwesomeIcon icon={regular('link')} fixedWidth />
const iconTrash = <FontAwesomeIcon icon={regular('trash')} fixedWidth />
const iconMergePrevious = (
  <FontAwesomeIcon icon={regular('arrow-up')} fixedWidth />
)
const iconMergeParent = (
  <FontAwesomeIcon icon={regular('arrow-up-left')} fixedWidth />
)
const iconFillDrip = <FontAwesomeIcon icon={regular('fill-drip')} fixedWidth />
const iconShowPreview = (
  <FontAwesomeIcon icon={regular('align-justify')} fixedWidth />
)
const iconHidePreview = (
  <FontAwesomeIcon icon={regular('align-slash')} fixedWidth />
)

// Rendering Chakra Menu components is expensive, even if the children dont change
// One of these will exist for every Card in the memo, so memoize this component
export const ManageCardMenu = forwardRef<HTMLDivElement, ManageCardMenuProps>(
  function ManageCardMenu(
    {
      openStyleDrawer,
      cardUrl,
      getPos,
      isNested,
      isFirstCard,
      isCollapsed,
      hasCardBackground,
      onClose,
      updateAttributes,
      previewContent,
      ...rest
    },
    ref
  ) {
    console.debug(
      '%c [<ManageCardMenu />] RENDER',
      'background-color: limegreen'
    )

    const { getCollaborativeEditorInstance } = useEditorContext()
    const editor = getCollaborativeEditorInstance()
    const toast = useToast()

    const togglePreview = useCallback(() => {
      if (!updateAttributes) return
      // Set previewContent to empty string to hide it and show "view more" instead
      // Reset to null to show default
      updateAttributes({ previewContent: previewContent === null ? '' : null })
    }, [updateAttributes, previewContent])
    const store = useAppStore()

    const deleteCard = useCallback(() => {
      const pos = getPos()
      if (!pos || !editor) {
        return
      }
      // Try to get the card title. `node`'s content may not be up to date,
      // so we fetch it using the pos right at delete time
      const nodeNow = editor.state.doc.nodeAt(pos)
      const title = nodeNow && getCardTitle(nodeNow.toJSON())
      const displayTitle = title ? `"${title}"` : 'card'
      editor.chain().deleteCard(pos).focus().run()

      const toastId = toast({
        title: (
          <HStack>
            <Text noOfLines={1}>Deleted {displayTitle}. </Text>
            <Link
              textDecoration="underline"
              onClick={() => {
                undo(editor.state)
                toast.close(toastId)
              }}
            >
              Undo
            </Link>
          </HStack>
        ),
        status: 'info',
        duration: 3000,
        position: 'top',
      })
    }, [editor, getPos, toast])

    const moveCardToVault = useCallback(() => {
      const pos = getPos()
      if (!pos) {
        return
      }
      const cardNode = editor?.state.doc.nodeAt(pos)
      if (!cardNode || !isCardNode(cardNode)) return
      const card = selectCard(cardNode.attrs.id)(store.getState())

      VaultEventEmitter.emit('moveCardToVault', {
        cardData: cardNode.toJSON(),
        cardPreviewUrl: card?.previewUrl,
        cardTitle: card?.title,
      })
      // Use delayed focus after deleting because for some reason
      // nodeviews appear to error if you mutate them and immediately
      // call the built in .focus()
      editor?.chain().deleteCard(pos).focusDelayed().run()
    }, [editor, getPos, store])

    const duplicateCard = useCallback(() => {
      const pos = getPos()
      if (!pos) {
        return
      }
      editor?.commands.duplicateCard(pos)
    }, [editor, getPos])

    const mergeIntoParent = useCallback(() => {
      const pos = getPos()
      if (!pos) {
        return
      }

      editor?.commands.unnestCard(pos)
    }, [editor, getPos])

    const mergeIntoPrevious = useCallback(() => {
      const pos = getPos()
      if (!pos) {
        return
      }

      editor?.chain().mergeCardsAtPos(pos).focus().run()
    }, [editor, getPos])

    const openBackgroundTab = useCallback(() => {
      openStyleDrawer!(0)
    }, [openStyleDrawer])
    const openStylesTab = useCallback(() => {
      openStyleDrawer!(1)
    }, [openStyleDrawer])

    const editable = useAppSelector(selectEditable)
    const { onCopy: onCopyLink } = useClipboard(cardUrl)
    const deleteColor = useColorModeValue('red.500', 'red.300')
    const onCopyLinkClick = useCallback(() => {
      onCopyLink()
      toast({
        title: 'URL copied.',
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    }, [onCopyLink, toast])

    const onCopyCardClick = useCallback(() => {
      const pos = getPos()
      if (!pos || !editor) return
      const html = getSerializedNodeAtPos(editor, pos)
      if (!html) {
        toast({
          title: 'Error copying card.',
          status: 'error',
          duration: 3000,
          position: 'top',
        })
        return
      }
      copy(html, { format: 'text/html' })
      toast({
        title: 'Card copied.',
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    }, [editor, getPos, toast])

    return (
      <GammaMenuList
        {...rest}
        zIndex="popover"
        ref={ref}
        onClick={onClose}
        data-testid="manage-card-menu"
      >
        <GammaMenuItem
          icon={iconLink}
          onClick={onCopyLinkClick}
          data-testid="copy-card-link"
        >
          Copy link
        </GammaMenuItem>
        <GammaMenuItem icon={iconCopy} onClick={onCopyCardClick}>
          Copy card
        </GammaMenuItem>

        <Flex direction="column" display={editable ? 'flex' : 'none'}>
          <GammaMenuDivider />
          <GammaMenuItem
            icon={iconDuplicate}
            onClick={duplicateCard}
            data-testid="duplicate-card"
          >
            Duplicate
          </GammaMenuItem>
          {updateAttributes && isNested && isCollapsed && (
            <GammaMenuItem
              icon={previewContent === '' ? iconShowPreview : iconHidePreview}
              onClick={togglePreview}
              data-testid="hide-preview"
            >
              {previewContent === ''
                ? 'Show preview text'
                : 'Hide preview text'}
            </GammaMenuItem>
          )}
          {openStyleDrawer && hasCardBackground !== undefined && !isCollapsed && (
            <GammaMenuItem
              icon={iconFillDrip}
              onClick={openBackgroundTab}
              onMouseDown={preventDefaultToAvoidBlur}
              data-testid="card-background"
            >
              {hasCardBackground ? 'Change background' : 'Add background'}
            </GammaMenuItem>
          )}
          {openStyleDrawer && !isCollapsed && (
            <GammaMenuItem
              icon={iconBrush}
              onClick={openStylesTab}
              onMouseDown={preventDefaultToAvoidBlur}
              data-testid="card-style"
            >
              Change style
            </GammaMenuItem>
          )}
          {isNested ? (
            <GammaMenuItem
              icon={iconMergeParent}
              onClick={mergeIntoParent}
              data-testid="merge-into-parent-card"
            >
              Merge into parent card
            </GammaMenuItem>
          ) : isNested == false && !isFirstCard ? (
            <GammaMenuItem
              icon={iconMergePrevious}
              onClick={mergeIntoPrevious}
              data-testid="merge-into-card-above"
            >
              Merge into card above
            </GammaMenuItem>
          ) : null}
          <GammaMenuDivider />
          <GammaMenuItem
            icon={iconVault}
            onClick={moveCardToVault}
            data-testid="move-to-vault"
          >
            Move to Vault
          </GammaMenuItem>
          <GammaMenuItem
            icon={iconTrash}
            color={deleteColor}
            onClick={deleteCard}
            data-testid="delete-card"
          >
            Delete
          </GammaMenuItem>
        </Flex>
      </GammaMenuList>
    )
  }
)
