import {
  Avatar,
  HStack,
  Stack,
  StackProps,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { capitalize, GammaTooltip, normalizeDate } from '@gamma-app/ui'

import { DocEditor, MemoViewerStats } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useUserContext } from 'modules/user'
import { generateName } from 'utils/generators'

import { DisplayUser } from '../types'
import { formatCardsViewedTooltipText } from '../utils'
import { CardsViewedProgress } from './CardsViewedProgressBar'

const formatViewerLabelWithCount = (originalLabel: string, count: number) => {
  let prefix = count.toString()
  if (count === 0) {
    prefix = 'All '
  }
  const newLabel = count === 1 ? originalLabel : originalLabel + 's'
  return `${prefix} ${newLabel} (all time)`
}

type ViewersColumns = {
  [key: string]: {
    label: string
    getTooltipText?: (num?: number) => string
    width: string
  }
}

const VIEWERS_TABLE_COLUMNS: ViewersColumns = {
  uniqueViewers: {
    label: 'unique viewer',
    getTooltipText: () =>
      'Unique viewers as defined by IP address, who viewed this since it was created on',
    width: '40%',
  },
  lastOpened: {
    label: 'last opened',
    width: '35%',
  },
  cardsViewed: {
    label: 'cards viewed',
    getTooltipText: formatCardsViewedTooltipText,
    width: '25%',
  },
}

const TableHeader = ({
  columns,
  uniqueViewers,
  docCreatedDate,
}: {
  columns: ViewersColumns
  uniqueViewers: number
  docCreatedDate: string
}) => {
  const CARD_VIEWED_THRESHOLD_IN_MS = useFeatureFlag('cardViewedThreshold')
  return (
    <Thead>
      <Tr>
        {Object.entries(columns).map(
          ([key, { label, getTooltipText, width }]) => {
            const tooltipText =
              getTooltipText && getTooltipText(CARD_VIEWED_THRESHOLD_IN_MS)
            return (
              <TableHeaderItem
                key={key}
                columnName={key}
                docCreatedDate={docCreatedDate}
                tooltipText={tooltipText}
                width={width}
                label={label}
                uniqueViewers={uniqueViewers}
              />
            )
          }
        )}
      </Tr>
    </Thead>
  )
}

type TableHeaderItemProps = {
  columnName: string
  label: string
  docCreatedDate: string
  uniqueViewers: number
  width: string
  tooltipText?: string
}
const TableHeaderItem = ({
  columnName,
  label,
  docCreatedDate,
  uniqueViewers,
  width,
  tooltipText,
}: TableHeaderItemProps) => {
  let tooltipLabel = tooltipText
  if (columnName === 'uniqueViewers') {
    tooltipLabel += ` ${docCreatedDate}.`
  }
  const InnerHeaderItem = (
    <Th w={width}>
      <Text
        display="inline"
        borderBottom={tooltipText ? '0.125em dashed' : undefined}
        borderBottomColor={tooltipText ? 'gray.400' : undefined}
      >
        {columnName === 'uniqueViewers'
          ? formatViewerLabelWithCount(label, uniqueViewers)
          : capitalize(label)}
      </Text>
    </Th>
  )
  // If no tooltip is provided, then just render the header without tooltips
  if (!tooltipText) return InnerHeaderItem
  return (
    <GammaTooltip placement="top" label={tooltipLabel}>
      {InnerHeaderItem}
    </GammaTooltip>
  )
}

type ViewersTableProps = {
  viewers: MemoViewerStats[]
  currentViewerFilterCount: number
  docCreatedDate: string
  creatorId: string
  contributors: DocEditor[]
  cardCount: number
  handleTableRowClick: (user: DisplayUser) => void
}

export const ViewersTable = ({
  viewers,
  currentViewerFilterCount,
  docCreatedDate,
  creatorId,
  contributors,
  cardCount,
  handleTableRowClick,
}: ViewersTableProps) => {
  const { user: selfUser } = useUserContext()

  return (
    <TableContainer>
      <Table colorScheme="blackAlpha">
        <TableHeader
          columns={VIEWERS_TABLE_COLUMNS}
          uniqueViewers={currentViewerFilterCount}
          docCreatedDate={docCreatedDate}
        />
        <Tbody>
          {viewers.map((viewer, idx) => {
            const { user, lastOpened, visitorId } = viewer
            const isCreator = user?.id === creatorId
            const isContributor = contributors.some(
              (c) => c.user.id === user?.id
            )
            const name = user
              ? user.displayName + (user.id === selfUser?.id ? ' (You)' : '') ||
                ''
              : visitorId
              ? generateName(visitorId)
              : 'Anonymous'

            const lastOpenedDisplay = lastOpened
              ? capitalize(normalizeDate(lastOpened))
              : 'Date unknown'

            const displayUser = {
              name,
              id: user?.id || visitorId || '',
              email: user?.email,
              isCreator,
              isContributor,
              profileImg: user?.profileImageUrl,
              lastOpened: lastOpenedDisplay,
            }
            const isLast = idx === viewers.length - 1
            return (
              <Tr
                key={user?.id || visitorId}
                cursor="pointer"
                onClick={() => handleTableRowClick(displayUser)}
                _hover={{ bg: 'blackAlpha.50' }}
                borderBottom="none"
              >
                <Td borderBottom={isLast ? '0 none' : undefined}>
                  <UserAvatarBlock
                    name={name}
                    email={user?.email}
                    profileImg={user?.profileImageUrl}
                    isContributor={isContributor}
                    isCreator={isCreator}
                  />
                </Td>
                <Td borderBottom={isLast ? '0 none' : undefined}>
                  <Text fontSize="sm">{lastOpenedDisplay}</Text>
                </Td>
                <Td borderBottom={isLast ? '0 none' : undefined}>
                  <CardsViewedProgress
                    cardsViewed={viewer.cardsViewed || 0}
                    totalCards={cardCount || 0}
                  />
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

type UserAvatarBlockProps = Omit<DisplayUser, 'id'> & StackProps

export const UserAvatarBlock = ({
  name,
  profileImg,
  email = '',
  isCreator = false,
  isContributor = false,
  ...rest
}: UserAvatarBlockProps) => {
  return (
    <HStack {...rest}>
      <Avatar size="sm" name={name} src={profileImg} />
      <Stack spacing={0.5}>
        <Text>{name}</Text>
        <HStack mt={1}>
          <Text fontSize="sm" color="gray.500">
            {email}
          </Text>
          {(isCreator || isContributor) && (
            <Tag size="sm" transform="translate(0, 1.5px)">
              {isCreator ? 'Creator' : 'Contributor'}
            </Tag>
          )}
        </HStack>
      </Stack>
    </HStack>
  )
}
