import { memoize } from 'lodash'
import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'

import { EventEmitter } from 'utils/EventEmitter'

declare global {
  interface Window {
    gammaMouseMoveEmitter: MouseMoveEmitter
  }
}
class MouseMoveEmitter extends EventEmitter<{
  move: MouseEvent
}> {
  throttled: any

  constructor() {
    super()

    this.throttled = throttle(
      (e: MouseEvent) => {
        this.emit('move', e)
      },
      200,
      { leading: true }
    )
    document.addEventListener('mousemove', this.throttled)
  }

  cleanup() {
    document.removeEventListener('mousemove', this.throttled)
  }
}

type TestHotspotFn = (e: MouseEvent) => boolean

export const getEmitter = memoize(() => new MouseMoveEmitter())

export const useMouseHotspot = (testHotspot: TestHotspotFn): boolean => {
  const emitter = getEmitter()
  const [inHotspot, setInHotspot] = useState(false)

  useEffect(() => {
    return emitter.on('move', (e) => {
      setInHotspot(testHotspot(e))
    })
  }, [emitter, testHotspot])

  return inHotspot
}
