import {
  Flex,
  ModalHeader,
  VStack,
  Wrap,
  WrapItem,
  Button,
  ModalCloseButton,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalize, DOC_DISPLAY_NAME } from '@gamma-app/ui'

import { GRAY_TEXT_COLOR } from '../constants'

export const DECK_VIEW_LABEL = `${capitalize(DOC_DISPLAY_NAME)} views`
export const CARD_VIEW_LABEL = 'Card engagement'

type AnalyticsModalHeaderProps = {
  isAnalyticsLoading: boolean
  hasError: boolean
  view: string
  setView: React.Dispatch<React.SetStateAction<string>>
  description: string
}

export const AnalyticsModalHeader = ({
  isAnalyticsLoading,
  hasError,
  view,
  setView,
  description,
}: AnalyticsModalHeaderProps) => {
  if (hasError) {
    return <ModalHeader>Analytics</ModalHeader>
  }

  return (
    <Flex direction="column" flex={1}>
      <ModalHeader>
        {isAnalyticsLoading ? (
          <AnalyticsSkeletonHeader />
        ) : (
          <>
            Analytics
            <VStack align="stretch" spacing={4}>
              <Text
                color="gray.500"
                fontSize="sm"
                fontWeight="normal"
                letterSpacing="normal"
              >
                {description}
              </Text>
              <Wrap>
                <WrapItem>
                  <Button
                    leftIcon={
                      <FontAwesomeIcon
                        icon={solid('chart-column')}
                        aria-label={DECK_VIEW_LABEL}
                      />
                    }
                    variant="ghost"
                    fontWeight="600"
                    bg={view === 'deckView' ? 'trueblue.50' : 'transparent'}
                    color={
                      view === 'deckView' ? 'trueblue.500' : GRAY_TEXT_COLOR
                    }
                    onClick={() => setView('deckView')}
                  >
                    {DECK_VIEW_LABEL}
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    leftIcon={
                      <FontAwesomeIcon
                        icon={solid('chart-simple-horizontal')}
                        aria-label={CARD_VIEW_LABEL}
                      />
                    }
                    variant="ghost"
                    fontWeight="600"
                    bg={
                      view === 'cardEngagement' ? 'trueblue.50' : 'transparent'
                    }
                    color={
                      view === 'cardEngagement'
                        ? 'trueblue.500'
                        : GRAY_TEXT_COLOR
                    }
                    onClick={() => setView('cardEngagement')}
                  >
                    {CARD_VIEW_LABEL}
                  </Button>
                </WrapItem>
              </Wrap>
            </VStack>
          </>
        )}
      </ModalHeader>
      <ModalCloseButton />
    </Flex>
  )
}

const AnalyticsSkeletonHeader = () => {
  return (
    <VStack align="stretch" spacing={4}>
      <Skeleton h={12} width="30%" />
      <Skeleton h={4} width="80%" />
    </VStack>
  )
}
