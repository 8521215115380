import { useTheme } from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { TogglePanelConfig } from 'modules/panels/hooks'
import { useAppSelector } from 'modules/redux'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { selectMode } from 'modules/tiptap_editor/reducer'
import { useEffectWhen } from 'utils/hooks'

export const useUpdateTOCFromMode = ({
  toggleToCPanel,
  initializeToCPanel,
  isToCPanelOpen,
}: {
  toggleToCPanel: (config?: TogglePanelConfig) => void
  initializeToCPanel: () => void
  isToCPanelOpen: boolean
}) => {
  const {
    breakpoints: { xl: xlBreakpoint },
  } = useTheme()
  const mode = useAppSelector(selectMode)
  const [isToCPanelOpenInDocMode, setIsToCPanelOpenInDocMode] = useState<
    boolean | null
  >(mode !== EditorModeEnum.SLIDE_VIEW)
  const [hasSetToCBasedOnQuery, setHasSetToCBasedOnQuery] =
    useState<boolean>(false)
  const dispatch = useDispatch()

  useEffectWhen(
    () => {
      // This hook sets up TOC on page load
      // and opens it based on screen size and mode
      initializeToCPanel()

      // Disabling code below since we've decided ToC should always default to closed
      // but leaving it here in case we change our minds later
      // Unfortunately, we can't use the `mode` selector; if you have ?mode=present,
      // the mode in the selector starts out as doc (???) before changing to slide.
      // This is a more surefire way to ensure that the query param is the source of truth
      // for whether or not we open the ToC panel.
      // const { mode: modeFromQuery } = getExistingQueryParams()
      // const { matches } = window.matchMedia(`(min-width: ${xlBreakpoint})`)
      // if (modeFromQuery !== EditorModeToQueryParamMap.SLIDE_VIEW && matches) {
      //   toggleToCPanel({
      //     disableTransition: true,
      //     override: 'open',
      //   })
      //   setHasSetToCBasedOnQuery(true)
      // }
    },
    [xlBreakpoint, toggleToCPanel, initializeToCPanel, dispatch],
    []
  )
  useEffectWhen(
    () => {
      // This hook is responsible for closing the TOC when you enter slide view, and
      // storing info about the previous TOC state such that when you get out of slide
      // view, the TOC will be open or closed depending on how you last left it

      if (mode === EditorModeEnum.SLIDE_VIEW) {
        // Keep track of whether TOC was open right before we switched to Slide view
        setIsToCPanelOpenInDocMode(isToCPanelOpen)
        toggleToCPanel({
          disableTransition: false,
          override: 'closed',
        })
      } else if (hasSetToCBasedOnQuery && mode === EditorModeEnum.DOC_VIEW) {
        toggleToCPanel({
          disableTransition: false,
          override: isToCPanelOpenInDocMode ? 'open' : 'closed',
        })
      }
    },
    [
      toggleToCPanel,
      isToCPanelOpen,
      isToCPanelOpenInDocMode,
      mode,
      hasSetToCBasedOnQuery,
    ],
    [mode]
  )
}
