import { Box, Button, HStack, Text, ToastId, useToast } from '@chakra-ui/react'
import { capitalize, DOC_DISPLAY_NAME, LoadingToastInner } from '@gamma-app/ui'
import { useCallback, useEffect, useRef } from 'react'

import { GetDocQuery } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'

const CloneToast = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      bg="white"
      border="0.5px solid var(--chakra-colors-gray-100)"
      borderRadius="md"
      shadow="md"
      display="column"
      p={4}
      position="relative"
    >
      <HStack>
        <Text mr={1}>
          <strong>Like this {DOC_DISPLAY_NAME}?</strong> Make it yours 😍
        </Text>
        <Button variant="solid" onClick={onClick}>
          Copy {DOC_DISPLAY_NAME}
        </Button>
      </HStack>
    </Box>
  )
}

export const useCloneToast = (doc: GetDocQuery['doc']) => {
  const examplesV2Enabled = useFeatureFlag('examplesV2')
  const enabled = examplesV2Enabled && !isMobileDevice
  const { user } = useUserContext()
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  const duplicateDoc = useDuplicateDoc()
  const analytics = useAnalytics()
  const onClick = useCallback(() => {
    if (!doc) return
    const duplicateToast = toast({
      id: `duplicate-toast-${doc.id}`,
      position: 'top',
      duration: null,
      render: function LoadingToast({ onClose, id }) {
        return (
          <LoadingToastInner
            title={`Copying ${DOC_DISPLAY_NAME}...`}
            isClosable={false}
            onClose={onClose}
            id={id}
          />
        )
      },
    }) as string
    analytics?.track(SegmentEvents.EXAMPLE_DUPLICATED, {
      doc_id: doc.id,
      title: doc.title,
    })
    duplicateDoc(doc.id).then((result) => {
      if (!result?.data?.duplicateDoc) {
        console.error('[CloneToast] handleDuplicateDoc error')
        return
      }
      const { id } = result?.data?.duplicateDoc
      console.debug(
        `[CloneToast] ${capitalize(
          DOC_DISPLAY_NAME
        )} duplicated. New doc id (${id}). Navigating to Editor`
      )
      toast.update(duplicateToast, {
        status: 'success',
        description: 'Success!',
        duration: 5000,
      })
      window.open(`/docs/${id}`, '_blank')
    })
  }, [analytics, doc, duplicateDoc, toast])

  useEffect(() => {
    return () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
      }
    }
  }, [toast])

  useEffect(() => {
    const showCloneToast = enabled && user && doc
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
    if (!showCloneToast) return

    toastIdRef.current = toast({
      position: 'bottom',
      render: () => CloneToast({ onClick }),
      isClosable: true,
      duration: null,
    })
  }, [doc, enabled, onClick, toast, user])
}
