import {
  Circle,
  Flex,
  HStack,
  Progress,
  SquareProps,
  Text,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colorForProgress } from '../utils'

type CardsViewedProgressProps = {
  cardsViewed: number
  totalCards: number
}

export const CardsViewedProgress = ({
  cardsViewed,
  totalCards,
}: CardsViewedProgressProps) => {
  const progressColor = colorForProgress({ degree: cardsViewed / totalCards })
  const hasTotalCards = totalCards > 0
  return (
    <HStack spacing={3}>
      <Flex flex={1} position="relative">
        <Progress
          h={4}
          flex={1}
          borderRadius="sm"
          value={cardsViewed}
          max={hasTotalCards ? totalCards : undefined}
          bg="gray.200"
          // colorScheme does not work for colors that aren't just "green"
          sx={{
            '> div': {
              bg: progressColor,
            },
          }}
        />
        {hasTotalCards && cardsViewed === totalCards && (
          <CircleCheckmark
            top={0}
            right={0}
            transform="translate(50%,-50%)"
            position="absolute"
          />
        )}
      </Flex>
      <Text
        w={{ base: 10, lg: 12 }}
        fontSize="sm"
        whiteSpace="nowrap"
        textAlign="left"
      >
        {!hasTotalCards ? 'no data' : `${cardsViewed}/${totalCards}`}
      </Text>
    </HStack>
  )
}

export const CircleCheckmark = ({ ...circleProps }: SquareProps) => {
  return (
    <Circle
      bg="green.500"
      size="18px"
      border="2px solid white"
      color="white"
      {...circleProps}
    >
      <FontAwesomeIcon icon={solid('check')} aria-label="checkmark" size="xs" />
    </Circle>
  )
}
