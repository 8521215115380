import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  Heading,
  ModalBody,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { format, parseISO } from 'date-fns'
import { useCallback, useState } from 'react'

import {
  MemoStats,
  useGetMemoAnalyticsQuery,
  Doc,
  DocDayStats,
} from 'modules/api'

import { BACKGROUND_GRAY, GRAY_TEXT_COLOR } from '../constants'
import { AnalyticsViewerType, DisplayUser } from '../types'
import { AnalyticsError } from './AnalyticsError'
import {
  AnalyticsModalHeader,
  CARD_VIEW_LABEL,
  DECK_VIEW_LABEL,
} from './AnalyticsModalHeader'
import { CardEngagementView } from './CardEngagement'
import { UserCardDrilldown } from './UserCardDrilldown'
import { ViewerControlMenu } from './ViewerControls'
import { ViewersTable } from './ViewersTable'
import { ViewersTimeBarChart } from './ViewersTimeBarChart'

const FULL_DATE_FORMAT = 'PPP'

const formatDate = (dateFormat: string, date?: string) => {
  if (!date) return ''
  return format(parseISO(date), dateFormat)
}

const isAllZeroData = (dayStats: DocDayStats[]) =>
  dayStats.every((day) => day.uniqueVisitors === 0)

const getViews = (
  filter: AnalyticsViewerType,
  memoStats?: MemoStats
): DocDayStats[] => {
  if (!memoStats || !memoStats.dailyViews) return []
  if (filter === 'everyone') {
    return memoStats.dailyViews.everyone ? memoStats.dailyViews.everyone : []
  }
  if (filter === 'viewersOnly') {
    return memoStats.dailyViews.viewers ? memoStats.dailyViews.viewers : []
  }
  return []
}

const getViewerCount = (
  doc: Doc,
  filter: AnalyticsViewerType,
  memoStats?: MemoStats
) => {
  if (!memoStats) return 0
  const everyone = memoStats.uniqueViewers ?? 0
  // Using doc.editors instead of memoStats.uniqueEditors because if you duplicate a deck but
  // do not edit it, you will be listed as an editor in the doc, but not counted in the uniqueEditors
  // Link to ticket: https://linear.app/gamma-app/issue/G-2605/when-you-duplicate-a-deck-without-editing-edit-count-is-returned-as-0
  const editorsOnly = doc.editors?.length ?? 0
  if (filter === 'everyone') {
    return everyone
  } else if (filter === 'viewersOnly') {
    // prevent possibility of negative numbers
    return Math.max(0, everyone - editorsOnly)
  } else {
    return 0
  }
}

type AnalyticsView = 'deckView' | 'cardEngagement'

type FullAnalyticsViewProps = {
  doc: Doc
  handleSharePanelOpen: () => void
  onAnalyticsPanelClose: () => void
}

export const FullAnalyticsView = ({
  doc,
  handleSharePanelOpen,
  onAnalyticsPanelClose,
}: FullAnalyticsViewProps) => {
  const {
    loading: isAnalyticsLoading,
    data: analyticsData,
    error,
    refetch,
  } = useGetMemoAnalyticsQuery({
    variables: {
      memoId: doc.id,
    },
    skip: !doc.id,
    // re-get stats every 15 minutes
    pollInterval: 1000 * 60 * 15,
    // This ensures that the value of isAnalyticsLoading updates on refetch
    notifyOnNetworkStatusChange: true,
  })
  const [view, setView] = useState<AnalyticsView>('deckView')
  const [individualUserToShow, setIndividualUserToShow] =
    useState<DisplayUser | null>(null)
  const [viewerFilter, setViewerFilter] =
    useState<AnalyticsViewerType>('everyone')

  const docCreatedDate = formatDate(FULL_DATE_FORMAT, doc.createdTime)
  const docTitle = doc.title || 'Untitled'
  const viewers = analyticsData?.memoStats.viewers || []
  const dayCount = analyticsData?.memoStats.dailyViews?.dayCount || 0
  const views: DocDayStats[] = getViews(viewerFilter, analyticsData?.memoStats)

  const shouldDisplayNoDataForBarChart = isAllZeroData(views)
  const filteredViewers = viewers.filter((viewer) => {
    if (viewerFilter === 'everyone') {
      return true
    } else {
      // In the future, handle filtering viewers for those that came via accessLink
      return !doc.editors?.some((editor) => viewer.user?.id === editor.user?.id)
    }
  })
  const currentViewerFilterCount = getViewerCount(
    doc,
    viewerFilter,
    analyticsData?.memoStats
  )

  const handleMenuItemChange = useCallback((value: AnalyticsViewerType) => {
    setViewerFilter(value)
  }, [])

  const handleViewChange = useCallback((value: AnalyticsView) => {
    setView(value)
  }, [])

  const handleTableRowClick = useCallback((user: DisplayUser) => {
    setIndividualUserToShow(user)
  }, [])

  const handleBackClick = useCallback(() => {
    setIndividualUserToShow(null)
  }, [])

  const hasError = error !== undefined

  return (
    <>
      {individualUserToShow && (
        <UserCardDrilldown
          user={individualUserToShow}
          onBackClick={handleBackClick}
          docId={doc.id}
          onAnalyticsPanelClose={onAnalyticsPanelClose}
        />
      )}
      {/* Header */}
      {!individualUserToShow && (
        <>
          <AnalyticsModalHeader
            hasError={hasError}
            isAnalyticsLoading={isAnalyticsLoading}
            setView={handleViewChange}
            view={view}
            description={`${docTitle}, since it was created on ${docCreatedDate}`}
          />
          {hasError && <AnalyticsError error={error} refetch={refetch} />}
          {!hasError && (
            <>
              <Divider />

              {/*Body */}
              <ModalBody>
                {isAnalyticsLoading && <FullAnalyticsSkeletonBody />}
                {!isAnalyticsLoading && viewers.length <= 1 && (
                  <NoViewersShareBlock
                    handleSharePanelOpen={handleSharePanelOpen}
                  />
                )}
                {!isAnalyticsLoading && (
                  <Flex direction="column" mt={4}>
                    <Flex
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Heading as="h2" size="md">
                        {view === 'cardEngagement'
                          ? CARD_VIEW_LABEL
                          : DECK_VIEW_LABEL}
                      </Heading>
                      <ViewerControlMenu
                        selected={viewerFilter}
                        handleMenuItemChange={handleMenuItemChange}
                        everyoneCount={getViewerCount(
                          doc,
                          'everyone',
                          analyticsData?.memoStats
                        )}
                        viewersOnlyCount={getViewerCount(
                          doc,
                          'viewersOnly',
                          analyticsData?.memoStats
                        )}
                      />
                    </Flex>

                    {/* Deck views bar chart */}
                    {view === 'deckView' && shouldDisplayNoDataForBarChart && (
                      <NoViewersDataAlert
                        handleSharePanelOpen={handleSharePanelOpen}
                        dayCount={dayCount}
                      />
                    )}
                    {view === 'deckView' && !shouldDisplayNoDataForBarChart && (
                      <ViewersTimeBarChart
                        viewData={views}
                        dayCount={dayCount}
                      />
                    )}
                    {/* Card engagement */}
                    {view === 'cardEngagement' && (
                      <CardEngagementView
                        docId={doc.id}
                        viewerFilter={viewerFilter}
                        currentViewerFilterCount={currentViewerFilterCount}
                        onAnalyticsPanelClose={onAnalyticsPanelClose}
                      />
                    )}

                    <ViewersTable
                      creatorId={doc.createdBy?.id || ''}
                      viewers={filteredViewers}
                      currentViewerFilterCount={currentViewerFilterCount}
                      docCreatedDate={docCreatedDate}
                      contributors={doc.editors || []}
                      cardCount={analyticsData?.memoStats.cardCount || 0}
                      handleTableRowClick={handleTableRowClick}
                    />
                  </Flex>
                )}
              </ModalBody>
            </>
          )}
        </>
      )}
    </>
  )
}

const FullAnalyticsSkeletonBody = () => {
  return (
    <Flex direction="column" py={2}>
      <Skeleton h={10} width="40%" mb={4} />
      <Skeleton h={40} mb={4} />
      <VStack align="stretch">
        {[...Array(3)].map((_, i) => (
          <Skeleton h={12} key={i} />
        ))}
      </VStack>
    </Flex>
  )
}

const NoViewersShareBlock = ({
  handleSharePanelOpen,
}: {
  handleSharePanelOpen: () => void
}) => {
  return (
    <Flex
      p={3}
      px={4}
      mt={2}
      bg={BACKGROUND_GRAY}
      color={GRAY_TEXT_COLOR}
      borderRadius="base"
    >
      <Flex align="baseline" fontSize="sm">
        <Box pr={2}>
          <FontAwesomeIcon
            icon={solid('user')}
            aria-label="user-icon"
            size="sm"
          />
        </Box>
        <Text>
          Only you have seen this {DOC_DISPLAY_NAME}.{' '}
          <Text
            as="button"
            onClick={handleSharePanelOpen}
            textDecoration="underline"
            fontWeight="600"
            color="gray.800"
          >
            Share it with others
          </Text>{' '}
          to track views and engagement.
        </Text>
      </Flex>
    </Flex>
  )
}

const NoViewersDataAlert = ({
  handleSharePanelOpen,
  dayCount,
}: {
  handleSharePanelOpen: () => void
  dayCount: number
}) => {
  return (
    <Alert
      my={4}
      p={6}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      colorScheme={'gray'}
    >
      <Circle size="30px" bg="gray.500">
        <FontAwesomeIcon icon={solid('empty-set')} color="white" />
      </Circle>
      <AlertTitle mt={4} mb={2} fontSize="md">
        No data to show
      </AlertTitle>
      {dayCount > 0 && (
        <>
          <AlertDescription maxWidth="sm">
            {`No viewers to display for the last
          ${dayCount === 1 ? 'day' : `${dayCount} days`}`}
          </AlertDescription>
          <Button mt={4} onClick={handleSharePanelOpen}>
            Share this {DOC_DISPLAY_NAME}
          </Button>
        </>
      )}
    </Alert>
  )
}
