import { Flex, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { useCallback } from 'react'

import { useEditorContext } from 'sections/docs'

import { ToolbarButton } from './components/ToolbarButton'

export const SpotlightBlock = ({
  spotlightingByBlock,
}: {
  spotlightingByBlock: boolean
}) => {
  const { editor } = useEditorContext()

  const toggleSpotlight = useCallback(() => {
    if (!spotlightingByBlock) {
      editor?.commands.spotlightNextBlock()
    } else {
      editor?.commands.turnOffSpotlight()
    }
  }, [editor, spotlightingByBlock])

  return (
    <Flex>
      <GammaTooltip
        closeOnMouseDown={false}
        closeOnClick={false}
        label={
          <Text whiteSpace="nowrap">
            {spotlightingByBlock ? 'Turn off spotlight' : 'Turn on spotlight'}
            <Text as={'span'} display="inline" color="gray.400" ml={2}>
              S
            </Text>
          </Text>
        }
      >
        <ToolbarButton
          isActive={spotlightingByBlock}
          colorScheme={'trueblue'}
          variant={spotlightingByBlock ? 'solid' : 'plain'}
          onClick={toggleSpotlight}
          icon={<FontAwesomeIcon icon={regular('lamp-desk')} />}
        >
          Spotlight
        </ToolbarButton>
      </GammaTooltip>
    </Flex>
  )
}
