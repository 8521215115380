import { Link, Text, useToast } from '@chakra-ui/react'
import { LoadingToastInner } from '@gamma-app/ui'
import { Editor, JSONContent } from '@tiptap/core'
import { useCallback, useRef, useState } from 'react'

import { config } from 'config'
import { Doc } from 'modules/api'

const PDF_EXPORT_MAX_DELAY = 30000

const pdfExportEndpoint = (docId: string) =>
  `${config.API_HOST || ''}/export/docs/${docId}/pdf`

const fetchPDFEndpoint = async (docId: string, docContent: JSONContent) => {
  const url = pdfExportEndpoint(docId)

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      docContent,
    }),
  })
}

export const useHandlePDFExport = (editor: Editor | null, doc: Doc) => {
  const toast = useToast()
  const toastId = useRef<string | null>(null)

  const [exportInProgress, setExportInProgress] = useState(false)

  const showGeneratingPDFToast = useCallback(() => {
    toastId.current = toast({
      id: `export-toast-${doc.id}`,
      position: 'top',
      duration: null,
      render: function LoadingToast({ id }) {
        return (
          <LoadingToastInner
            title="Generating PDF..."
            description=""
            isClosable={false}
            id={id}
          />
        )
      },
    }) as string
  }, [doc?.id, toast])

  const showLoadingPDFToast = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, {
        render: function LoadingToast({ onClose, id }) {
          return (
            <LoadingToastInner
              title="Generating PDF..."
              description="This is taking a while. You can keep waiting, or close this and we'll email it to you."
              isClosable={true}
              onClose={onClose}
              id={id}
            />
          )
        },
      })
    }
  }, [toast])

  const showErrorToast = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, {
        title: 'Error exporting PDF.',
        status: 'error',
        duration: null,
        isClosable: true,
      })
    }
  }, [toast])

  const showSuccessPDFToast = useCallback(
    (downloadLink: string | undefined) => {
      if (toastId.current) {
        toast.update(toastId.current, {
          status: 'success',
          title: 'Your PDF is ready.',
          description: (
            <Text>
              A download should start automatically. If not,{' '}
              <Link
                textDecoration="underline"
                target="_blank"
                href={downloadLink}
              >
                click here to download it.
              </Link>
            </Text>
          ),
          isClosable: true,
        })
      }
    },
    [toast]
  )

  const handlePDFExport = useCallback(() => {
    if (!editor) return

    const docContent = editor.getJSON()

    setExportInProgress(true)
    showGeneratingPDFToast()

    const exportWaitTimeout = setTimeout(
      showLoadingPDFToast,
      PDF_EXPORT_MAX_DELAY
    )

    fetchPDFEndpoint(doc.id, docContent)
      .then(async (response) => {
        const blob = await response.blob()
        setExportInProgress(false)
        clearTimeout(exportWaitTimeout)
        showSuccessPDFToast(
          response.headers.get('x-gamma-download-url') || undefined
        )

        // auto download
        // This feels a bit hacky but is apparently the best way to achieve auto download 🤷
        const blobUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = doc.title || doc.id
        a.href = blobUrl
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .catch(() => {
        showErrorToast()
        setExportInProgress(false)
      })
  }, [
    doc?.id,
    doc?.title,
    editor,
    showErrorToast,
    showGeneratingPDFToast,
    showLoadingPDFToast,
    showSuccessPDFToast,
  ])

  return { handlePDFExport, exportInProgress }
}
