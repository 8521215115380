import { CloseIcon } from '@chakra-ui/icons'
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  MenuDivider,
  StackDivider,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarOverflowList, GammaTooltip, TooltipAvatar } from '@gamma-app/ui'
import { LayoutGroup } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tinycolor from 'tinycolor2'

import {
  Collaborator,
  selectDistinctCollaborators,
  selectIsEditingInSlideView,
  selectLocalCollaborator,
  selectLocalCollaboratorAttached,
  selectLocalCollaboratorIsFollowingSomeone,
  selectNewFollowers,
  setFollowingAttached,
} from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'
import { useEditorContext } from 'sections/docs/context'

import { useFollow, useFollowFromQueryParams } from '../presentFollow'

export const CollaboratorsPanel = () => {
  const { user, anonymousUser } = useUserContext()
  const dispatch = useDispatch()
  const toast = useToast()
  const { editor } = useEditorContext()
  const { collaborators, followers } = useSelector(
    selectDistinctCollaborators(user?.id || anonymousUser.id)
  )
  // Initialize following based on query params, if any
  useFollowFromQueryParams({ editor, collaborators, user })
  const newFollowers = useSelector(selectNewFollowers)
  const localCollaborator = useSelector(selectLocalCollaborator)
  const localIsAttached = useSelector(selectLocalCollaboratorAttached)
  const localIsFollowing = useSelector(
    selectLocalCollaboratorIsFollowingSomeone
  )
  const isEditingInSlideView = useSelector(selectIsEditingInSlideView)

  useEffect(() => {
    newFollowers.forEach((follower) => {
      toast({
        duration: 2000,
        position: 'bottom',
        render: function FollowerToast() {
          return (
            <Flex
              bg="gray.50"
              p={3}
              px={6}
              align="center"
              borderRadius="md"
              shadow="md"
            >
              <Avatar
                size="sm"
                src={follower.profileImageUrl}
                border={`solid 2px ${follower.color}`}
                color={tinycolor(follower.color).isDark() ? 'white' : 'black'}
                bg={follower.color}
                ignoreFallback={true}
              />
              <Text ml={2}>{follower.name} started following you.</Text>
            </Flex>
          )
        },
      })
    })
  }, [newFollowers, toast])

  useEffect(() => {
    const shouldShowDetachedToast =
      !localIsAttached && // Must be detached
      localIsFollowing && // Must be following someone
      // Must not be editing in slide view (that action detaches you), so
      // we wait until they've finished editing to show the toast
      !isEditingInSlideView
    if (!editor || !shouldShowDetachedToast) return

    toast.closeAll()
    const detachedToast = toast({
      id: 'detached-toast',
      // eslint-disable-next-line
      render: () => (
        <Flex
          bg="gray.50"
          p={3}
          px={6}
          m={4} // Match up with you're editing notification
          justify="flex-end"
          align="center"
          borderRadius="md"
          shadow="md"
        >
          <Text size="sm" mr={6}>
            You're exploring.
          </Text>
          <ButtonGroup size="sm" alignItems="center">
            <Button
              onClick={() => {
                dispatch(setFollowingAttached({ attached: true }))
              }}
              variant="solid"
            >
              Back to following
            </Button>

            <GammaTooltip label="Stop following">
              <IconButton
                isRound
                variant="ghost"
                size="xs"
                aria-label="Stop following"
                icon={<CloseIcon />}
                onClick={() => {
                  dispatch(
                    setFollowingAttached({ attached: false, following: null })
                  )
                }}
              />
            </GammaTooltip>
          </ButtonGroup>
        </Flex>
      ),
      position: 'bottom',
      duration: null,
    }) as string
    return () => {
      toast.close(detachedToast)
    }
  }, [
    editor,
    localIsAttached,
    localIsFollowing,
    isEditingInSlideView,
    toast,
    dispatch,
  ])

  useEffect(() => {
    if (!localIsFollowing) {
      if (!editor || editor.isDestroyed) return
      dispatch(setFollowingAttached({ following: null }))
    }
  }, [editor, localIsFollowing, dispatch])

  const MAX_AVATARS =
    useBreakpointValue({
      base: 4,
      md: 6,
      xl: 8,
    }) || 6

  const maxCollaboratorAvatars = MAX_AVATARS / (followers.length ? 2 : 1)
  const maxFollowerAvatars = MAX_AVATARS / (collaborators.length ? 2 : 1)
  return (
    <Flex>
      <HStack divider={<StackDivider borderColor="gray.200" />}>
        {followers.length && (
          <CollaboratorAvatarGroup
            type={GroupType.FOLLOWERS}
            collaborators={followers}
            max={maxFollowerAvatars}
            isEveryone={collaborators.length === 0}
            localCollaborator={localCollaborator}
            localIsAttached={localIsAttached}
          />
        )}
        {collaborators.length && (
          <CollaboratorAvatarGroup
            type={GroupType.COLLABORATORS}
            collaborators={collaborators}
            max={maxCollaboratorAvatars}
            isEveryone={followers.length === 0}
            localCollaborator={localCollaborator}
            localIsAttached={localIsAttached}
          />
        )}
      </HStack>
    </Flex>
  )
}

enum GroupType {
  FOLLOWERS = 'FOLLOWERS',
  COLLABORATORS = 'COLLABORATORS',
}
interface CollaboratorAvatarGroupProps {
  collaborators: Collaborator[]
  localCollaborator?: Collaborator
  localIsAttached: boolean
  type: GroupType
  max: number
  isEveryone: boolean
}

const CollaboratorAvatarGroup = ({
  collaborators,
  localCollaborator,
  localIsAttached,
  type,
  max,
  isEveryone,
}: CollaboratorAvatarGroupProps) => {
  const follow = useFollow()
  const isFollowerGroup = type === GroupType.FOLLOWERS
  const over = collaborators.length > max
  // If we overflow, save the last slot for the overflow avatar (max - 1)
  const avatarList = over ? collaborators.slice(0, max - 1) : collaborators
  // If we overflow, include the user who would have been in the last slot (max + 1)
  const overflowCount = over ? collaborators.length - max + 1 : 0

  const onAvatarClick = ({ localIsFollowing, collaborator }) => {
    if (isFollowerGroup) return
    follow({ localIsFollowing, localIsAttached, collaborator })
  }
  return (
    <LayoutGroup>
      <AvatarGroup size="sm" spacing={-2}>
        {isFollowerGroup && <FollowerGroupEyecon isEveryone={isEveryone} />}
        {avatarList.map((collaborator) => {
          const localIsFollowing =
            localCollaborator?.memoState?.following === collaborator.sessionId
          const isIdle = Boolean(collaborator.idleSince)
          return (
            <TooltipAvatar
              key={collaborator.sessionId}
              ignoreFallback={true}
              name={collaborator.name}
              label={
                <Flex direction="column" justify="center" align="center">
                  <Text>{collaborator.name}</Text>
                  <Text fontSize="xs">
                    {isFollowerGroup
                      ? 'Is following you'
                      : localIsFollowing && localIsAttached
                      ? 'Click to stop following'
                      : 'Click to follow'}
                  </Text>
                </Flex>
              }
              pointerEvents={isIdle ? 'none' : 'auto'}
              opacity={isIdle ? 0.4 : 1}
              transitionProperty="common"
              transitionDuration="normal"
              src={collaborator.profileImageUrl}
              border={`solid 2px ${collaborator.color}`}
              nubbin={localIsFollowing && localIsAttached}
              bg={collaborator.color}
              shadow="base"
              color={tinycolor(collaborator.color).isDark() ? 'white' : 'black'}
              cursor={isFollowerGroup ? '' : 'pointer'}
              onClick={() =>
                onAvatarClick({
                  localIsFollowing,
                  collaborator,
                })
              }
            />
          )
        })}
        {overflowCount > 0 && (
          <AvatarOverflowList
            onClick={(avatar) => {
              const collaborator = collaborators.find((c) => c.id === avatar.id)
              const locIsF = Boolean(
                collaborator &&
                  localCollaborator?.memoState?.following ===
                    collaborator.sessionId
              )
              onAvatarClick({ localIsFollowing: locIsF, collaborator })
            }}
            avatars={collaborators}
            count={overflowCount}
            disabled={isFollowerGroup}
          >
            <Text px={2}>
              {isFollowerGroup
                ? `${collaborators.length} people following you`
                : `${collaborators.length} people viewing or editing`}
            </Text>
            {!isFollowerGroup && (
              <Text px={2} fontSize="sm" color="gray.500">
                Click to follow anyone
              </Text>
            )}
            <MenuDivider />
          </AvatarOverflowList>
        )}
      </AvatarGroup>
    </LayoutGroup>
  )
}

const FollowerGroupEyecon = ({ isEveryone }: { isEveryone: boolean }) => {
  const [hasShownInitial, setHasShownInitial] = useState(false)
  const [showInitial, setShowInitial] = useState(false)
  const [isOver, setOver] = useState(false)

  useEffect(() => {
    // Manually show the tooltip on the first occurence of
    // isEveryone becoming true as an educational notification
    if (hasShownInitial || !isEveryone) return

    setShowInitial(true)
    setHasShownInitial(true)
    setTimeout(() => {
      setShowInitial(false)
    }, 3000)
  }, [isEveryone, hasShownInitial])

  if (!isEveryone) {
    return (
      <Box fontSize="sm" color={'gray.700'} pr={4}>
        <FontAwesomeIcon icon={regular('eye')} />
      </Box>
    )
  }

  return (
    <Box fontSize="sm" color="" px={2} mr={2}>
      <GammaTooltip
        label="Everyone is following you"
        isOpen={isOver || showInitial}
      >
        <Box
          mr={2}
          px={1}
          bg="green.300"
          color="green.900"
          borderRadius="full"
          onMouseEnter={() => setOver(true)}
          onMouseLeave={() => setOver(false)}
        >
          <FontAwesomeIcon icon={regular('eye')} />
        </Box>
      </GammaTooltip>
    </Box>
  )
}
