import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'

type UnsentInvitationsModalProps = {
  isOpen: boolean
  onClose: () => void
  onSendInvitationsClick: () => void
  onDiscardClick: () => void
  invitationCount: number
}
export const UnsentInvitationsModal = ({
  invitationCount,
  isOpen,
  onClose,
  onSendInvitationsClick,
  onDiscardClick,
}: UnsentInvitationsModalProps) => {
  const INVITATION_SINGULAR_OR_PLURAL_STRING =
    invitationCount === 1 ? 'invitation' : 'invitations'
  return (
    <Modal
      returnFocusOnClose={false}
      onClose={onClose}
      closeOnOverlayClick={true}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unsent {INVITATION_SINGULAR_OR_PLURAL_STRING}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            You have {invitationCount} unsent{' '}
            {INVITATION_SINGULAR_OR_PLURAL_STRING}. Do you want to discard or
            send {invitationCount === 1 ? 'it' : 'them'}?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} direction="row" align="center">
            <Button onClick={onDiscardClick}>Discard</Button>
            <Button variant="solid" onClick={onSendInvitationsClick}>
              Send {INVITATION_SINGULAR_OR_PLURAL_STRING}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
