import { useToast } from '@chakra-ui/react'
import {
  capitalize,
  CHANNEL_DISPLAY_NAME,
  DOC_DISPLAY_NAME,
} from '@gamma-app/ui'
import { useCallback } from 'react'

import {
  Channel,
  Doc,
  DocChannel,
  DocChannelsUpdateFragmentDoc,
  DocChannelUpdateFragmentDoc,
  PatchOperation,
  Permission,
  useUpdatePublicChannelsMutation,
} from 'modules/api'

export const useUpdatePublicChannels = () => {
  const toast = useToast()
  const [_updatePublicChannels] = useUpdatePublicChannelsMutation({
    refetchQueries: ['GetChannelDocActivity', 'GetChannels'],
  })

  const addDocChannel = useCallback(
    ({
      docId,
      doc,
      channel,
      existingChannels = [],
    }: {
      docId?: string
      doc?: Doc
      channel: Channel
      existingChannels?: DocChannel[]
    }) => {
      const docIdToUse = docId || doc?.id
      if (!docIdToUse) {
        console.error('Invalid docid')
        return
      }
      const variables = {
        input: {
          docId: docIdToUse,
          sharing: [
            {
              op: PatchOperation.Add,
              path: '/channels',
              value: [
                {
                  channelId: channel.id,
                },
              ],
            },
          ],
        },
      }
      const title = doc?.title || DOC_DISPLAY_NAME
      const optimisticChannels = [
        ...existingChannels,
        {
          id: channel.id,
          slug: channel.slug,
          name: channel.name,
          permission: Permission.Comment,
        },
      ].sort((a, b) =>
        // Mimic the API sort order for DocChannels
        // https://github.com/gamma-app/gamma/blob/25763537fd84edcd73ca4c99c2a79df4890ed5d6/packages/server/src/docs/docs.service.ts#L632-L635
        a.slug.localeCompare(b.slug, 'en')
      )
      _updatePublicChannels({
        variables,
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `Doc:${docIdToUse}`,
            fragment: DocChannelUpdateFragmentDoc,
            data: data?.updatePublicChannels,
          })
        },
        optimisticResponse: {
          updatePublicChannels: {
            id: variables.input.docId,
            channels: optimisticChannels,
          },
        },
      }).then(
        () => {
          toast({
            title: `Added ${title} to ${CHANNEL_DISPLAY_NAME}`,
            description: `This ${DOC_DISPLAY_NAME} has been added to ${channel.name}`,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })
        },
        (error) => {
          toast({
            title: `Error adding ${title} to ${CHANNEL_DISPLAY_NAME} ${channel.name}`,
            description: error.message,
            duration: null,
            status: 'error',
            isClosable: true,
            position: 'top',
          })
        }
      )
    },
    [_updatePublicChannels, toast]
  )

  const removeDocChannel = useCallback(
    (doc: Doc, channelId: string | null) => {
      if (!channelId) {
        return
      }
      const variables = {
        input: {
          docId: doc.id,
          sharing: [
            {
              op: PatchOperation.Remove,
              path: '/channels',
              value: [
                {
                  channelId: channelId,
                },
              ],
            },
          ],
        },
      }
      _updatePublicChannels({
        variables,
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `Doc:${doc.id}`,
            fragment: DocChannelsUpdateFragmentDoc,
            data: data?.updatePublicChannels,
          })
        },
        optimisticResponse: {
          updatePublicChannels: {
            id: variables.input.docId,
            channels: doc.channels?.filter((c) => c.id !== channelId),
          },
        },
      })
        .then(() => {
          toast({
            title: `${capitalize(
              DOC_DISPLAY_NAME
            )} removed from ${CHANNEL_DISPLAY_NAME}.`,
            status: 'success',
            duration: 10000,
            position: 'top',
          })
        })
        .catch((e) => {
          toast({
            title: `Error removing ${CHANNEL_DISPLAY_NAME}:`,
            description: e.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          })
          console.error(
            '[addRemoveDocChannel] Error removing channel:',
            e.message
          )
        })
    },
    [_updatePublicChannels, toast]
  )

  return {
    addDocChannel,
    removeDocChannel,
  }
}
