import { HStack, Flex } from '@chakra-ui/react'
import { whiten } from '@chakra-ui/theme-tools'
import { gammaTheme } from '@gamma-app/ui'
import { useEffect, useState } from 'react'

import { useAppStore } from 'modules/redux'
import { CardIdMap } from 'modules/tiptap_editor'
import {
  selectCardIdMap,
  selectPresentingCardId,
} from 'modules/tiptap_editor/reducer'

type CardId = string
type TopLevelCardMap = Record<CardId, CardId[]>

const useProgress = () => {
  const [progress, setProgress] = useState<[CardId, CardId[], TopLevelCardMap]>(
    ['', [], {}]
  )
  const reduxStore = useAppStore()

  useEffect(() => {
    const callback = () => {
      const state = reduxStore.getState()
      const cardIdMap = selectCardIdMap(state)
      const presentingCardId = selectPresentingCardId(state) || ''
      const presentingCardsParents = cardIdMap.parents[presentingCardId] || []

      /**
       * A map of top level cardIds to an array of all their nested card children in depth first order
       * {
       *   card1: [nestedA, nestedA.1, nestedB]
       *   card2: [nestedC, nestedD, nestedD.1, nestedD.2]
       *   card3: [nestedE, nestedE.1, nested E.1.1]
       * }
       */
      const topLevelCardMap: TopLevelCardMap = {}
      Object.keys(cardIdMap.tree).forEach((topLevelCardId: CardId) => {
        const cardIdList: CardId[] = []

        const pushIds = (tree: CardIdMap['tree']) => {
          Object.keys(tree).forEach((cardId) => {
            cardIdList.push(cardId)
            pushIds(tree[cardId])
          })
        }

        pushIds(cardIdMap.tree[topLevelCardId])
        topLevelCardMap[topLevelCardId] = cardIdList
      })
      setProgress([presentingCardId, presentingCardsParents, topLevelCardMap])
    }
    return reduxStore.subscribe(callback)
  }, [reduxStore])

  return progress
}

export const ProgressBar = () => {
  const [presentingCardId, presentingCardsParents, topLevelCardMap] =
    useProgress()
  const topLevelCardIds = Object.keys(topLevelCardMap)

  return (
    <HStack
      position="fixed"
      bottom={0}
      w="100vw"
      zIndex={10}
      background="blackAlpha.200"
      px={'6px'}
      py={'2px'}
      align="end"
      spacing={'2px'}
      opacity={presentingCardId ? 1 : 0}
      transitionProperty="opacity"
      transitionDuration="normal"
    >
      {topLevelCardIds.map((cardId, idx) => {
        const presentingCardsTopParent = [
          ...presentingCardsParents,
          presentingCardId,
        ][0]

        const currentTopLevelIdx = topLevelCardIds.indexOf(
          presentingCardsTopParent
        )
        const nestedCardList = topLevelCardMap[presentingCardsTopParent]
        let percent: number = 0
        let unit: number = 100
        if (
          presentingCardId &&
          nestedCardList &&
          topLevelCardIds.indexOf(presentingCardId) === -1
        ) {
          // The current presenting card is a nested card.
          // Compute the distance into it's top level card.
          const presentingCardIdx = nestedCardList.indexOf(presentingCardId)

          percent = ((presentingCardIdx + 1) / nestedCardList.length) * 100
          unit = (1 / nestedCardList.length) * 100
        }
        return (
          <Pill
            key={cardId}
            cardId={cardId}
            state={
              idx === currentTopLevelIdx
                ? 'current'
                : idx <= currentTopLevelIdx
                ? 'prev'
                : 'next'
            }
            percent={percent}
            unit={unit}
          />
        )
      })}
    </HStack>
  )
}

type PillValue = 'prev' | 'current' | 'next'

const bgColorCurrent = whiten('trueblue.400', 20)(gammaTheme) // 'rgba(255,255,255,1)' // Currently on
const bgColorSeen = whiten('trueblue.400', 40)(gammaTheme) // 'rgba(255,255,255,.6)' // Prev/Seen
const bgColorNew = whiten('trueblue.400', 80)(gammaTheme) //'rgba(200,200,200,.4)' // New

const Pill = ({
  cardId,
  state,
  percent,
  unit,
}: {
  cardId: string
  state: PillValue
  percent: number
  unit: number
}) => {
  // The current pill is comprised of 3 sections:
  // [<---     seen    --->|<---      current       ---->|<---    new    --->]
  // [<---0 to percent --->|<---percent plus 1 unit ---->|<--- remaining --->]
  const currentWithPct = `linear-gradient(to right, ${bgColorSeen} 0% ${percent}%, ${bgColorCurrent} ${percent}% ${
    percent + unit
  }%, ${bgColorNew} ${percent + unit}% 100%)`

  return (
    <Flex
      key={cardId}
      transitionProperty="common"
      transitionDuration="normal"
      borderRadius="2px"
      flex="1"
      h="6px"
      bg={
        state === 'current'
          ? currentWithPct
          : state === 'prev'
          ? bgColorSeen
          : bgColorNew
      }
    />
  )
}
