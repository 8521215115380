import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'
import { memo, useMemo } from 'react'

import { useAppSelector } from 'modules/redux'
import { isThemeDark, Theme } from 'modules/theming'

import { DocumentAttributes } from '../../../extensions/Document/DocumentAttrs/attributes'
import { useForwardUndo } from '../../../hooks'
import { selectBackground, selectTheme } from '../../../reducer'
import { BackgroundPanel } from '../../panels/BackgroundPanel'
import { ThemePanel } from './ThemePanel'

export const ThemeSkeleton = ({ size }: { size: number }) => {
  return (
    <Flex h="100%">
      <SimpleGrid w="100%" columns={2} spacing={5} mt={2}>
        {new Array(size).fill(true).map((_, i) => {
          return <Skeleton key={i} minH="9em" borderRadius="base" />
        })}
      </SimpleGrid>
    </Flex>
  )
}

type DocThemeDrawerProps = {
  isOpen: boolean
  onClose: () => void
  getEditor: () => Editor | null
  openThemeEditor: (themeToEdit?: Theme) => void
  setDocTheme: (newTheme: Theme) => void
  updateAttributesAndTrack: (docAttrs: Partial<DocumentAttributes>) => void
}

export const DocThemeDrawer = memo(
  ({
    isOpen,
    onClose,
    getEditor,
    openThemeEditor,
    setDocTheme,
    updateAttributesAndTrack,
  }: DocThemeDrawerProps) => {
    const editor = getEditor()
    const theme = useAppSelector(selectTheme)
    const background = useAppSelector(selectBackground)

    const forwardUndo = useForwardUndo(editor)
    const defaultMessage = useMemo(
      () => (
        <Alert>
          <AlertIcon />
          The theme you choose in the Theme tab will control the background. You
          can override it by choosing an option above, or changing to a
          different theme.
        </Alert>
      ),
      []
    )

    return (
      <Drawer
        returnFocusOnClose={false}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="sm"
      >
        <DrawerOverlay background="none" />
        <DrawerContent onKeyDown={forwardUndo} data-testid="doc-theme-drawer">
          <DrawerCloseButton />
          <DrawerHeader>Theme</DrawerHeader>
          <DrawerBody overflowX="hidden">
            <Tabs variant="soft-rounded" size="sm" isFitted isLazy>
              <TabList>
                <Tab>
                  <Box mr={2}>
                    <FontAwesomeIcon icon={regular('swatchbook')} />
                  </Box>
                  Theme
                </Tab>
                <Tab>
                  <Box mr={2}>
                    <FontAwesomeIcon icon={regular('fill-drip')} />
                  </Box>
                  Background
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0} pb={1} mt={4}>
                  <ThemePanel
                    currentTheme={theme}
                    setDocTheme={setDocTheme}
                    openThemeEditor={openThemeEditor}
                  />
                </TabPanel>
                <TabPanel p={0} pb={1} mt={4}>
                  <BackgroundPanel
                    editor={editor as Editor}
                    background={background}
                    updateAttributes={updateAttributesAndTrack}
                    defaultMessage={defaultMessage}
                    isDark={isThemeDark(theme)}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>

          <DrawerFooter>
            <Button w="100%" variant="solid" onClick={onClose}>
              Done
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    )
  }
)

DocThemeDrawer.displayName = 'DocThemeDrawer'
